import { Typography } from '@mui/material'
import RangeSliderPrice from './RangeSliderPrice';
import { CheckboxCategoria } from '../../Categorias/Componentes/CheckboxCategoria';
import { useEffect } from 'react';

const FiltrosProductos = ({
    categoria,
    minMaxValue = [0, 50000],
    setCategoria,
    valuePrice,
    setValuePrice,
    widthRangeSlider = 200,
    handleAddCategoria,
    absoluteRangeSlidePrecie = false
}) => {
    const handleClick = (catego) => {
        //  setCategoria({ ...categoria, id: catego.ID })
        handleAddCategoria({ checked: !catego.checked, ID: catego.ID });
    }

    useEffect(()=>{
        console.log({categoria});
    },[categoria])

    return (
        <div className="w-100 sticky-top pe-2 " style={absoluteRangeSlidePrecie ? {} : { paddingTop: "5.1rem" }}>
            <Typography className='LibreBodoniBold mb-3' style={{ fontSize: "1.3rem" }}>
                Categorías
            </Typography>
            <div className='w-100' style={absoluteRangeSlidePrecie ? { overflowY: "auto", maxHeight: "80%", height: "80%" } : {}}>
                {
                    categoria && Array.isArray(categoria) && (
                        categoria.map((catego, indexCatego) => {
                            const classActive = catego.ID == categoria.id ? ' text-rosa ' : ''
                            return (
                                <div
                                    className='d-flex align-items-center mb-4' style={{ gap: "5px" }}
                                    onClick={() => handleClick(catego)}
                                    key={`${indexCatego}${catego.checked}`}
                                >
                                    <CheckboxCategoria
                                        checked={catego.checked}
                                        onClick={(e) => { e.preventDefault() }}
                                    />
                                    <Typography
                                        onClick={(e) => e.preventDefault()}
                                        className={"m-0 mt-0 p-0 font-Oxygen w-100"}
                                        style={{ cursor: 'pointer', fontWeight: 'normal', heigth: "fit-content", alignSelf: "center" }}>
                                        {catego.DESCRIPCCION}
                                    </Typography>
                                </div>
                            )
                        })
                    )
                }
            </div>
            <div
                className={(absoluteRangeSlidePrecie ? '' : 'w-100')}
            >
                <div class="LineRosa w-100" style={{ backgroundColor: 'lightgray' }} />

                <Typography className='LibreBodoniBold mb-3' style={{ fontSize: "1.3rem" }}>
                    Precio
                </Typography>
                <RangeSliderPrice
                    className={absoluteRangeSlidePrecie ? 'mb-3' : ''}
                    width={widthRangeSlider}
                    value={valuePrice}
                    minValue={minMaxValue[0]}
                    maxValue={minMaxValue[1]}
                    setValue={setValuePrice} />

            </div>


        </div>
    )
}

export default FiltrosProductos