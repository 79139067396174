import {
    Alert,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormLabel,
    IconButton,
    Radio,
    RadioGroup,
    Snackbar,
    TextField,
    Typography,
  } from "@mui/material";
  import CloseIcon from "@mui/icons-material/Close";
  import { useEffect, useState } from "react";
  import { Button, Col, Row } from "react-bootstrap";
  import { getInfoDireccion, getLista, guardar } from "../Funciones/Direcciones";
  import Estado from "./Estado";
  import Municipio from "./Municipio";
  
  import withReactContent from "sweetalert2-react-content";
  import Swal from "sweetalert2";
  import Loading from "../../../Includes/Loading";
  import { styled } from "@mui/material/styles";
  import { LoadingButton } from "@mui/lab";
  const MySwal = withReactContent(Swal);
  
  const ModalSelectDireccion = ({
    SetshowModalSelectDirec,  
    setDomicilio,
    domicilio
    
  }) => {



    const [IsLoading, setIsLoading] = useState(true); 
    const [Lista, setLista] = useState([]);


    useEffect(() => {
        getDireciones();
      }, []);
    
      const getDireciones = () => {
        setIsLoading(true);
        getLista("")
          .then((resp) => {
            let list = JSON.parse(resp.data);
            setLista(list);
            setIsLoading(false);
          })
          .catch((resp) => {
            setLista([]);
            setIsLoading(false);
          });
      };
    
  
    const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
      ({ theme }) => ({
        "& .MuiSnackbar-root": {
          top: theme.spacing(15),
        },
      })
    );
  
  
   
    return (
      <> 
        <Dialog fullWidth maxWidth="xs" open={true}>
          <IconButton
            aria-label="Close"
            className="m-3"
            style={{ position: "absolute", right: "0%" }}
            onClick={() => {
              SetshowModalSelectDirec(false);
            }}
          >
            <CloseIcon size="lg" />
          </IconButton>
          <DialogTitle>
            <h6 className="fw-bolder f-12 mt-2">Selecciona una dirección</h6>
          </DialogTitle>
          <DialogContent>
            {IsLoading ? (
              <Col sm={12} className="pt-5">
                <Loading />
              </Col>
            ) : (
                <Row className="p-2">
                {Lista.map((row) => (
                  <>
                    <div
                    onClick={()=>
                    {  
                            domicilio.id = row.ID;
  domicilio.domicilio = row.CALLE+" "+row.NUMERO_EXT+" "+row.NUMERO_INT+", "+row.COLONIA+", "+row.CP+", "+row.MUNICIPIO+", "+row.ESTADO
                            domicilio.nombre =  row.NOMBRE_CONTACTO;
                            domicilio.tipo = "DOMICILIO";
                            domicilio.lat =  row.LAT;
                            domicilio.long =  row.LONG;
                            

                        setDomicilio({...domicilio});
                        SetshowModalSelectDirec(false);
                    }}
                      className="pb-0 px-3 px-md-4 pt-3  mt-3 direccion-hover"
                      style={{ backgroundColor: "#F7F7F7", cursor:"pointer" }}
                    >
                      <div className="row">
                        <div className="col-12">
                          <h6 className="font-OxygenBold">{row.NOMBRE_CONTACTO+" - "+ row.TIPODOMICILIO} </h6>
                        </div>
    
                        <div className="col-12  mt-3 mt-md-1">
                          <small>
                            <p className="font-OxygenBold my-1">Dirección</p>
                            <p className="font-OxygenLight my-1">
                                {row.CALLE+" "+row.NUMERO_EXT+" "+row.NUMERO_INT+", "+row.COLONIA+", "+row.CP+", "+row.MUNICIPIO+", "+row.ESTADO}
                                </p>
                          </small>
                        </div>  
                        <div className="col-12  ">
                          <small>
                            <p className="font-OxygenBold my-1">Indicaciones</p>
                            <p className="font-OxygenLight my-1">
                              {row.INDICACIONES}
                            </p>
                          </small>
                        </div>
     
                      </div>
                    </div>
                  </>
                ))}
              </Row>
            )}
          </DialogContent>
          <DialogActions>
           
          </DialogActions>
        </Dialog>
      </>
    );
  };
  export default ModalSelectDireccion;
  