import { stringify } from "query-string";
import { getData, postdData, postUrl } from "../../Context/backend";
import RFCValidator from "../../Lib/Funciones Generales/ValidarRFC";
import { ESTATUS_PEDIDOS } from "../../Lib/Funciones Generales/EstatusPedidos";

export const getListaPedidos = async (
  estatus_pedido = -1,
  fecha_inicio = "",
  fecha_fin = ""
) => {
  let data = [];
  let queryParamsObj = { estatus_pedido, fecha_inicio, fecha_fin };

  const url = `pedidos/PedidosUsuario?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const getListaDetallePedidoCompleto = async (id) => {
  //onsole.log(query)
  let data = [];
  let queryParamsObj = { id };
  const url = `pedidos/managers/ListarDetalleCompleto?${stringify(
    queryParamsObj
  )}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const getComprobantes = async (id_venta) => {
  let data = [];
  let queryParamsObj = { id_venta };
  const url = `pedidos/managers/ListarComprobantes?${stringify(
    queryParamsObj
  )}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      const comprobantes = JSON.parse(res.data.respuesta.comprobantes);
      resolve({
        data: comprobantes,
        estatus_pedido: res.data.respuesta.estatus_pedido,
      });
    } else {
      reject([]);
    }
  });
};

export const getInfoComprobante = async (id) => {
  let queryParamsObj = {
    id: id,
  };
  const url = `pedidos/managers/ComprobantePago?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      const data = JSON.parse(res.data.respuesta.data);
      resolve(data[0]);
    } else {
      reject({});
    }
  });
};

export const guardarInfoComprobante = async (Values) => {
  let data = [];
  let body = { ...Values };
  const url =
    Values.id == 0
      ? `pedidos/managers/AgregarComprobante`
      : `pedidos/managers/EditarComprobante`;
  const res = await postdData(url, body);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const eliminarComprobante = async (id, id_venta, is_cliente = false) => {
  if (id != undefined) {
    let data = [];
    let queryParamsObj = { id, id_venta, is_cliente };
    const url = `pedidos/managers/EliminarComprobante?${stringify(
      queryParamsObj
    )}`;
    const res = await postdData(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};

export const getColoresEstatus = (ID_ESTATUS) => {
  let colorSstatus = "";
  let backgrorundStatus = "";
  let backgrorundStatusStyle = {};
  let colores = {
    recibido: "secondary",
    enProceso: "warning",
    terminado: "success",
    cancelado: "danger",

    recibidoV2: "#808B96",
    enProcesoV2: "#ffc107 ",
    terminadoV2: "#A1D3EA",
    canceladoV2: "#dc3545",

    recibidoV3: "#A1D3EA",
    enProcesoV3: "#A1D3EA ",
    terminadoV3: "#A1D3EA",
    enRutaV3: "#A1D3EA",
    entregadoV3: "#E0FFD2",
    canceladoV3: "#dc3545",
  };
  switch (ID_ESTATUS) {
    case ESTATUS_PEDIDOS.RECIBIDO:
      // colorSstatus = "#FFF"; backgrorundStatus = "bg-info";
      // colorSstatus = "#FFF";
      backgrorundStatusStyle = { backgroundColor: colores.recibidoV3 };
      break;
    case ESTATUS_PEDIDOS.EN_PROCESO:
      // colorSstatus = "#FFF"; backgrorundStatus = "bg-warning";
      backgrorundStatusStyle = { backgroundColor: colores.enProcesoV3 };
      break;
    case ESTATUS_PEDIDOS.TERMINADO:
      // colorSstatus = "#FFF"; backgrorundStatus = "bg-success";
      backgrorundStatusStyle = { backgroundColor: colores.terminadoV2 };
      break;
    case ESTATUS_PEDIDOS.EN_RUTA:
      backgrorundStatusStyle = { backgroundColor: colores.enRutaV3 };
      break;
    case ESTATUS_PEDIDOS.ENTREGADO:
      backgrorundStatusStyle = { backgroundColor: colores.entregadoV3 };
      break;
    default:
      // colorSstatus = "#FFF"; backgrorundStatus = "bg-danger";
      colorSstatus = "#FFF";
      backgrorundStatusStyle = { backgroundColor: colores.canceladoV3 };
      break;
    

  }

  return { colorSstatus, backgrorundStatus, backgrorundStatusStyle, colores };
};

export const timbrarCFDI = async (Values, Errores, setErrores, id) => {
  let error = false;

  if (!Values.uso_cfdi || Values.uso_cfdi == "") {
    Errores.uso_cfdi = true;
    error = true;
  }

  if (!Values.metodo_pago || Values.metodo_pago == "") {
    Errores.metodo_pago = true;
    error = true;
  }
  if (!Values.forma_pago || Values.forma_pago == "") {
    Errores.forma_pago = true;
    error = true;
  }
  if (!Values.fecha_emision || Values.fecha_emision == "") {
    Errores.fecha_emision = true;
    error = true;
  }

  if (!Values.cp || Values.cp == "" || Values.cp.length > 5) {
    Errores.cp = true;
    setErrores(Errores);
    error = true;
  }
  if (
    !Values.regimen_fiscal ||
    Values.regimen_fiscal == "" ||
    Values.regimen_fiscal == "0"
  ) {
    Errores.regimen_fiscal = true;
    setErrores(Errores);
    error = true;
  }

  if (!Values.razon_social || Values.razon_social == "") {
    Errores.razon_social = true;
    setErrores(Errores);
    error = true;
  }

  if (!Values.rfc || Values.rfc == "") {
    Errores.rfc = true;
    setErrores(Errores);
    error = true;
  } else {
    if (!RFCValidator(Values.rfc)) {
      Errores.rfc = true;
      setErrores(Errores);
      error = true;
    }
  }

  if (!Values.id_cliente || Values.id_cliente <= 0 || Values.id_cliente == "") {
    Errores.id_cliente = true;
    setErrores(Errores);
    error = true;
  }

  if (!Values.serie || Values.serie == "" || Values.serie == undefined) {
    Errores.serie = true;
    setErrores(Errores);
    error = true;
  }

  if (!error) {
    let data = [];
    let queryParamsObj = {
      id: id,
    };
    Values.id = id;
    Values.regimen_fiscal = String(Values.regimen_fiscal);
    Values.is_ecommerce = true;
    // Values.fecha_emision = parseInt( Values.fecha_emision);
    Values.auto_factura = true;
    const body = Values;
    const url = `Facturacion/facturarpedido`;

    //console.log(url);
    //console.log(body);
    const res = await postdData(url, body);
    //const res = { error: false, data: { codigo: "201", mensaje: "okey" } };

    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        //console.log(data);
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "Completa todos los campos requeridos" });
    });
  }
};

export const getDatosFacturacion = async (id) => {
  let data = [];
  let queryParamsObj = {
    ID: id,
  };
  const url = `pedidos/managers/getDatosFacturacion?${stringify(
    queryParamsObj
  )}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    //console.log(res);
    if (!res.error) {
      //data = JSON.parse(res.data.respuesta);
      const em = JSON.parse(res.data.respuesta.emisor);
      const rec = JSON.parse(res.data.respuesta.receptor);
      const concep = JSON.parse(res.data.respuesta.conceptos);

      let values = {
        emisor: em.length > 0 ? em[0] : {},
        receptor: rec.length > 0 ? rec[0] : {},
        conceptos: concep,
      };
      resolve(values);
    } else {
      reject([]);
    }
  });
};

export const enviarFactura = async (Values, Errores, setErrores) => {
  if (Values.id != undefined) {
    let data = [];
    if (Values.correos.trim() == "") {
      Errores.correos = true;
      setErrores(Errores);
      return new Promise((resolve, reject) => {
        resolve({ mensaje: "El campo de corres no es valido" });
      });
    }
    let queryParamsObj = { id_factura: Values.id, correo: Values.correos };
    const url = `facturacion/EnviarFacturaCorreo?${stringify(queryParamsObj)}`;
    const res = await postUrl(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};
