import { Divider } from "@mui/material";
import React, { useState } from "react";
import { CartContext } from "../../Context/CartContext";
import { useContext } from "react"; 
import { formatMoneda } from "../../Lib/Funciones Generales/Generales";
import { useCartInfo } from "../../Context/CartInfoContext";

const Resumen = ({envio=0}) => {

  const { 
    getTotalItems,
    getTotalPrice,
    getTotalDescuento,
    getTotalPriceSinDescuento
  } = useContext(CartContext);
  const { cartInfo, setCartInfo, orderInfo } = useCartInfo();
  const [fecha, setFecha] = useState(cartInfo.deliveryDate);
  

  return (
    <>
      <div className="pt-3" >
        <h5 className=" text-star mb-3 fw-bolder font-PlayfairDisplay">
          Resumen
        </h5>
        <div className="d-flex justify-content-between py-2">
          <div className="">
            <h6 className="text-star font-OxygenLight">Subtotal</h6>
          </div>
          <div className="">
            <h6 className="text-end font-OxygenMedium">  {formatMoneda(parseFloat(getTotalPriceSinDescuento()))} MXN</h6>
          </div>
        </div>
        <div className="d-flex justify-content-between py-2">
          <div className="">
            <h6 className="text-star font-OxygenLight">Descuento</h6>
          </div>
          <div className="">
            <h6 className="text-end font-OxygenMedium">  {formatMoneda(parseFloat(getTotalDescuento()))} MXN</h6>
          </div>
        </div>
        <div className="d-flex justify-content-between py-2">
          <div className="">
            <h6 className="text-star font-OxygenLight">Envío</h6>
          </div>
          <div className="">
            <h6 className="text-end font-OxygenMedium"> {formatMoneda(parseFloat(!cartInfo.precioEnvio.precioEnvio?0:cartInfo.precioEnvio.precioEnvio))}</h6>
          </div>
        </div>
        <div className="d-flex justify-content-between py-2">
          <div className="">
            <h6 className="text-star font-OxygenLight">Artículos</h6>
          </div>
          <div className="">
            <h6 className="text-end font-OxygenMedium">{getTotalItems()}</h6>
          </div>
        </div>
        <Divider light/>
        <div className="d-flex justify-content-between py-2">
          <div className="">
            <h6 className="text-star font-OxygenLight">TOTAL</h6>
          </div>
          <div className="">
            <h6 className="text-end font-OxygenMedium">
            {formatMoneda(parseFloat(getTotalPrice()+(!cartInfo.precioEnvio.precioEnvio?0:cartInfo.precioEnvio.precioEnvio)))} MXN
              </h6>
          </div>
        </div>
        <Divider light/>
      </div>
    </>
  );
};

export default Resumen;
