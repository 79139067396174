import React, { useEffect, useState } from "react";
import Skeleton2 from "../../assets/Skeleton2.webp";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { getListaProductos, getPrecioMaximo, guardarFavorito } from "../Funciones/Productos";
import { Chip, Typography, Snackbar, Alert, Box, Button } from "@mui/material";
import Loading from "../../Includes/Loading";
import { styled } from "@mui/material/styles";
import { getOpcionesCategorias, getOpcionesCategoriasEcommerce } from "../../Categorias/Funciones/Categorias";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import BotonFavorito from "../../Lib/Favoritos/BotonFavorito";
import Offcanvas from "react-bootstrap/Offcanvas";
import FiltrosProductos from "./FiltrosProductos";
import { useWindowSize } from "../../Lib/Funciones Generales/WindowSize";
import Descuentos from "./Descuentos";

const obtenerParametros = (params) => {
  const ids = params.getAll("c");
  return ids;
}
const Productos = () => {
  const [IsLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [Lista, setLista] = useState([]);
  const [mensaje, setMensaje] = useState("");
  const [params, setParams] = useSearchParams();

  const [stateRow, setStateRow] = useState();
  const [showFiltrosProdMini, setShowFiltrosProdMini] = useState(false);
  const [categoria, setCategoria] = useState([]);
  const [valuePrice, setValuePrice] = useState([0, 50000]);
  const [minMaxValuePrice, setMinMaxValuePrice] = useState([0, 50000]);
  const { width, height } = useWindowSize();

  const getProductos = () => {
    setIsLoading(true);
    clearInterval(window.intervalo);
    getListaProductos(params, valuePrice[0], valuePrice[1], true)
      .then((resp) => {
        clearInterval(window.intervalo);
        let list = JSON.parse(resp.data);
        setLista(list);
        setIsLoading(false);
      })
      .catch((resp) => {
        clearInterval(window.intervalo);
        setLista([]);
        setIsLoading(false);
      });
  };

  const getMasCostoso = () => {
    setIsLoading(true);
    clearInterval(window.intervalo);
    getPrecioMaximo()
      .then((resp) => {
        clearInterval(window.intervalo);
        let list = JSON.parse(resp.data);
        let { PRECIO_MAXIMO, PRECIO_MINIMO } = list[0];
        setMinMaxValuePrice([PRECIO_MINIMO, PRECIO_MAXIMO])
        setValuePrice([PRECIO_MINIMO, PRECIO_MAXIMO])
      })
      .catch((resp) => {
        clearInterval(window.intervalo);
        setLista([]);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getProductos();
    getOpcionesCategoriasEcommerce().then((resp) => {
      const list = JSON.parse(resp.data);
      const catSeleccionadas = obtenerParametros(params);
      const newList = list.map((item) => {
        const seleccionado = catSeleccionadas.find((value) => value == item.ID);
        return {
          ...item,
          checked: seleccionado != undefined
        }
      })
      setCategoria(newList);
    });
  }, []);

  useEffect(() => {
    getMasCostoso();
  }, []);

  useEffect(() => {
    if (!IsLoading) {
      // window.history.pushState(null, "", params.toString());
      const catSeleccionadas = obtenerParametros(params);
      getProductos();
    }
  }, [params]);

  useEffect(() => {
    clearInterval(window.intervalo);
    if (!IsLoading) {
      window.intervalo = setInterval(() => {
        getProductos();
        clearInterval(window.intervalo);
      }, 1000);
    }
  }, [valuePrice]);

  const handleAddCategoria = (cat) => {
    const lista = categoria;
    if (cat.checked) {
      setParams(params => {
        params.append("c", cat.ID)
        return params;
      })
    } else {
      setParams(params => {
        params.delete("c", cat.ID)
        return params;
      })
    }
    const newList = lista.map((c) => cat.ID == c.ID ? { ...c, checked: cat.checked } : c)
    setCategoria((cat) => newList)
  }

  const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
    ({ theme }) => ({
      "& .MuiSnackbar-root": {
        top: theme.spacing(15),
      },
    })
  );

  const catSeleccionadas = obtenerParametros(params);


  return (
    <>
      <Offcanvas
        show={showFiltrosProdMini}
        onHide={() => setShowFiltrosProdMini(false)}
      >
        <Offcanvas.Header closeButton>
          <h5 className="font-OxygenBold mt-3 mb-3 ms-2">Filtrar productos</h5>
        </Offcanvas.Header>
        <Offcanvas.Body className="px-4 d-flex flex-column">
          {<FiltrosProductos
            absoluteRangeSlidePrecie={true}
            widthRangeSlider={"100%"}
            categoria={categoria}
            setCategoria={setCategoria}
            valuePrice={valuePrice}
            minMaxValue={minMaxValuePrice}
            setValuePrice={setValuePrice}
            handleAddCategoria={handleAddCategoria}
          />}
        </Offcanvas.Body>
      </Offcanvas>

      <StyledSnackbar
        direction="right"
        open={open}
        autoHideDuration={6000}
        onClose={() => setOpen(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={() => setOpen(false)}
          severity="info"
          sx={{ width: "100%" }}
        >
          {mensaje}
        </Alert>
      </StyledSnackbar>
      <div className="espacio-header" />
      <div className="container pt-5">
        {width < 992 && (
          <div className="d-flex w-100 mb-3">
            <Button
              onClick={() => setShowFiltrosProdMini(true)}
              className="btn  text-star ps-0 "
              variant="text"
            >
              <FilterAltIcon className="me-3" />
              Filtrar
            </Button>
          </div>
        )}

        <div className="d-flex justify-content-between flex-wrap">
          <div className="">
            <h2 className="font-PlayfairDisplay text-star">
              {IsLoading
                ? "Productos"
                : (Lista.length != 0 && catSeleccionadas?.length == 1)
                  ? Lista[0].CATEGORIA
                  : (Lista.length == 0) ? "Sin productos en esta categoría" : "Productos"}
            </h2>
          </div>
          <div className="">
            <Link
              className="w-100"
              to={"/"}
              style={{
                textTransform: "none",
                textDecoration: "none",
              }}
            >
              <Typography className="m-1 mt-0 text-center font-OxygenBold text-rosa  animated-text">
                Ir a inicio
              </Typography>
            </Link>
          </div>
        </div>

        <Box sx={{ display: "flex" }} className="mt-4">
          {width >= 992 && (
            <Box sx={{ flexGrow: 1 }} className="me-4 mt-4">
              {<FiltrosProductos
                categoria={categoria}
                setCategoria={setCategoria}
                valuePrice={valuePrice}
                setValuePrice={setValuePrice}
                handleAddCategoria={handleAddCategoria}
                minMaxValue={minMaxValuePrice}
              />}
            </Box>
          )}

          <Box className="w-100 ps-4">
            <div className="row">
              {IsLoading ? (
                <div className="pt-5 w-100">
                  <Loading />
                </div>
              ) : (
                Lista.map((row) => (
                  <div className="p-2 p-md-3 mb-5 col-12 col-md-6 col-lg-4">
                    <Link
                      to={"/productos/view/" + row.ID}
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <div
                        className="w-100"
                        style={{
                          backgroundImage: `url(${row.IMG != "" ? row.IMG : Skeleton2
                            })`,
                          // backgroundImage: `url(${ProdcutosArreglos})`,
                          minHeight: "370px",
                          backgroundPosition: "center",
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "cover",
                          position: "relative",
                        }}
                      >
                        {/* <Chip
                          className="text-white px-3 position-absolute"
                          label={row.CATEGORIA}
                          style={{
                            backgroundColor: "#BFB7B9",
                            top: "330px",
                            left: "8px",
                          }}
                        /> */}
                        <div
                          style={{
                            position: "absolute",
                            top: "5px",
                            right: "5px",
                          }}
                        >
                          <BotonFavorito
                            id={row.ID}
                            favorito={row.FAVORITO}
                            Lista={Lista}
                            setLista={setLista}
                            setStateRow={setStateRow}
                          />
                        </div>
                      </div>
                    </Link>
                    <h6 className="font-Oxygen mb-1 mt-3 text-star">
                      {row.DESC_CORTA}
                    </h6>
                    <h6 className="font-OxygenBold mb-4 mt-2 text-star">
                      <Descuentos
                        descuentos={row.DESCUENTOS}
                        precio={row.PRECIO_PUBLICO}
                      />
                    </h6>
                  </div>
                ))
              )}
            </div>
          </Box>
        </Box>
      </div>
      <div className="w-100 my-5 py-5" />
      <div className="w-100 my-5 py-5" />
    </>
  );
};
export default Productos;
