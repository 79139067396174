import { stringify } from "query-string"
import { postUrl } from "../../../Context/backend";

export const guardarFavorito = async (id, checked) => {
    if (id != undefined) {
      let data = [];
      let queryParamsObj = {
        id_producto: id,
        favorito: checked
      };
      const url = `productos/AgregarAFavoritos?${stringify(queryParamsObj)}`;
      const res = await postUrl(url, true);
      return new Promise((resolve, reject) => {
        if (!res.error) {
          data = res.data;
          resolve(data);
        } else {
          reject(res);
        }
      });
    } else {
      return new Promise((resolve, reject) => {
        resolve({ mensaje: "No se pudo obtener el identificador" });
      });
    }
  };