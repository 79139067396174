import { Button, Divider, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

const Deposito = () => {
  return (
    <>
      <div className="pt-2 mb-5">
        <h6 className=" text-star mb-1 mt-3 fw-bolder font-PlayfairDisplay">
          Cuenta bancaria para depósito o transferencia
        </h6>
        <div
          className="px-3 py-2  mt-3 "
          style={{
            backgroundColor: "#F7F7F7",
            cursor: "pointer",
          }}
        >
          <div className=" w-100 mt-2 mt-md-0">
            <small>
              <p className="font-OxygenBold my-1">Banco Banamex</p>
            </small>

            <small>
              <p className="font-Oxygen my-1">
                A nombre de:<b>MICHELLE POURROY VALENCIA</b> 
                <br />
                Banco: <b>Banamex</b> 
                 <br />
                Número de Tarjeta de Débito: <b>5204 1659 8068 3092</b> 
                <br />
                CLABE Interbancaria: <b>002320701157138828</b>
                <br />
              </p>
            </small>
          </div>
        </div>

        <h6 className=" text-star mb-1 mt-3 fw-bolder font-PlayfairDisplay">
        Pasos a Seguir
        </h6>
        <p className="font-Oxygen my-1">
        1. Realiza tu pago. 
        </p>
                <p className="font-Oxygen my-1">
                2. Carga tu comprobante de pago en la sección Mi Perfil/Pedidos
                </p>
                <p className="font-Oxygen my-1">
                3. Recibe tu pedido
                </p>
                <p className="font-Oxygen my-1">
                Mientras mas rápido hagas tu pago, más rápido confirmaremos tu solicitud.
                </p> 

      </div>
    </>
  );
};

export default Deposito;
