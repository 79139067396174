import { Button, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";

export const CssTextField = styled(TextField)({
    "& label.Mui-focused": {
      color: "#979797",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#979797",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#979797",
      },
      "&:hover fieldset": {
        borderColor: "#979797",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#979797",
      },
      "& input": {
        color: "white",
      },
    },
  });
  
  export const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText("#ebe1d6"),
    backgroundColor: "#ebe1d6",
    "&:hover": {
      backgroundColor: "#ebe1d6",
    },
    height: 50,
    fontWeight: "bold",
    textTransform: "capitalize",
  }));