import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Button from "@mui/material/Button";
import Loading from "../../Includes/Loading";
import { getInfoCompra } from "../Funciones/Orden";
import { formatMoneda } from "../../Lib/Funciones Generales/Generales";
import ModalCargarImagen from "../../Lib/Imagen/Componentes/ModalCargarImagen";
import { IMG } from "../../Lib/Funciones Generales/Constantes";
import AccSkeleton2 from "../../assets/Skeleton2.webp";
import Swal from "sweetalert2";
import { guardarInfoComprobante } from "../../MiPerfil/Funciones/Pedidos";
import withReactContent from "sweetalert2-react-content";
import { Alert, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Snackbar, Typography, styled } from "@mui/material";


const MySwal = withReactContent(Swal);
const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
  ({ theme }) => ({
    "& .MuiSnackbar-root": {
      top: theme.spacing(15),
    },
  })
);
function PagoSuccess() {
  const { id } = useParams();
  const [mensaje, setMensaje] = useState("");
  const [Values, SetValues] = useState([]);
  const [IsLoading, setIsLoading] = useState(true);
  const [cargarComprobante, setCargarComprobante] = useState(false);
  const [open, setOpen] = useState(false);
  const [comprobanteSubido, setComprobanteSubido] = useState(false);
  const [showComprobanteSubido, setShowComprobanteSubido] = useState(false);

  const getInfo = () => {
    getInfoCompra(id)
      .then((resp) => {
        SetValues(resp);
        setIsLoading(false);
      })
      .catch((resp) => {
        alert("Erorr");
        SetValues([]);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getInfo();
  }, []);
  useEffect(() => {
    console.log(showComprobanteSubido)
  }, [showComprobanteSubido])

  console.log(Values);

  const GuardarComprobante = (img) => {
    const comprobante = {
      id: 0,
      id_venta: Values.ID,
      img,
      nombre: "",
      comentario: "",
      actualizar_comprobante: false,
    };
    new Swal({
      title: "¿Estás seguro de guardar el comprobante?",
      text: "Esta acción no se puede deshacer",
      icon: "warning",
      showDenyButton: true,
      denyButtonText: "No, cancelar",
      confirmButtonText: "Si, estoy seguro",
      confirmButtonColor: "#3ABE88",
      denyButtonColor: "#65748B",
      reverseButtons: true,
    }).then(function (isConfirm) {
      console.log(isConfirm.isConfirmed);
      if (isConfirm.isConfirmed) {
        guardarInfoComprobante(comprobante)
          .then((data) => {
            if (data?.codigo == "200") {
              setComprobanteSubido(true);
              setShowComprobanteSubido(true)
              // MySwal.fire({
              //   title: "Correcto",
              //   html: data.respuesta.register,
              //   icon: "success",
              //   confirmButtoColor: "#3ABE88",
              //   showConfirmButton: false,
              //   timer: 1700,
              // }).then((result) => {
              //   // if (window.fnConsultarComprobantes) {
              //   //     window.fnConsultarComprobantes()
              //   // }
              // });
            } else {
              setMensaje(data.mensaje);
              setOpen(true);
            }
          })
          .catch((data) => {
            setMensaje(data.mensaje);
            setOpen(true);
          });
      }
    });
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <StyledSnackbar
        direction="right"
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {mensaje}
        </Alert>
      </StyledSnackbar>
      <div className="espacio-header" />
      <div className="container-fluid px-0 w-100">
        <div className="w-100 my-4 py-4" />

        {IsLoading ? (
          <div className="col-12 my-5">
            <Loading />
          </div>
        ) : (
          <>
            {Values ? (
              <>
                <h2
                  className="text-black font-PlayfairDisplay fw-bolder text-center w-100"
                  style={{ fontWeight: "100" }}
                >
                  ¡Tu pedido fué realizado con éxito!
                </h2>
                <div className="d-flex justify-content-center">
                  <div
                    className="text-center mt-5"
                    style={{ maxWidth: "400px" }}
                  >
                    <div
                      className="p-5"
                      style={{
                        backgroundColor: "#f7f7f7",
                      }}
                    >
                      <h5
                        className="text-black font-PlayfairDisplay text-center"
                        style={{ fontWeight: "bold" }}
                      >
                        No. de pedido
                      </h5>
                      <p className="font-Oxygen text-center">
                        {Values.FOLIO_VENTA}
                      </p>
                      <h5
                        className="text-black font-PlayfairDisplay text-center"
                        style={{ fontWeight: "bold" }}
                      >
                        Fecha de entrega
                      </h5>
                      <p className="font-Oxygen text-center">
                        {Values.FECHA_DE_ENTREGA} <br />
                        Entre las {Values.ENTREGA_INICIO} y las{" "}
                        {Values.ENTREGA_FIN} Hrs
                      </p>
                      {
                        Values.PROMO_APLICADA != "" ?
                          <h5>
                            Felicidades!
                          </h5> : ""
                      }

                      <p className="font-Oxygen text-center">
                        {
                          Values.PROMO_APLICADA != "" ?
                            <> {"Tu compra te ha generado"}
                              <b>   {formatMoneda((parseFloat(Values.TOTAL) * 115) / 100)} </b>
                              {" Flower Wallet"}
                            </>
                            : ""
                        }
                      </p>


                      <p className="font-Oxygen text-center mt-5">
                        Podrás consultar el estatus de tu pedido en{" "}
                        <a
                          href="/perfil"
                          style={{ cursor: "pointer", textDecoration: "none" }}
                          className="animated-text "
                        >
                          Mi perfil
                        </a>
                      </p>
                      {Values.TRANSFERENCIA > 0 && (
                        !comprobanteSubido ? <Button
                          className="btn-accion"
                          variant="text"
                          onClick={() => {
                            // setIdDireccion(row.ID);
                            // SetshowModalDireccion(true);
                            setCargarComprobante(true)
                          }}
                        >
                          Subir comprobante
                        </Button> :
                          <Typography className="Oxygen">
                            Ya subiste el comprobante
                          </Typography>
                      )}
                    </div>
                    <a
                      className=""
                      href="/"
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <Button
                        className="btn btn-michelle mt-4 w-100  py-2 px-4 "
                        variant="contained"
                      >
                        <span>Volver a la tienda </span>
                      </Button>
                    </a>
                  </div>
                </div>
              </>
            ) : (
              <> </>
            )}
          </>
        )}

        <div className="w-100 my-4 py-4" />
      </div>
      {cargarComprobante && (
        <ModalCargarImagen
          id={0}
          aceptarPDF={true}
          imgUrl={AccSkeleton2}
          tipo={IMG.TIPO_COMPROBANTE}
          Titulo="Cargar comprobante"
          Subtitulo=" el archivo"
          mostrar={true}
          borderRadius={20}
          fnCerrar={() => {
            setCargarComprobante(false);
          }}
          fnDevolverImagen={(img) => {
            GuardarComprobante(img);
          }}
        />
      )}
      {
        showComprobanteSubido &&
        <DialogoSuccessComprobante
          open={showComprobanteSubido}
          handleClose={() => {
            setShowComprobanteSubido(false)
          }}
        />
      }
    </>
  );
}
const DialogoSuccessComprobante = ({ open, handleClose }) => {

  return <Dialog
    open={open}
    onClose={handleClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title" className="Oxygen">
      {"Comprobante subido correctamente"}
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description" fontWeight="normal" className="Oxygen text-body" >
        Tu comprobante se ha subido correctamente, puedes revisarlo en "Mi perfil"
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button
        onClick={handleClose}
        className="btn btn-michelle mt-4  py-2 px-4 "
        variant="contained"
      >
        <span>Aceptar</span>
      </Button>
    </DialogActions>
  </Dialog>
}
export default PagoSuccess;
