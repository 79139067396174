import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import { getLista } from "../Funciones/Categorias";

const CarruselCategorias = () => {
  const [IsLoading, setIsLoading] = useState(true);
  const [Lista, setLista] = useState([]);

  const getDireciones = () => {
    setIsLoading(true);
    getLista("")
      .then((resp) => {
        let list = JSON.parse(resp.data);
        console.log(list);
        setLista(list);
        setIsLoading(false);
      })
      .catch((resp) => {
        setLista([]);
        setIsLoading(false);
      });
  };

  
  useEffect(() => {
    getDireciones();
  }, []);

  return (
    <>
      <Splide
        options={{
          rewind: true,
          arrows: false,
          perPage: 4,
          // fixedWidth: "400px",
          // fixedHeigth: "400px",
          // autoWidth: true,
          width: "100%",
          breakpoints: {
            1300: {
              perPage: 3,
            },
            985: {
              perPage: 2,
            },
            700: {
              perPage: 1,
            },
          },
        }}
        aria-label="React Splide Example"
      >
        {Lista.map((row) => (
          <SplideSlide >
            <Link
              to={"/productos/cat?c=" + row.ID}
              style={{ textDecoration: "none", color: "black" }}
            >
              <div className="p-2">
                <div className="container-categoria">
                  {/* <img
                    src={row.IMG != "" ? row.IMG : ""}
                    style={{
                      width: "100%",
                      height:"100%",
                      cursor: "pointer"
                    }}
                    className="img-thumbnail" /> */}
                  <div
                    className="w-100 container-categoria"
                    style={{
                      backgroundImage: `url(${row.IMG != "" ? row.IMG : ""})`,
                      minHeight: "400px",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      cursor: "pointer",
                      transition: " all 0.5s ease",
                    }}
                  ></div>
                </div>
                <h4 className="font-PlayfairDisplay mb-4 mt-2 text-center">
                  {row.NOMBRE}
                </h4>
              </div>
            </Link>
          </SplideSlide>
        ))}
      </Splide>
    </>
  );
};
export default CarruselCategorias;
