const validateRfc = require('validate-rfc');

function RFCValidator(rfcStr) {

  try {
    const rfc = validateRfc(rfcStr);
    console.log(rfc);
    return rfc.isValid;
  } catch (e) {
    console.log("ERROR: ", e);
    return false;
  }

}

export default RFCValidator;
