import React, { createContext, useEffect, useState } from "react";

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState(() => {
    const storedCartItems = localStorage.getItem("cartItems");
    return storedCartItems ? JSON.parse(storedCartItems) : [];
  });

  useEffect(() => {
    localStorage.setItem("cartItems", JSON.stringify(cartItems));
  }, [cartItems]);

  const addToCart = (item) => {
    const itemIndex = cartItems.findIndex(
      (cartItem) =>
        cartItem.id === item.id &&
        cartItem.id_color === item.id_color &&
        cartItem.id_talla === item.id_talla
    );

    if (itemIndex === -1) {
      setCartItems([...cartItems, { ...item, cantidad: 1 }]);
    } else {
      const updatedCartItems = [...cartItems];
      updatedCartItems[itemIndex].cantidad += 1;
      setCartItems(updatedCartItems);
    }
  };

  const removeFromCart = (item) => {
    const itemIndex = cartItems.findIndex(
      (cartItem) =>
        cartItem.id === item.id &&
        cartItem.id_color === item.id_color &&
        cartItem.id_talla === item.id_talla
    );
    if (itemIndex !== -1) {
      const updatedCartItems = [...cartItems];
      if (updatedCartItems[itemIndex].cantidad > 1) {
        updatedCartItems[itemIndex].cantidad -= 1;
      } else {
        updatedCartItems.splice(itemIndex, 1);
      }
      setCartItems(updatedCartItems);
    }
  };

  const deleteItem = (item) => {
    const itemIndex = cartItems.findIndex(
      (cartItem) => cartItem.id === item.id
    );
    if (itemIndex !== -1) {
      const updatedCartItems = [...cartItems];
      updatedCartItems.splice(itemIndex, 1);

      setCartItems(updatedCartItems);
    }
  };

  const clearCart = () => {
    setCartItems([]);
  };

  const isInCart = (item) => {
    return cartItems.some((cartItem) => cartItem.id === item.id);
  };

  const getItemCount = (item) => {
    const itemIndex = cartItems.findIndex(
      (cartItem) =>
        cartItem.id === item.id &&
        cartItem.id_color === item.id_color &&
        cartItem.id_talla === item.id_talla
    );

    if (itemIndex !== -1) {
      return cartItems[itemIndex].cantidad;
    }

    return 0;
  };

  const getTotalItems = () => {
    return cartItems.reduce((total, item) => total + item.cantidad, 0);
  };

  const getTotalPrice = () => {
    const totalPrice = cartItems.reduce((total, item) => {
      let itemPrice = item.precio;
      if (JSON.parse(item.descuento).TIPO_DESCUENTO !== 0) {
        itemPrice = JSON.parse(item.descuento).PRECIO_DESCUENTO;
      }
      return total + itemPrice * item.cantidad;
    }, 0);

    return totalPrice;
  };

  const getTotalDescuento = () => {
    const totalPrice = cartItems.reduce((total, item) => {
      let itemPrice = item.precio;
      if (JSON.parse(item.descuento).TIPO_DESCUENTO !== 0) {
        if (JSON.parse(item.descuento).TIPO_DESCUENTO === 1) {
          let descuento = JSON.parse(item.descuento).DESCUENTO / 100;
          itemPrice = item.precio * descuento;
        } else {
          itemPrice = JSON.parse(item.descuento).DESCUENTO;
        }
      } else {
        return total + 0;
      }
      return total + itemPrice * item.cantidad;
    }, 0);

    return totalPrice;
  };

  const getTotalPriceSinDescuento = () => {
    const totalPrice = cartItems.reduce((total, item) => {
      let itemPrice = item.precio;
      return total + itemPrice * item.cantidad;
    }, 0);

    return totalPrice;
  };

  return (
    <CartContext.Provider
      value={{
        cartItems,
        addToCart,
        getTotalPrice,
        removeFromCart,
        clearCart,
        isInCart,
        deleteItem,
        getItemCount,
        getTotalItems,
        getTotalDescuento,
        getTotalPriceSinDescuento,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
