import React, { useEffect, useState } from "react";
import { getSucursalesByKey } from "../Lib/Direcciones/Funciones/Direcciones";
import CarruselRandom from "../Productos/Componentes/CarruselRandom";
import { Typography } from "@mui/material";
import { Col, Container, Row } from "react-bootstrap";
import { useExtraSmallBreakpoint, useSmallBreakpointBootstrap } from "../Lib/Funciones Generales/Breakpoints";

function Workshop() {
  const BannerWork =
    "https://florcreativa.blob.core.windows.net/imagenes/banner_workshop.jpg";

  const GraficoHomeFreeStyleNegro =
    "https://florcreativa.blob.core.windows.net/imagenes/GraficoHomeFreeStyleNegro.svg";
  useEffect(() => {
    getSucursalesByKey();
  }, []);

  const isSmall = useExtraSmallBreakpoint({ inRange: true });

  const workshops = [
    {
      nivel: "básico",
      titulo: <>
        <Typography
          textAlign="justify"
          lineHeight={isSmall ? "8.4vw" : "4.2rem"}
          letterSpacing="-2px"
          style={{ wordSpacing: "1px" }}
          fontSize="inherit"
          fontFamily="inherit"

        >
          Nothing like the
          first <span style={{ fontWeight: "bold" }}>FLOWERS</span>
        </Typography>
      </>,
      descripcion: "Este taller es el ideal si quieres tener un primer acercamiento a los conocimientos básicos de estética, colorimetría y técnicas en arreglos florales."
    },
    {
      nivel: "intermedio",
      titulo: <>
        <Typography
          textAlign="justify"
          lineHeight={isSmall ? "8.4vw" : "4.2rem"}
          letterSpacing="-2px"
          style={{ wordSpacing: "1px" }}
          fontSize="inherit"
          fontFamily="inherit"

        >
          Adding a little
          bit of <span style={{ fontWeight: "bold" }}>MAGIC</span>
        </Typography>
      </>,
      descripcion: "Este taller es ideal si ya cuentas con los conocimientos básicos pero quieres seguir aprendiendo"
    },
    {
      nivel: "avanzado",
      titulo: <Typography
        textAlign="justify"
        lineHeight={isSmall ? "8.4vw" : "4.2rem"}
        letterSpacing="-2px"
        style={{ wordSpacing: "1px" }}
        fontSize="inherit"
        fontFamily="inherit"

      >
        Level <span style={{ fontWeight: "bold" }}>UP</span>
      </Typography>,
      descripcion: "Este taller es ideal si ya cuentas con los conocimientos básicos pero quieres seguir aprendiendo"
    }
  ]
  return (
    <>
      <div className="espacio-header" />

      {/*<div className="container-fluid" style={{ backgroundColor: "#baa294" }}>
          <div className="row">
            <div
              className="col-12 col-sm-7 col-md-6
           d-flex    
           align-items-center
          justify-content-center                   
          "
            >
              <div>
                <div className="my-5 my-md-0 align-self-center">
                  <h4
                    className="font-Oxygen text-white"
                    style={{ fontSize: "1.5rem", marginBottom: "0.5rem" }}
                  >
                    Conoce nuestros
                  </h4>
                  <h4
                    className="font-PlayfairDisplayItalic text-white"
                    style={{ fontSize: "4.2rem" }}
                  >
                    Workshops
                  </h4>
                </div>
              </div>
            </div>
            <div className=" col-12 col-sm-5 col-md-6 p-0   d-flex  justify-content-end ">
              <img src={BannerWork} className="img-fluid" />
            </div>
          </div>
  </div>*/}
      <div
        className="banner-contacto img-fluid"
        style={{
          backgroundImage: `url("${BannerWork}")`,
          maxHeight: "87vh !important",
        }}
      >
        <div
          className=" d-flex flex-wrap  align-items-end justify-content-start ps-4"
          style={{ height: "87vh", background: "" }}
        >
          <div className="d-flex justify-content-end align-items-start flex-column ps-1 pb-1 ps-sm-5 pb-sm-5">
            <h2
              className=" mb-4 w-100 text-white "
              style={{
                fontWeight: "bold",
                fontFamily: "PlayfairDisplay",
                fontSize: 50,
                wordWrap: "break-word",
                overflowWrap: "break-word",
                inlineSize: "150px"
              }}
            >
              {!isSmall ?
                <p>WORKSHOPS</p> :
                <>
                  <p style={{ lineHeight: 0 }}>
                    WORK-
                  </p>
                  <p>
                    SHOPS
                  </p>
                </>
              }
            </h2>
          </div>
        </div>
      </div>
      <div className="px-4 w-75 d-flex justify-content-center w-100">
        <div className="px-1 px-sm-5">
          <div className="d-flex align-items-center mt-5 pt-4">
            <p className="LibreBodoni text-black text-md mb-0" >
              Somos fieles creyentes de que el conocimiento se debe compartir, por eso hemos diseñado una serie de talleres que ofrecen
              nuestra sabiduría a todos aquellos que se sientan creativos o quieran adentrarse en nuestra magia.
            </p>
          </div>
          {/* <div className="w-100 my-3 py-1" /> */}
          <div className="d-flex justify-content-center flex-wrap flex-workshop" style={{}}>
            {workshops.map((w, key) =>
              <div style={{ flex: 2, minWidth: "170px", }} key={key}>
                <TipoWorkshop {...w} />
              </div>
            )
            }
          </div>
          {/* <div className="my-5 blank-component">{"a"}</div>
          <div className="my-5">{" "}</div>
          <div className="my-5">{" "}</div> */}
          <div className="w-100 mb-5">
            <div className="container-iframe" style={{ maxWidth: "100%", paddingTop: !isSmall ? "40%" : "56%" }}>
              <iframe
                className="responsive-iframe"
                src="https://www.youtube.com/embed/_ivBl6fcJbI?si=LXrCdWWQsXmVwbkE"
                title="Michelle Pourroy workshops"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen></iframe>
            </div>

          </div>
        </div>
      </div>
      <div className="w-100 my-5 py-1" />
      <div
        style={{ backgroundColor: "#ebe1d6" }}
      >
        <div
          className="container p-3"
        >
          <div className="d-flex flex-column align-items-center">
            <h2 className="font-PlayfairDisplay mt-4">
              Shop
            </h2>
            <div
              className="d-flex w-100 justify-content-center 
              mb-4 
              align-content-center align-items-center"
              ata-aos="fade-up"
              data-aos-duration="1500"
            >
              <h6 className="font-PlayfairDisplay text-rosa">
                (Our products)
              </h6>
              <div className="LineRosa" />
            </div>
          </div>
          <CarruselRandom />
        </div>

      </div>
      <div className="w-100 my-5 py-4" />
    </>
  );
}

const TipoWorkshop = ({ nivel, titulo, descripcion }) => {
  return (
    <div className="LibreBodoni">
      <div className="text-sm mb-3">
        <Typography
          fontStyle="italic"
          display="inline"
          fontFamily="LibreBodoni-Italic"
          fontSize="inherit">
          Nivel: {" "}
        </Typography>
        <Typography
          fontWeight="bold"
          display="inline"
          fontFamily="inherit"
          fontSize="inherit">
          {nivel}
        </Typography>
      </div>
      <div className="text-bg mb-3" style={{ lineHeight: "26px" }}>
        {titulo}
      </div>
      <div className="text-md">
        <Typography
          fontStyle="italic"
          fontFamily="LibreBodoni-Italic"
          fontSize="inherit"
          textAlign="justify"
          style={{ textJustify: "inter-word", wordSpacing: -1, lineHeight: "26px" }}
        >
          {descripcion}
        </Typography>
      </div>
    </div>)
}

export default Workshop;
