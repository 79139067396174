import * as React from 'react';
import { styled } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';

const BpIcon = styled('span')(({ theme }) => ({
    borderRadius: 6,
    width: 20,
    height: 20,
    backgroundColor: "white",
    ". MuiButtonBase-root &": {
        backgroundColor: "black",
        borderRadius: "5px",
        padding: "5px"
    },
    "& .MuiButtonBase-root": {
        backgroundColor: "black",
        borderRadius: "5px",
        padding: "5px"
    },
    "& .MuiCheckbox-root": {
        backgroundColor: "black",
        borderRadius: "5px",
        padding: "5px"
    },
    backgroundImage:
        theme.palette.mode === 'dark'
            ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
            : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
    },
    // 'input:hover ~ &': {
    //     backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
    // },
    'input:disabled ~ &': {
        boxShadow: 'none',
        background:
            theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
    },
}));

const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: 'black',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,0),hsla(0,0%,100%,0))',
    padding: "1px",
    '&::before': {
        display: 'block',
        backgroundImage:
            "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
            " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
            "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='black'/%3E%3C/svg%3E\")",
        content: '""',
    },
    'input:hover ~ &': {
        backgroundColor: 'black',
    },
});

// Inspired by blueprintjs
function BpCheckbox(props) {
    return (
        <Checkbox
            sx={{
                '&:hover': { bgcolor: 'white' },
                backgroundColor: "white",
                borderRadius: "6px",
                padding: "5px",
                border: "1px solid black",

            }}
            disableRipple
            color="default"
            checkedIcon={<BpCheckedIcon />}
            icon={<BpIcon />}
            inputProps={{ 'aria-label': 'Checkbox demo' }}
            {...props}
        />
    );
}

export function CheckboxCategoria({ ...props }) {
    return (
        <div>
            <BpCheckbox {...props} />
        </div>
    );
}