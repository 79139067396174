import * as React from "react";
import Menu from "@mui/material/Menu";
import IconButton from "@mui/material/IconButton";
import imgCarrito from "./../assets/Carrito.svg";
import AccEliminar from "./../assets/AccEliminar.svg";
import {
  Backdrop,
  Badge,
  Button,
  Divider,
  Tooltip,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { CartContext } from "../Context/CartContext";
import { useContext } from "react";
import CloseIcon from "@mui/icons-material/Close";
import Skeleton2 from "../assets/Skeleton2.webp";
import { formatMoneda } from "../Lib/Funciones Generales/Generales";
import { Link } from "react-router-dom";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    padding: "0 0px",
    width: "5px",
  },
}));

const CarritoMini = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    // setOpen(false);
    setAnchorEl(null);
  };

  const handleOpen = () => {
    // setOpen(true);
  };

  const handleItemClick = (event) => {
    event.stopPropagation(); // Evita que el evento se propague al contenedor del menú
    // Agrega aquí la acción que deseas ejecutar al hacer clic en el elemento del menú
  };
  const { cartItems, clearCart, getTotalItems, deleteItem } =
    useContext(CartContext);

  return (
    <React.Fragment>
      <Tooltip title="Carrito">
        <IconButton className="me-3 me-0" onClick={handleClick}>
          <StyledBadge
            badgeContent={getTotalItems()}
            variant={getTotalItems() == 0 ? "dot" : "standard"}
            color="error"
          >
            <img src={imgCarrito} width="25px"/>
          </StyledBadge>
        </IconButton>
      </Tooltip>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      ></Backdrop>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <div className="p-0" style={{ width: "350px" }}>
          <div className="w-100 d-flex justify-content-between flex-nowrap align-content-center align-items-center">
            <div className="ms-3">
              <h6 className=" text-center mb-0 aligin-self-center  font-OxygenBold">
                Carrito de compras
              </h6>
            </div>

            <div>
              <IconButton
                aria-label="Close"
                onClick={() => {
                  handleClose();
                }}
              >
                <CloseIcon size="lg" />
              </IconButton>
            </div>
          </div>

          {cartItems.length == 0 ? (
            <>
              <center>
                <Typography className=" mt-5 mb-5 text-center font-OxygenBold text-rosa  ">
                  Tu carrito esta vacío
                </Typography>
              </center>
            </>
          ) : (
            <>
              <div
                className="p-3 pb-2"
                style={{
                  maxHeight: "450px",
                  overflowY: "auto",
                  overflowX: "hidden",
                }}
              >
                <div className="row">
                  {cartItems.map((row) => (
                    <div>
                      <div className="col-12">
                        <div className="d-flex ">
                          <div
                            style={{
                              backgroundImage: `url(${
                                row.img != "" ? row.img : Skeleton2
                              })`,
                              height: "70px",
                              width: "70px",
                              backgroundPosition: "center",
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "cover",
                              position: "relative",
                            }}
                          ></div>
                          <div className="flex-grow-1 ps-3">
                            <h6 className="font-Oxygen mb-2 text-star">
                              {row.descripcion}
                            </h6>
                            {row.id_color != 0 ? (
                              <>
                                <h6 className="font-Oxygen mb-2 text-star">
                                  Color: {row.color}
                                </h6>
                              </>
                            ) : (
                              <></>
                            )}
                            {row.id_talla != 0 ? (
                              <>
                                <h6 className="font-Oxygen mb-2 text-star">
                                  Tamaño: {row.talla}
                                </h6>
                              </>
                            ) : (
                              <></>
                            )}

                            <h6 className="font-Oxygen mb-2 text-star">
                              Cant {row.cantidad}
                            </h6>
                            <div className="w-100 d-flex justify-content-between flex-nowrap align-content-center align-items-center">
                              <div style={{ marginLeft: "-10px" }}>
                                <IconButton
                                  aria-label="Close"
                                  onClick={() => {
                                    deleteItem({ id: row.id });
                                  }}
                                >
                                  <img src={AccEliminar} />
                                </IconButton>
                              </div>

                              <div className="ms-3">
                                <h6 className="font-OxygenBold  mt-2 text-end">
                                  {JSON.parse(row.descuento).TIPO_DESCUENTO ===
                                  0 ? (
                                    <>{formatMoneda(parseFloat(row.precio))}</>
                                  ) : (
                                    <>
                                      <span>
                                        {formatMoneda(
                                          parseFloat(
                                            JSON.parse(row.descuento)
                                              .PRECIO_DESCUENTO
                                          )
                                        )}
                                        <span
                                          className="p-2"
                                          style={{
                                            color: "#BFB7B9",
                                            textDecoration: "line-through",
                                          }}
                                        >
                                          {formatMoneda(parseFloat(row.precio))}
                                        </span>
                                      </span>
                                    </>
                                  )}
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 py-2">
                        <Divider light />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="p-3 pt-1">
                <Link
                  className=""
                  to="/orden"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <Button
                    onClick={() => {
                      handleClose();
                    }}
                    loadingPosition="start"
                    className="btn btn-michelle mt-2 w-100  py-2 px-4 "
                    variant="contained"
                  >
                    <span>Comprar</span>
                  </Button>
                </Link>

                <center>
                  <Typography
                    onClick={() => {
                      clearCart();
                    }}
                    className="mt-3 text-center font-OxygenBold  animated-text"
                    style={{ cursor: "pointer" }}
                  >
                    Vaciar carrito
                  </Typography>
                </center>
              </div>
            </>
          )}
        </div>
      </Menu>
    </React.Fragment>
  );
};

export default CarritoMini;
