import { styled } from "@mui/material/styles";
import {
  Divider,
  IconButton,
  Rating
} from "@mui/material";
import AccEditar from '../../assets/AccEditar.svg'
// import parse from 'html-react-parser';

const StyledRating = styled(Rating)({
  "& .MuiRating-iconFilled": {
    color: "#c9a396",
  },
  "& .MuiRating-iconHover": {
    color: "#ff3d47",
  },
});

export default function ReseniaPersona({
  row
  , id_cliente = 0
  , setShowDialogReseñaEditar
  , setComentarioEditar
}) {

  if (row.COMENTARIO.includes('\n')) {
    console.log("SALTO DE LINE:", row.COMENTARIO)
  }

  const EditarComentario = () => {
    setComentarioEditar(row)
    setShowDialogReseñaEditar(true)
  }

  const ARRAY_COMENTARIO = row.COMENTARIO.split("\n");

  return (
    <div className="mt-2 ">
      <div className="row">
        <div className="col-12 mt-1">
          <h6 className="font-OxygenMedium d-flex flex-wrap align-items-center justify-content-between w-100">
            {row.CLIENTE}
            {
              row.ID_CLIENTE == id_cliente && (
                <IconButton className="me-2" onClick={EditarComentario}>
                  <img src={AccEditar} />
                </IconButton>
              )
            }

          </h6>

          <StyledRating
            readOnly={true}
            name="customized-color"
            defaultValue={0}
            precision={1}
            size="small"
            value={row.CALIFICACION}
          />

          <h6
            className="font-Oxygen text-star"
            style={{ overflowWrap: 'break-word' }}
            dangerouslySetInnerHTML={{ __html: row.COMENTARIO }} />

          <Divider light />
        </div>
      </div>
    </div >
  );
}
