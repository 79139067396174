import React, { createContext, useContext, useEffect, useState } from 'react'; 

const CartInfoContext = createContext( );

const CartInfoProvider = ({ children }) => { 

  const orderInfo = {
    shippingMethod:{id:""},
    shippingPrice:[],
    paymentMethod:"",
    deliveryDate:"",
    deliveryTime:{ id: 0, 
      inicio: "00:00",
      fin: "00:00",  
  },
    deliveryMethod:"", 
    totalPrice: 0,
    domicilio:{
      id: 0,
      domicilio: "",
      nombre: "",
      tipo: "DOMICILIO",
      lat:0,
      long:0
    },
    sucursal:{
      id: 0,
      domicilio: "",
      nombre: "",
      tipo: "SUCURSAL",
    },
    precioEnvio:{
      idZona: 0,
      precioEnvio:0,
      zona: "", 
    },
    dedicatoria:{
      envia: "",
      recibe:"",
      dedicatoria: "", 
    },

  };  
  const [cartInfo, setCartInfo] = useState(() => {
    const storedCartItems = localStorage.getItem('cartInfo');
    return storedCartItems ? JSON.parse(storedCartItems) : orderInfo;
  }); 
  useEffect(() => {
    const savedCartInfo = localStorage.getItem('cartInfo');
    if (savedCartInfo) {  
      setCartInfo(JSON.parse(savedCartInfo));
    }
    else{  
      setCartInfo(orderInfo); 
    }
  }, []);

  useEffect(() => {  
    localStorage.setItem('cartInfo', JSON.stringify(cartInfo));
  }, [cartInfo]);

  return (
    <CartInfoContext.Provider value={{ cartInfo, setCartInfo }}>
      {children}
    </CartInfoContext.Provider>
  );
};

const useCartInfo = () => {
  const context = useContext(CartInfoContext);
  if (!context) {
    throw new Error('useCartInfo must be used within a CartInfoProvider');
  }
  return context;
};

export { CartInfoProvider, useCartInfo };
