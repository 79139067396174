import Loading from "../../../Includes/Loading";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { getData } from "../../../Context/backend";

const PDFFactura = () => {
  const { id, uuid } = useParams();
  const idFactura = id ?? 0;
  const uuidFactura = uuid ?? "";

  const [base64Data, setBase64PDF] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    document.title = `Ver factura`;
    let url = "reportes/ReporteFactura?";
    if (idFactura != 0) {
      url += "id=" + idFactura;
    } else {
      url += "uuid=" + uuidFactura;
    }

    getData(url)
      .then((res) => {
        if (!res.error) {
          console.log(res.data.respuesta.base64);
          const base64 = res.data.respuesta.base64;
          setBase64PDF(base64);
        } else {
          console.log(res.error);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return (
      <>
        <h6 className="text-center pt-5">Cargando factura, por favor espere</h6>
        <div className="p-5 mt-3">
          <Loading />
        </div>
      </>
    );
  }

  if (!base64Data) {
    return (
      <div>
        No se pudo obtener el archivo PDF, revisa tu conexión a internet e
        intenta de nuevo
      </div>
    );
  }
  return (
    <object
      data={`data:application/pdf;base64,${base64Data}`}
      style={{ height: "100vh" }}
      type="application/pdf"
      width="100%"
      name="Factura"
    ></object>
  );
};

export default PDFFactura;
