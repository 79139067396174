import { stringify } from "query-string";
import { getData, postdData, postUrl, KEY } from "../../Context/backend";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import validator from "validator";

//////////////////
/*FUNCIONES GET */
//////////////////

//////////////////
/*FUNCIONES POST*/
//////////////////

export const guardar = async (
  nombre = "",
  tipo = "",
  id_rs = "",
  img = "",
  Values = {},
  Errores = {},
  setErrores = () => {}
) => {
  let error = false;

  /* REQUERIDOS */
  if (tipo == "Registro") {
    if (!Values.nombre || Values.nombre == "") {
      //console.log(Values);
      Errores.nombre = true;
      setErrores(Errores);
      error = true;
    }

    if (!Values.apellido || Values.apellido == "") {
      let Errores2 = Errores;

      Errores2.apellido = true;
      setErrores(Errores2);
      error = true;
    }

    if (!Values.correo || Values.correo == "") {
      Errores.correo = true;
      setErrores(Errores);
      error = true;
    } else {
      if (!validator.isEmail(Values.correo)) {
        Errores.correo = true;
        setErrores(Errores);
        error = true;
      }
    }
    if (!Values.telefono || Values.telefono == "") {
      Errores.telefono = true;
      setErrores(Errores);
      error = true;
    } else {
      if (!validator.isNumeric(Values.telefono)) {
        Errores.telefono = true;
        setErrores(Errores);
        error = true;
      } else if (Values.telefono.length != 10) {
        Errores.telefono = true;
        setErrores(Errores);
        error = true;
      }
    }

    if (!Values.fuente || Values.fuente == "") {
      Errores.fuente = true;
      setErrores(Errores);
      error = true;
    }

    if (!Values.contrasena || Values.contrasena == "") {
      Errores.contrasena = true;
      setErrores(Errores);
      error = true;
    }

    if (!Values.confirmcontrasena || Values.confirmcontrasena == "") {
      Errores.contrasena = true;
      Errores.confirmcontrasena = true;
      setErrores(Errores);
      error = true;
    }

    if (Values.contrasena != Values.confirmcontrasena) {
      console.log(
        "contraseña: ",
        Values.contrasena,
        "Confirmar: ",
        Values.confirmcontrasena
      );
      Errores.contrasena = true;
      Errores.confirmcontrasena = true;
      Errores.contraseniaEquals = true;
      setErrores(Errores);
      error = true;
    }
  }

  if (!error) {
    let queryParamsObj = {
      isByUser: true,
    };
    let data = [];
    const body = {
      id: 0,
      nombre: nombre,
      iD_RS: id_rs,
      tipo: tipo,
      img: img,
      apellidos: Values.apellido ? Values.apellido : "",
      correo: Values.correo ? Values.correo : "",
      telefono: Values.telefono ? Values.telefono : "",
      contrasenia: Values.contrasena ? Values.contrasena : "",
      confirmarcontrasenia: Values.confirmcontrasena
        ? Values.confirmcontrasena
        : "",
      fuente: Values.fuente,
      key:KEY
    };
    //console.log(body);
    const url = `usuarios/Insertaruserweb?${stringify(queryParamsObj)}`;

    const res = await postdData(url, body);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        console.log(data);
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "Completa todos los campos requeridos" });
    });
  }
};
