import {
  Alert,
  Avatar,
  Button,
  ButtonBase,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import Loading from "../../Includes/Loading";
import AddIcon from "@mui/icons-material/Add";

import withReactContent from "sweetalert2-react-content";
import { styled } from "@mui/material/styles";
import Swal from "sweetalert2";
import ModalDireccion from "../../Lib/Direcciones/Componentes/ModalDireccion";
import {
  Eliminar,
  getLista,
  seDefault,
} from "../../Lib/Direcciones/Funciones/Direcciones";
import AccEditar from "./../../assets/AccEditar.svg";
import AccEliminar from "./../../assets/AccEliminar.svg";

const MySwal = withReactContent(Swal);

const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
  ({ theme }) => ({
    "& .MuiSnackbar-root": {
      top: theme.spacing(15),
    },
  })
);

const MisDomicilios = () => {
  const [IsLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [showModalDireccion, SetshowModalDireccion] = useState(false);
  const [Lista, setLista] = useState([]);
  const [idDireccion, setIdDireccion] = useState(0);
 

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    getDireciones();
  }, []);

  const getDireciones = () => {
    setIsLoading(true);
    getLista("")
      .then((resp) => {
        let list = JSON.parse(resp.data); 
        setLista(list);
        setIsLoading(false);
      })
      .catch((resp) => {
        setLista([]);
        setIsLoading(false);
      });
  };

  const EliminarRegistro = (id) => {
    new Swal({
      title: "¿Estás seguro de eliminar esta dirección?",
      text: "esta acción no se puede deshacer",
      icon: "warning",
      showDenyButton: true,
      denyButtonText: "No, cancelar",
      confirmButtonText: "Si, estoy seguro",
      confirmButtonColor: "#c9a396",
      denyButtonColor: "#979797",
      reverseButtons: true,
    }).then(function (isConfirm) {
     
      
      if (isConfirm.isConfirmed) {
        if (id != undefined) {
          Eliminar(id)
            .then((resp) => {
              new Swal({
                title: "Exito!",
                text: "Eliminado correctamente",
                icon: "success",
              }).then(function () {
                getDireciones();
              });
            })
            .catch((resp) => {
              alert("error al eliminar");
            });
        }
      }
    });
  };

  const MarcarDefault = (id) => {
    new Swal({
      title: "¿Estás seguro de cambiar tu dirección predeterminada?",
      text: "Podrás cambiarla cuando quieras",
      icon: "warning",
      showDenyButton: true,
      denyButtonText: "No, cancelar",
      confirmButtonText: "Si, estoy seguro",
      confirmButtonColor: "#c9a396",
      denyButtonColor: "#979797",
      reverseButtons: true,
    }).then(function (isConfirm) {
     
      
      if (isConfirm.isConfirmed) {
        if (id != undefined) {
          seDefault(id)
            .then((resp) => {
              new Swal({
                title: "Exito!",
                text: "Actualizado correctamente",
                icon: "success",
              }).then(function () {
                getDireciones();
              });
            })
            .catch((resp) => {
              alert("error al eliminar");
            });
        }
      }
    });
  };

  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  return (
    <>
      <StyledSnackbar
        direction="right"
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {mensaje}
        </Alert>
      </StyledSnackbar>
      {showModalDireccion ? (
        <ModalDireccion
          SetshowModalDireccion={SetshowModalDireccion}
          getDireciones={getDireciones}
          id={idDireccion}
        />
      ) : (
        ""
      )}

      <div style={{ maxWidth: "950px" }}>
        <Row className="p-3">
          <div className="col-12 text-end">
            <Button
              onClick={() => SetshowModalDireccion(true)}
              className="btn shadow-none  btn-michelle  py-2 px-4 "
              variant="contained"
              style={{ max: "400px" }}
            >
              <span className={"px-2 "}>
                <AddIcon className="me-2" />
                Agregar
              </span>
            </Button>
          </div>

          {IsLoading ? (
            <Col sm={12} className="pt-5">
              <Loading />
            </Col>
          ) : (
            <div style={{ maxWidth: "950px" }}>
              <Row className="p-2">
                {Lista.map((row) => (
                  <>
                    <div
                      className="pb-0 px-3 px-md-4 pt-3  mt-3"
                      style={{ backgroundColor: "#F7F7F7" }}
                    >
                      <div className="row">
                        <div className="col-12">
                          <h6 className="font-OxygenBold">
                            {row.NOMBRE_CONTACTO}
                          </h6>
                        </div>

                        <div className="col-6 col-md-4 col-lg-3  mt-3 mt-md-1">
                          <small>
                            <p className="font-OxygenBold my-1">Calle</p>
                            <p className="font-OxygenLight my-1">{row.CALLE}</p>
                          </small>
                        </div>

                        <div className="col-6 col-md-4 col-lg-3  mt-3 mt-md-1">
                          <small>
                            <p className="font-OxygenBold my-1">
                              {" "}
                              Número exterior
                            </p>
                            <p className="font-OxygenLight my-1">
                              {row.NUMERO_EXT}
                            </p>
                          </small>
                        </div>

                        <div className="col-6 col-md-4 col-lg-3  mt-3 mt-md-1">
                          <small>
                            <p className="font-OxygenBold my-1">
                              Número interior
                            </p>
                            <p className="font-OxygenLight my-1">
                              {row.NUMERO_INT}
                            </p>
                          </small>
                        </div>

                        <div className="col-6 col-md-4 col-lg-3  mt-3 mt-md-1">
                          <small>
                            <p className="font-OxygenBold my-1">Colonia</p>
                            <p className="font-OxygenLight my-1">
                              {row.COLONIA}
                            </p>
                          </small>
                        </div>

                        <div className="col-6 col-md-4 col-lg-3  mt-3 mt-md-1">
                          <small>
                            <p className="font-OxygenBold my-1">
                              Código postal
                            </p>
                            <p className="font-OxygenLight my-1">{row.CP}</p>
                          </small>
                        </div>

                        <div className="col-6 col-md-4 col-lg-3  mt-3 mt-md-1">
                          <small>
                            <p className="font-OxygenBold my-1">Estado</p>
                            <p className="font-OxygenLight my-1">
                              {row.ESTADO}
                            </p>
                          </small>
                        </div>

                        <div className="col-6 col-md-4 col-lg-3  mt-3 mt-md-1">
                          <small>
                            <p className="font-OxygenBold my-1">Municipio</p>
                            <p className="font-OxygenLight my-1">
                              {row.MUNICIPIO}
                            </p>
                          </small>
                        </div>

                        <div className="col-6 col-md-4 col-lg-3  mt-3 mt-md-1">
                          <small>
                            <p className="font-OxygenBold my-1">
                              Tipo de domicilio
                            </p>
                            <p className="font-OxygenLight my-1">
                              {row.TIPODOMICILIO}
                            </p>
                          </small>
                        </div>

                        <div className="col-6 col-md-4 col-lg-3  mt-3 mt-md-1">
                          <small>
                            <p className="font-OxygenBold my-1">Entre calles</p>
                            <p className="font-OxygenLight my-1">
                              {row.CALLE1}
                            </p>
                            <p className="font-OxygenLight my-1">
                              {row.CALLE2}
                            </p>
                          </small>
                        </div>

                        <div className="col-12 col-lg-3  mt-3 mt-md-1">
                          <small>
                            <p className="font-OxygenBold my-1">Indicaciones</p>
                            <p className="font-OxygenLight my-1">
                              {row.INDICACIONES}
                            </p>
                          </small>
                        </div>

                        <div className="col-12 text-end">
                          <Stack
                            className="w-100 mt-2"
                            spacing={4}
                            direction="row"
                            justifyContent="end"
                          >
                            {
                              <Button
                                className="btn-accion"
                                variant="text"
                                onClick={() => {
                                  setIdDireccion(row.ID);
                                  SetshowModalDireccion(true);
                                }}
                              >
                                <img
                                  src={AccEditar}
                                  className="me-2 mb-1"
                                  style={{ width: "15px" }}
                                />{" "}
                                Editar
                              </Button>
                            }

                            <Button
                              className="btn-accion"
                              variant="text"
                              onClick={() => {
                                EliminarRegistro(row.ID);
                              }}
                            >
                              <img src={AccEliminar} className="me-2" />{" "}
                              Eliminar
                            </Button>
                          </Stack>
                        </div>
                      </div>
                    </div>
                    <div>
                      <FormControlLabel
                        onClick={() => {
                          if (!row.DEFAULT) {
                            MarcarDefault(row.ID);
                          }
                        }}
                        className="mb-2"
                        control={<Checkbox checked={row.DEFAULT} />}
                        label={
                          <Typography
                            className="font-Oxygen"
                            variant="body1"
                            component="span"
                            style={{ fontSize: "12px" }}
                          >
                            Mi dirección predeterminada
                          </Typography>
                        }
                      />
                    </div>
                  </>
                ))}
              </Row>
            </div>
          )}
        </Row>
      </div>
    </>
  );
};

export default MisDomicilios;
