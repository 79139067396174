import React, { useEffect, useState } from "react";
import FotoBanner from "../assets/Home/FotoBanner.webp";
import Banner2 from "../assets/Home/Banner2.webp";
import Workshops from "../assets/Home/Workshops.webp";
import { getLocalStorageJWT } from "../Context/Storage";

import AOS from "aos";
import "aos/dist/aos.css";
import { Button, IconButton } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import CarruselCategorias from "../Categorias/Componentes/CarruselCategorias";
import Video from "./Componentes/Video";
import CarruselNovedades from "../Productos/Componentes/CarruselNovedades";
import { ForkLeft } from "@mui/icons-material";
import { useMediaQuery } from "@material-ui/core";

export default function Dashboard() {
  const [isLoged, setIsLoged] = useState(false);
  const urls = {
    BannerHome1:
      "https://florcreativa.blob.core.windows.net/imagenes/bannerwebprincipal_mayo.webp?v121", // "https://florcreativa.blob.core.windows.net/imagenes/BannerHome1.webp",
    BannerHome2:
      "https://florcreativa.blob.core.windows.net/imagenes/BannerHome2.webp",
    BannerHome3:
      "https://florcreativa.blob.core.windows.net/imagenes/BannerHome3.webp",
    GraficoHomeFreeStyleBlanco:
      "https://florcreativa.blob.core.windows.net/imagenes/GraficoHomeFreeStyleBlanco.svg",
    GraficoHomeFreeStyleNegro:
      "https://florcreativa.blob.core.windows.net/imagenes/GraficoHomeFreeStyleNegro.svg",
    BannerWorkshops2:
      "https://florcreativa.blob.core.windows.net/imagenes/BannerWorkshops2",
    BannerWorkshops2:
      "https://florcreativa.blob.core.windows.net/imagenes/BannerWorkshops2.webp",
    Workshops2:
      "https://florcreativa.blob.core.windows.net/imagenes/Workshops2.webp",
  };
  const getInfoUsuario = async () => {
    if (getLocalStorageJWT() != "") {
      setIsLoged(true);
    }
  };
  useEffect(() => {
    getInfoUsuario().then(setTimeout(() => {}, 1500));
    AOS.init();
  }, []);

  const navegite = useNavigate();

  /*const isTabletOrMobile = useMediaQuery("(max-width: 1200px)");

  useEffect(() => {
    console.log("isTabletOrMobile", isTabletOrMobile);
  }, [isTabletOrMobile]);*/

  return (
    <>
      <div className="espacio-header" />
      <div
        id="carouselExampleInterval"
        className="carousel slide w-100"
        data-bs-ride="carousel"
        //style={{ background: "red", padding: "2px" }}
      >
        <div className="carousel-inner">
          {/*
            
            <div className="carousel-item active" data-bs-interval="10000">
              <div className="w-100" style={{ backgroundColor: "#BFB7B9" }}>
                <div
                  className="container-fluid"
                  style={{ backgroundColor: "#BFB7B9" }}
                >
                  <div className="row">
                    <div
                      className=" col-12 col-sm-6 col-md-7 col-lg-8 p-3  mt-5 mt-md-0  d-flex justify-content-center align-items-center "
                      data-aos="zoom-in-down"
                    >
                      <h1
                        className="tituloWhiteHome text-white font-PlayfairDisplay"
                        style={{ fontWeight: "100" }}
                      >
                        Flowers
                        <br />
                        for the mother
                      </h1>
                    </div>
                    <div
                      className=" col-12 col-sm-6 col-md-5 col-lg-4 p-0   "
                      style={{
                        //backgroundImage: `url(${row.IMG != "" ? row.IMG : ProdcutosArreglos})`,
                        backgroundImage: `url(${Banner2})`,
                        minHeight: "488px",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item" data-bs-interval="2000">
              <div className="w-100" style={{ backgroundColor: "#dcdbd6" }}>
                <div
                  className="container-xl"
                  style={{ backgroundColor: "#dcdbd6" }}
                >
                  <div className="d-flex justify-content-between w-100 align-items-center align-content-center flex-wrap flex-sm-nowrap">
                    <div
                      className="p-3 aligin-self-center mt-5 mt-md-0 w-100 w-md-50"
                      data-aos="zoom-in-down"
                    >
                      <h2 className="font-Oxygen">Nos encantaría ser parte</h2>
                      <h1 className="tituloWhiteHome text-white font-PlayfairDisplay">
                        De ese evento
                        <br />
                        tan especial
                      </h1>
                    </div>
                    <div className="p-3 aligin-self-center">
                      <img src={FotoBanner} className="img-fluid" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            */}

          <div
            className="carousel-item active"
            data-bs-interval="50000"
            style={{ maxHeight: "87vh" }}
          >
            <div
              className=" img-carroucel-home"
              style={{
                backgroundImage: `url("${urls.BannerHome1}")`,
              }}
            />
          </div>
          <div
            className="carousel-item"
            data-bs-interval="5000"
            style={{ maxHeight: "87vh" }}
          >
            <div
              className="img-carroucel-home"
              style={{
                backgroundImage: `url("${urls.BannerHome2}")`,
              }}
            />
            <div
              className="carousel-caption d-flex justify-content-start align-content-start"
              style={{
                //backgroundColor: "yellow",
                position: "absolute",
                bottom: 0,
                left: 100,
              }}
            >
              <img
                src={urls.GraficoHomeFreeStyleBlanco}
                style={{
                  maxWidth: "350px",
                  maxHeight: "350px",
                  //backgroundColor: "red",
                }}
              />
            </div>
          </div>
          <div
            className="carousel-item"
            data-bs-interval="5000"
            style={{ maxHeight: "87vh" }}
          >
            <div
              className="img-carroucel-home"
              style={{
                backgroundImage: `url("${urls.BannerHome3}")`,
              }}
            />
            <div
              className="carousel-caption d-flex justify-content-start align-content-start"
              style={{
                //backgroundColor: "yellow",
                position: "absolute",
                bottom: 0,
                left: 100,
              }}
            >
              <img
                src={urls.GraficoHomeFreeStyleBlanco}
                style={{
                  maxWidth: "350px",
                  maxHeight: "350px",
                  //backgroundColor: "red",
                }}
              />
            </div>
          </div>
        </div>

        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleInterval"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleInterval"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
        <button
          className="btn btn-outline-light btn-lg"
          onClick={() => {
            navegite("/productos");
          }}
          style={{
            backgroundColor: "transparent",
            border: "2px solid white",
            position: "absolute",
            bottom: "20px", // ajusta este valor según sea necesario
            left: "50%",
            transform: "translateX(-50%)",
            padding: "10px 25px"
          }}
        >
          <span style={{ color: "white", fontSize:"1.5rem" }}>SHOP ALL</span>
        </button>
      </div>

      <div
        className="w-100"
        style={{ backgroundColor: "#ebe1d6" }}
        data-aos="fade-up"
      >
        <div className="w-100 py-3 px-sm-5">
          <div className="d-flex flex-column align-items-center">
            <h2 className="font-PlayfairDisplay mt-4">Shop</h2>
            <div
              className="d-flex w-100 justify-content-center 
              mb-4 
              align-content-center align-items-center"
              ata-aos="fade-up"
              data-aos-duration="1500"
            >
              <h6 className="font-PlayfairDisplay text-rosa">(Our products)</h6>
              <div className="LineRosa" />
            </div>
          </div>
          <div className="w-100">
            <CarruselCategorias />
            {/* <div className="col-12 text-center mb-5">
                <Link
                  to="/productos"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <Button
                    className="btn shadow-none  btn-michelle-rouse font-OxygenMedium  py-2 px-4 mt-5  me-auto me-md-0 "
                    variant="contained"
                    style={{ max: "400px" }}
                  >
                    <span className={"px-2 font-OxygenMedium text-white"}>
                      Ver productos
                    </span>
                  </Button>
                </Link>
              </div> */}
          </div>
        </div>
      </div>

      <div className="w-100 my-5 py-5" />

      {/* <div className="w-100 my-5 py-5" />

        <h2
          className="font-PlayfairDisplayItalic text-center mb-4"
          data-aos="fade-up"
        >
          Novedades
        </h2>
        <div
          className="d-flex w-100 justify-content-center align-content-center align-items-center"
          ata-aos="fade-up"
          data-aos-duration="1500"
        >
          <div className="LineRosa me-3" />
          <div>
            <h6 className="font-PlayfairDisplayItalic text-rosa">(New in)</h6>
          </div>
          <div className="LineRosa ms-3" />
        </div>

        <div className="container p-3">
          <div className="row">
            <div className="col-12">
              <CarruselNovedades />
            </div>
          </div>
                </div>*/}
    </>
  );
}
