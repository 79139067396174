// import React, { useEffect, useState } from "react";

// import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import Skeleton2 from "../../assets/Skeleton2.webp";
// import { Link } from "react-router-dom";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
// import ImageZoom from "react-image-zooom";

const GaleriaImagenes = ({ imagenes }) => {
  if (imagenes.length == 0) {
    return (
      <center>
        <img src={Skeleton2}
          className="img-fluid m-auto"
        />
      </center>


    )

  }
  return (
    <>

      <div id="myCarousel" className="carousel slide" data-bs-ride="carousel" align="center">
        <div className="carousel-inner">
          {
            imagenes.map((row, index) => (
              <div className={"p-0 px-md-2 px-lg-5 carousel-item" + (index == 0 ? ' active ' : '')}>
                <img src={(row.IMG != "" ? row.IMG : Skeleton2)} className="img-item" />
                {/* <ImageZoom  src={ (row.IMG != "" ? row.IMG : Skeleton2)} alt="Cargando imagen" zoom="200" className="img-item"/> */}
              </div>
            )
            )
          }
        </div>
        <ol className="carousel-indicators list-inline d-flex flex-wrap mx-0">
          {
            imagenes.map((row, index) => (
              <li className={"list-inline-item mx-2" + (index == 0 ? ' active ' : '')}>
                <a id={"carousel-selector-" + index} className="selected" data-bs-slide-to={index} data-bs-target="#myCarousel">
                  <div className="img-min" style={{ backgroundImage: 'url(' + (row.IMG != "" ? row.IMG : Skeleton2) + ')' }} />
                </a>
              </li>
            )
            )
          }
        </ol>

        <button class="carousel-control-prev" type="button" data-bs-target="#myCarousel" data-bs-slide="prev" style={{width: 30}}>
          <ArrowBackIosIcon style={{ color: '#191919', marginTop: -100 }} />
          <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#myCarousel" data-bs-slide="next" style={{width: 30}}>
          <ArrowForwardIosIcon style={{ color: '#191919', marginTop: -100 }} />
          <span class="visually-hidden">Next</span>
        </button>
      </div>

     

    </>
  );
};
export default GaleriaImagenes;
