import React, { useEffect, useState } from "react";
import logo from "../assets/logoMichelle.svg"; 
const StartLoad = ({ isLoged }) => { 
  return (
    <div className="d-flex justify-content-center  ling-items-center" 
    style={{height:"100vh",   backgroundColor: "white"}}>
      {<img src={logo} alt="asd" className="img-fluid" style={{maxWidth:"150px"}} />} 
    </div>
  );
};

export default StartLoad;
