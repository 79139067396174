import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { FormHelperText } from "@mui/material";
export default function SelectFuente({
  value = "",
  name = "",
  label = "",
  error = false,
  onChange = () => {},
}) {
  const [fuente, setFuente] = React.useState(value);
  const handleChange = (event) => {
    setFuente(event.target.value);
  };
  useEffect(() => {
    onChange({ target: { name: name, value: fuente } });
  }, [fuente]);
  return (
    <>
      <FormControl fullWidth error={error}>
        <InputLabel id="demo-simple-select-label">{label}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={fuente}
          label={label}
          onChange={handleChange}
        >
          <MenuItem value={"facebook"}>Facebook</MenuItem>
          <MenuItem value={"Instagram"}>Instagram</MenuItem>
          <MenuItem value={"TikTok"}>TikTok</MenuItem>
          <MenuItem value={"Whatsapp"}>Whatsapp</MenuItem>
          <MenuItem value={"Email"}>Email</MenuItem>
          <MenuItem value={"Punto de Venta"}>Punto de Venta</MenuItem>
          <MenuItem value={"Recomendación"}>Recomendación</MenuItem>
          <MenuItem value={"otro"}>Otro</MenuItem>
        </Select>
        <FormHelperText>{error ? "Selecciona un valor" : ""}</FormHelperText>
      </FormControl>
    </>
  );
}
