import React, { useState } from "react";
import {
  TextField,
  Button,
  Grid,
  FormControl,
  InputLabel,
  InputAdornment,
  IconButton,
  OutlinedInput,
  CardContent,
  Snackbar,
  Card,
  Alert,
  CardHeader,
  Typography,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

import { Link, useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import withReactContent from "sweetalert2-react-content";
import loogo from "./../../assets/LogoNegrocreativoMichelle.svg";
import Swal from "sweetalert2";
import { guardar } from "../funciones/login";
import { setLocalStorage, setLocalStorageJWT } from "../../Context/Storage";
import SelectFuente from "./SelectFuente";
import { Col, Row } from "react-bootstrap";
import GoogleButton from "./GoogleButton";
import FacebookButton from "./FacebookButton";

const MySwal = withReactContent(Swal);
export default function Registro() {
  const [Values, setValues] = useState({
    nombre: "",
    apellido: "",
    correo: "",
    telefono: "",
    contrasena: "",
    confirmcontrasena: "",
    fuente: "",
  });
  const [Errores, setErrores] = useState({
    nombre: false,
    apellido: false,
    correo: false,
    telefono: false,
    contrasena: false,
    confirmcontrasena: false,
    fuente: false,
    contraseniaEquals: false,
  });
  const [IsGuardando, setIsGuardando] = useState(false);
  const [open, setOpen] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const navigate = useNavigate();

  const handleInputChange = ({ target }) => {
    const Name = target.name;
    const Value = target.value;
    let NewValue = {
      ...Values,
      [Name]: Value,
    };
    if (Name == "telefono") {
      const re = /^[0-9\b]+$/;
      if (re.test(target.value)) {
        NewValue = { ...NewValue, telefono: target.value };
      }
    }
    if (
      (Name == "contrasena" || Name == "confirmcontrasena") &&
      Errores.contraseniaEquals
    ) {
      let Errores2 = Errores;

      Errores2.contrasena = false;
      Errores2.confirmcontrasena = false;
      Errores2.contraseniaEquals = false;
      setErrores(Errores2);
    }

    setValues(NewValue);
    setErrores({
      ...Errores,
      [Name]: false,
    });
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (e) => {
    guardar(Values.nombre, "Registro", "", "", Values, Errores, setErrores)
      .then((data) => {
        console.log(data);
        if (data.codigo == "200") {
          setIsGuardando(false);
          MySwal.fire({
            title: "Correcto",
            html: data.mensaje,
            icon: "success",
            confirmButtoColor: "#3ABE88",
            showConfirmButton: false,
            timer: 1700,
          }).then((result) => {
            setLocalStorageJWT(data.respuesta.token);
            setLocalStorage("img", data.respuesta.img);
            window.location.href = "/";
            Location.reload();
          });
        } else {
          setMensaje(data.mensaje);
          setOpen(true);
          setIsGuardando(false);
        }
      })
      .catch((data) => {
        setMensaje(data.mensaje);
        setOpen(true);
        setIsGuardando(false);
      });
    //e.preventDefault();
    //console.log(formValues); // Aquí puedes enviar los datos a la API o a otro componente
    //window.location.href = "/";
  };

  const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
    ({ theme }) => ({
      "& .MuiSnackbar-root": {
        top: theme.spacing(15),
      },
    })
  );

  const handleRsCredencials = (obj) => {
    guardar(
      obj.nombre,
      obj.tipo,
      obj.idRS,
      obj.img,
      { correo: obj.correo },
      {},
      () => {}
    )
      .then((data) => {
        console.log(data);
        if (data.codigo == "200") {
          setLocalStorageJWT(data.respuesta.token);
          setLocalStorage("img", data.respuesta.img);
          window.location.href = "/";
          Location.reload();
        } else {
          setMensaje("Acceso incorrecto, verifica tus credenciales");
          setOpen(true);
        }
      })
      .catch((data) => {
        console.log(data);
      });
  };

  return (
    <>
      <StyledSnackbar
        direction="right"
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {mensaje}
        </Alert>
      </StyledSnackbar>
      <div className="container mt-5">
        <div className=" p-4 m-auto  " style={{ maxWidth: "450px" }}>
          <center>
            <Link to="/home" style={{ textDecoration: "none", color: "black" }}>
              <img src={loogo} className="img-fluid mb-4" />
            </Link>
          </center>
          <CardContent>
            <Row>
              <Col xs={12} className="p-2">
                <GoogleButton
                  className="shadow-lg mt-3"
                  handleRsCredencials={handleRsCredencials}
                />
                <div className="mb-3 w-100"> </div>
               
                {
                  /**
                   
                   <FacebookButton
                  className="shadow mt-3"
                  handleRsCredencials={handleRsCredencials}
                />
                   */
                }
              </Col>
              <Col xs={12} className="p-2">
                <TextField
                  required
                  fullWidth
                  label="Nombre"
                  name="nombre"
                  value={Values.nombre}
                  error={Errores.nombre}
                  onChange={handleInputChange}
                />
              </Col>

              <Col xs={12} className="p-2">
                <TextField
                  required
                  fullWidth
                  label="Apellido"
                  name="apellido"
                  value={Values.apellido}
                  error={Errores.apellido}
                  onChange={handleInputChange}
                />
              </Col>

              <Col xs={12} className="p-2">
                <TextField
                  required
                  fullWidth
                  label="Correo electrónico"
                  name="correo"
                  value={Values.correo}
                  error={Errores.correo}
                  onChange={handleInputChange}
                />
              </Col>

              <Col xs={12} className="p-2">
                <TextField
                  required
                  fullWidth
                  label="Teléfono"
                  name="telefono"
                  inputProps={{ maxLength: 10 }}
                  value={Values.telefono}
                  error={Errores.telefono}
                  onChange={handleInputChange}
                />
              </Col>

              <Col xs={12} className="p-2">
                <SelectFuente
                  label="¿Donde nos conociste?"
                  name="fuente"
                  error={Errores.fuente}
                  value={Values.fuente}
                  onChange={handleInputChange}
                />
              </Col>

              <Col xs={12} className="p-2">
                <TextField
                  required
                  fullWidth
                  label="Contraseña"
                  name="contrasena"
                  type="password"
                  value={Values.contrasena}
                  error={Errores.contrasena}
                  onChange={handleInputChange}
                  helperText={
                    Errores.contrasena
                      ? Errores.contraseniaEquals
                        ? "Contraseñas no coinciden"
                        : ""
                      : ""
                  }
                />
              </Col>

              <Col xs={12} className="p-2">
                <TextField
                  required
                  fullWidth
                  label="Confirmar contraseña"
                  name="confirmcontrasena"
                  type="password"
                  value={Values.confirmcontrasena}
                  error={Errores.confirmcontrasena}
                  onChange={handleInputChange}
                  helperText={
                    Errores.confirmcontrasena
                      ? Errores.contraseniaEquals
                        ? "Contraseñas no coinciden"
                        : ""
                      : ""
                  }
                />
              </Col>

              <Col className="p-2">
                <LoadingButton
                  loading={IsGuardando}
                  loadingPosition="start"
                  onClick={() => {
                    handleSubmit();
                  }}
                  className="btn btn-michelle w-100 font-AvenirMedium  py-2 px-4 "
                  variant="contained"
                  xs={{ with: "100%" }}
                >
                  <span className={IsGuardando ? "px-4" : "px-2"}>
                    {IsGuardando ? "Guardando..." : "Regístrame"}
                  </span>
                </LoadingButton>
              </Col>
            </Row>
          </CardContent>

          <Typography className="m-1 text-center mt-5 font-Oxygen">
            ¿Ya tienes una cuenta?
          </Typography>
          <Link
            className="w-100"
            to={"/login"}
            style={{
              textTransform: "none",
              textDecoration: "none",
            }}
          >
            <center>
              <Typography className="m-1 mt-0 text-center font-OxygenBold text-rosa  animated-text">
                Inicia sesión aquí
              </Typography>
            </center>
          </Link>
        </div>
      </div>
    </>
  );
}
