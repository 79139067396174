import React, { useEffect, useState } from "react";
import FavoritosOFF from "../../assets/FavoritosOFF.svg";
import Skeleton2 from "../../assets/Skeleton2.webp";
import { Link, useParams } from "react-router-dom";
import { getListaFavoritos } from "../Funciones/Productos";
import { formatMoneda } from "../../Lib/Funciones Generales/Generales";
import { Chip, IconButton, Typography } from "@mui/material";
import Loading from "../../Includes/Loading";
import BotonFavorito from "../../Lib/Favoritos/BotonFavorito";
import Descuentos from "./Descuentos";

const Productos = () => {
  const [IsLoading, setIsLoading] = useState(true);
  const [Lista, setLista] = useState([]);
  const { id } = useParams();
  const [stateRow, setStateRow] = useState();

  const getProductos = () => {
    setIsLoading(true);
    getListaFavoritos(id == undefined ? 0 : id)
      .then((resp) => {
        let list = JSON.parse(resp.data);
        console.log(list);
        setLista(list);
        setIsLoading(false);
      })
      .catch((resp) => {
        setLista([]);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getProductos();
  }, []);

  return (
    <>
      <div className="espacio-header" />
      <div className="container pt-5">
        <div className="d-flex justify-content-between flex-wrap">
          <div className="">
            <h2 className="font-PlayfairDisplayItalic text-star   mb-5">
              {IsLoading
                ? "Tus favoritos"
                : Lista.length != 0
                ? Lista[0].CATEGORIA
                : "No cuentas con productos favoritos"}
            </h2>
          </div>
          <div className="">
            <Link
              className="w-100"
              to={"/"}
              style={{
                textTransform: "none",
                textDecoration: "none",
              }}
            >
              <Typography className="m-1 mt-0 text-center font-OxygenBold text-rosa  animated-text">
                Ir a inicio
              </Typography>
            </Link>
          </div>
        </div>

        <div className="row">
          {IsLoading ? (
            <div className="pt-5 w-100">
              <Loading />
            </div>
          ) : (
            Lista.map((row) => (
              <div className="p-2 p-md-3 mb-5 col-12 col-sm-6 col-md-4">
                <Link
                  to={"/productos/view/" + row.ID}
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <div
                    className="w-100"
                    style={{
                      backgroundImage: `url(${
                        row.IMG != "" ? row.IMG : Skeleton2
                      })`,
                      // backgroundImage: `url(${ProdcutosArreglos})`,
                      minHeight: "370px",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      position: "relative",
                    }}
                  >
                    {/* <Chip
                      className="text-white px-3 position-absolute"
                      label={row.CATEGORIA}
                      style={{
                        backgroundColor: "#BFB7B9",
                        top: "330px",
                        left: "8px",
                      }}
                    /> */}

                    <div
                      style={{
                        position: "absolute",
                        top: "5px",
                        right: "5px",
                      }}
                    >
                      <BotonFavorito
                        id={row.ID}
                        favorito={row.FAVORITO}
                        Lista={Lista}
                        setLista={setLista}
                        setStateRow={setStateRow}
                      />
                    </div>
                  </div>
                </Link>
                <h6 className="font-Oxygen mb-1 mt-3 text-star">
                  <Descuentos
                    descuentos={row.DESCUENTOS}
                    precio={row.PRECIO_PUBLICO}
                  />
                </h6>
              </div>
            ))
          )}
        </div>
      </div>
      <div className="w-100 my-5 py-5" />
      <div className="w-100 my-5 py-5" />
    </>
  );
};
export default Productos;
