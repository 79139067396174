import { Route, Routes } from "react-router-dom";
import Error404 from "../Includes/Error404";

import Orden from "./Componentes/Orden";
import PagoSuccess from "./Componentes/PagoSuccess";
const OrdenRoute = () => {
  return (
    <>
      <Routes>
      <Route path="/*" element={<Orden/>} />  
        <Route path="/pagosuccess/:id" element={<PagoSuccess />} />
        <Route path="*" element={<Error404 />} />
      </Routes>
    </>
  );
};

export default OrdenRoute;
