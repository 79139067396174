import Left from "../../assets/left-arrow.svg";
import Right from "../../assets/right-arrow.svg";
export const FlechasSplide = () => {
    return (
        <div className="splide__arrows">
            <button className="splide__arrow splide__arrow--prev" style={{
                backgroundColor: '#eee',
                borderRadius: "1em"
            }}>
                <img
                    src={Left}
                    width="15px"

                />
            </button>

            <button className="splide__arrow splide__arrow--next"
                style={{
                    backgroundColor: '#eee',
                    borderRadius: "1em"
                }}
            >
                <img
                    src={Right}
                    width="15px"

                />
            </button>
        </div>
    )
}