import { Autocomplete, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { getHorarios } from "../Funciones/Horarios";

const Horarios = ({
  Value,
  Error,
  horario,
  setHorario,
  Name = "Horario",
  fecha,
  sucursal,
}) => {
  const opcionInicial = { ID: 0, RANGO: "Horario de entrega" };
  const [ValorFiltrado, setFiltro] = useState();
  const [horarios, setHorarios] = useState({
    cargado: true,
    lista: [opcionInicial],
  });

  useEffect(() => {
    setHorarios({ cargado: true, lista: [opcionInicial] });
    
    getHorarios(sucursal, fecha)
      .then((resp) => {
        let list = JSON.parse(resp.data);

        console.log(list);
        if (list.length != 0) {
          setHorarios({ cargado: false, lista: list });
        } else {
          setHorarios({
            cargado: true,
            lista: [{ ID: 0, RANGO: "Sin horarios disponibles" }],
          });
        }
      })
      .catch((resp) => {
        setHorarios({
          cargado: true,
          lista: [{ ID: 0, RANGO: "Sin horarios disponibles" }],
        });
      });
  }, [fecha, sucursal]);

  const getOpcion = () => {
    console.log(horarios);
    const opcion = horarios.lista.find((x) => x.ID == horario.id);
    if (opcion) {
      return opcion;
    } else {
      return null;
    }
  };

  return !horarios.cargado ? (
    <Autocomplete
      size="small"
      fullWidth
      defaultValue={getOpcion}
      isOptionEqualToValue={(option, value) => {
        return option.ID == value.ID;
      }}
      inputValue={ValorFiltrado}
      onChange={(e, value) => {
        if (value) {
          console.log(value);
          horario.id = value.ID;
          horario.inicio = value.INICIO;
          horario.fin = value.FIN;
          setHorario({ ...horario });
        } else {
          setHorario({ id: 0, inicio: "00:00", fin: "00:00" });
        }
      }}
      onInputChange={(event, newInputValue) => {
        setFiltro(newInputValue);
      }}
      options={horarios.lista}
      getOptionLabel={(option) => {
        return option.RANGO;
      }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            error={Error}
            helperText={Error ? "Selecciona un horario" : ""}
            label="horario"
          />
        );
      }}
    />
  ) : (
    <TextField
      size="small"
      fullWidth
      disabled={true}
      value={"Horarios disponibles"}
    />
  );
};

export default Horarios;
