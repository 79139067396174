import {  Route, Routes } from "react-router-dom";
 
import Productos from "./Componentes/Productos"; 
import Error404 from "../Includes/Error404";
import Producto from "./Componentes/Producto";
import Categorias from "../Categorias/Componentes/Categorias";
import Favoritos from "./Componentes/Favoritos"

 const ProductosRote =()=> { 
  return (
    <> 
     <Routes>     
        <Route index element={<Categorias/>} />  
        <Route path="/favoritos" element={<Favoritos/>} />
        <Route path="/cat" element={<Productos/>} /> 
        <Route path="/view/:id" element={<Producto />} /> 
        <Route path="*" element={<Error404 />} />
    </Routes>
    </> 
  );
}

export default ProductosRote;