import React from "react";
import { Select, FormControl, InputLabel, MenuItem } from "@mui/material";

export default function FormasDePago({
  showCodigos = false,
  Value,
  Error,
  name = "forma_pago",
  handleInputChange = () => {},
  vista = false,
}) {
  return (
    <>
      <FormControl fullWidth>
        <InputLabel id="demo-select-small-label">Forma de pago</InputLabel>
        <Select
          labelId="demo-select-small-label"
          id="demo-select-small"
          value={Value}
          error={Error}
          label="Forma de pago"
          name={name}
          onChange={handleInputChange}
          fullWidth
          defaultValue={"01"}
          readOnly={vista}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={"01"}>{showCodigos && "01 - "}Efectivo</MenuItem>
          <MenuItem value={"02"}>
            {showCodigos && "02 - "}Cheque nominativo
          </MenuItem>
          <MenuItem value={"03"}>
            {showCodigos && "03 - "}Transferencia electrónica de fondos
          </MenuItem>
          <MenuItem value={"04"}>
            {showCodigos && "04 - "}Tarjeta de crédito
          </MenuItem>
          <MenuItem value={"05"}>
            {showCodigos && "05 - "}Monedero electrónico
          </MenuItem>
          <MenuItem value={"06"}>
            {showCodigos && "06 - "}Dinero electrónico
          </MenuItem>
          <MenuItem value={"08"}>
            {showCodigos && "08 - "}Vales de despensa
          </MenuItem>
          <MenuItem value={"12"}>
            {showCodigos && "12 - "}Dación en pago
          </MenuItem>
          <MenuItem value={"13"}>
            {showCodigos && "13 - "}Pago por subrogación
          </MenuItem>
          <MenuItem value={"14"}>
            {showCodigos && "14 - "}Pago por consignación
          </MenuItem>
          <MenuItem value={"15"}>{showCodigos && "15 - "}Condonación</MenuItem>
          <MenuItem value={"17"}>{showCodigos && "17 - "}Compensación</MenuItem>
          <MenuItem value={"23"}>{showCodigos && "23 - "}Novación</MenuItem>
          <MenuItem value={"24"}>{showCodigos && "24 - "}Confusión</MenuItem>
          <MenuItem value={"25"}>
            {showCodigos && "25 - "}Remisión de deuda
          </MenuItem>
          <MenuItem value={"26"}>
            {showCodigos && "26 - "}Prescripción o caducidad
          </MenuItem>
          <MenuItem value={"27"}>
            {showCodigos && "27 - "}A satisfacción del acreedor
          </MenuItem>
          <MenuItem value={"28"}>
            {showCodigos && "28 - "}Tarjeta de débito
          </MenuItem>
          <MenuItem value={"29"}>
            {showCodigos && "29 - "}Tarjeta de servicios
          </MenuItem>
          <MenuItem value={"30"}>
            {showCodigos && "30 - "}Aplicación de anticipos
          </MenuItem>
          <MenuItem value={"31"}>
            {showCodigos && "31 - "}Intermediario pagos
          </MenuItem>
          <MenuItem value={"99"}>{showCodigos && "99 - "}Por definir</MenuItem>
        </Select>
      </FormControl>
    </>
  );
}
