import { TextField, Typography } from "@mui/material";
import React from "react";
import { Col, Row } from "react-bootstrap";
import UsoCFDI from "../../../../../Lib/SAT/Componentes/UsoCDFI";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Tune } from "@mui/icons-material";
import FormasDePago from "../../../../../Lib/SAT/Componentes/FormasDePago";
//import SelectSeries from "../../../../Catalogos/Series/Componentes/SelectSeries";

export default function TabDatosCFDI({
  handleInputChange = () => {},
  ValuesSend = {},
  Errores = {},
}) {
  const caracteresRestantes = (tam, max) => {
    const total = max - Number(tam);

    if (Number(tam) == 0) {
      return "";
    } else {
      return `(${total})`;
    }
  };

  return (
    <>
      <Row className="">
        <Col xs={4} className="p-3">
          <TextField
            fullWidth
            value={"MXN"}
            InputProps={{
              readOnly: true,
            }}
            label="Moneda"
          />
        </Col>

        <Col xs={4} className="p-3">
          <FormasDePago
            Value={ValuesSend.forma_pago}
            Error={Errores.forma_pago}
            handleInputChange={handleInputChange}
            showCodigos
            vista
          />
        </Col>

        <Col xs={4} className="p-3">
          <UsoCFDI
            Value={ValuesSend.uso_cfdi}
            handlInputChange={handleInputChange}
            Error={Errores.uso_cfdi}
          />
        </Col>
      </Row>
    </>
  );
}
