import * as React from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { Typography } from '@mui/material';
import { formatMoneda } from '../../Lib/Funciones Generales/Generales';

function valuetext(value) {
    return `${value}°C`;
}

const minDistance = 100;

export default function RangeSliderPrice({
    value,
    setValue,
    className = "",
    width = 200,
    minValue = 0,
    maxValue = 0
}) {

    const handleChange1 = (event, newValue, activeThumb) => {
        if (!Array.isArray(newValue)) {
            return;
        }
        if (activeThumb === 0) {
            setValue([Math.min(newValue[0], value[1] - minDistance), value[1]]);
        } else {
            setValue([value[0], Math.max(newValue[1], value[0] + minDistance)]);
        }
    };

    return (
        <div className={'d-flex flex-wrap' + ' ' + className} style={{ width: width }}>
            <div className='d-flex w-100 justify-content-center'>
                <Typography className={"m-1 mt-0 font-Oxygen mt-4 text-center"} style={{ cursor: 'pointer' }}>
                    {
                        `${formatMoneda(value[0])} - ${formatMoneda(value[1])}`
                    }
                </Typography>
            </div>
            <Slider
                sx={{
                    color: "black",
                    "& .MuiSlider-track": {
                        height: "1px !important",
                    },
                    "& .MuiSlider-thumb": {
                        border: "2px solid black",
                        color: "white !important",
                        "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
                            boxShadow: "none !important"
                        }
                    }
                }}
                getAriaLabel={() => 'Minimum distance'}
                value={value}
                onChange={handleChange1}
                valueLabelDisplay="off"
                getAriaValueText={valuetext}
                disableSwap

                min={minValue}
                max={maxValue}
            />
        </div>
    );
}