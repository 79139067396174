import { stringify } from "query-string";
import {
  KEY_KONECTA,
  getData,
  postUrl,
  postdData,
} from "../../Context/backend";
import validator from "validator";

const Conekta = window.Conekta;
Conekta.setPublicKey(KEY_KONECTA);
Conekta.setLanguage("es");

export const getLista = async (query) => {
  let data = [];
  let queryParamsObj = {
    query: query,
  };

  
  const url = `tarjetas/Listar?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

////////////////////
/* FUNCIONES POST */
////////////////////


export const guardar = async (Values, Errores, setErrores, setToken) => {
  let error = false;

  if (!Values.nombre || Values.nombre == "") {
    Errores.nombre = true;
    setErrores(Errores);
    error = true;
  }

  let Numero = Values.numero.replace(/\s/g, "");

  if (!Numero || Numero == "") {
    Errores.numero = true;
    setErrores(Errores);
    error = true;
  } else {
    if (!validator.isNumeric(Numero)) {
      Errores.numero = true;
      setErrores(Errores);
      error = true;
    } else if (Numero.length != 16) {
      alert("Error");
      Errores.numero = true;
      setErrores(Errores);
      error = true;
    }
  }

  if (!Values.ccv || Values.ccv == "") {
    Errores.ccv = true;
    setErrores(Errores);
    error = true;
  } else {
    if (!validator.isNumeric(Values.ccv)) {
      Errores.ccv = true;
      setErrores(Errores);
      error = true;
    } else if (Values.ccv.length < 3) {
      Errores.ccv = true;
      setErrores(Errores);
      error = true;
    }
  }

  if (!Values.vencimiento || Values.vencimiento == "") {
    Errores.vencimiento = true;
    setErrores(Errores);
    error = true;
  }
  if (Values.vencimiento.length != 5) {
    Errores.vencimiento = true;
    setErrores(Errores);
    error = true;
  }

  let arreglo = Values.vencimiento.split("/");
 

  if (arreglo[0] == undefined || arreglo[1] == undefined) {
    Errores.vencimiento = true;
    setErrores(Errores);
    error = true;
  }
  if (!error) {
 
  const card = {
    number: Numero,
    name: Values.nombre,
    cvc: Values.ccv,
    exp_month: arreglo[0],
    exp_year: arreglo[1],
  };

 
  
 let id=undefined; 
  const   response  = await Conekta.Token.create({ card },
    function async(token) { 
    setToken(token.id) 
  }, function(error) { 
    setToken("Error") ;
  })
  return new Promise((resolve, reject) => {
    resolve({ mensaje: "" });
  });
  return;
 
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "Completa todos los campos requeridos" });
    });
  }
};


export const guardarTarjeta = async (id, nombre, isDefault) => {
    
     
  if( id==undefined)
  {
      return new Promise((resolve, reject) => {
          resolve({ mensaje: "Ocurrió un error al validar los datos de tu tarjeta, verificalos e intenta de nuevo" });
        });
  } 

  if( nombre==undefined || nombre=="" || nombre==null )
  {
      return new Promise((resolve, reject) => {
          resolve({ mensaje: "Ingresa el nombre del titular de la tarjeta" });
        });
  } 
  
      let data = [];
      const body = {
          tokenTarjeta :id
      };
   
      const url = "Tarjetas/Insertar?tokenTarjeta="+id+"&nombre="+nombre+"&isDefault="+isDefault;
      
      const res = await postdData(url, null);
      return new Promise((resolve, reject) => {
        if (!res.error) {
          data = res.data; 
          resolve(data);
        } else {
          reject(res);
        }
      });
    
  };
  



export const Eliminar = async (id, idTarjeta) => {
  if (id != undefined) {
    let data = [];
    let queryParamsObj = {
      id: id,
      idTarjeta: idTarjeta
    };
    const url = `Tarjetas/Eliminar?${stringify(queryParamsObj)}`;
    const res = await postUrl(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};


export const seDefault = async (id) => {
  if (id != undefined) {
    let data = [];
    let queryParamsObj = {
      id: id,
    };
    const url = `Tarjetas/seDefault?${stringify(queryParamsObj)}`;
    const res = await postUrl(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};