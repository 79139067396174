import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Error404 from "../Includes/Error404";
import PDFFactura from "../MiPerfil/Componentes/MisPedidos/PDFFactura";

const ReportesPDF = () => {
  return (
    <Routes>
      <Route path="factura/:id" element={<PDFFactura />}></Route>
    </Routes>
  );
};
export default ReportesPDF;
