import React, { useEffect } from "react";
import CarritoVacio from "../assets/CarritoVacio.svg";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";

const CartVacio = () => {
  useEffect(() => {
    document.title = `Carrito vacío`;
  }, []);
  
  return (
    <div className="w-100 h-100 d-flex justify-content-center mt-5 pt-5">
      <div>
        <div>
          <center>
            <img
              src={CarritoVacio}
              className="img-fluid mt-5"
              style={{ maxWidth: "80px" }}
            />
          </center>
        </div>
        <h6 className="font-OxygenLight mt-2 pt-2">
          Tu carrito esta vacío
        </h6>
<center>
     <Link
              className="w-100 text-center"
              to={"/productos"}
              style={{
                textTransform: "none",
                textDecoration: "none",
              }}
            >
              <Typography className="m-1 mt-2 text-center font-OxygenBold text-rosa  animated-text">
                Ir a productos
              </Typography>
            </Link>
</center>
       

      </div>
    </div>
  );
};

export default CartVacio;
