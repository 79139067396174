import React, { useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

const TallasSelector = ({ tallas, onSelect }) => {
  const [selectedTalla, setSelectedTalla] = useState(null);

  const handleTallaClick = (tallaId, tallaNombre) => {
    setSelectedTalla(tallaId);
    onSelect(tallaId, tallaNombre);
  };

  return (
    <Grid container spacing={2}>
      {tallas.map((talla) => (
        <Grid item xs={6} sm={4} md={3} key={talla.ID}>
          <Card
            onClick={() => handleTallaClick(talla.ID, talla.NOMBRE)}
            style={{
              cursor: "pointer",
              backgroundColor:
                selectedTalla === talla.ID
                  ? "black"
                  : "rgba(255, 255, 255, 0.3)",
              height: "40px",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: "1px solid black",
              borderRadius: "0px",
            }}
          >
            <CardContent>
              <Typography
                className="LibreBodoni mt-2"
                style={{
                  color: selectedTalla === talla.ID ? "white" : "black",
                }}
              >
                {talla.NOMBRE}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default TallasSelector;
