import React from "react";
import ImagenVideo from "../assets/ImagenVideo.webp";

function PoliticaPrivacidad() {
  return (
    <>
      <div className="espacio-header" />
      <div className="container-fluid" style={{ backgroundColor: "#181e1b" }}>
        <div className="row">
          <div
            className="col-12 col-sm-7 col-md-6
           d-flex    
           align-items-center
          justify-content-center"
          >
            <div>
              <div className="my-5 my-md-0 align-self-center">
                <h4
                  className="font-Oxygen text-white"
                  style={{ fontSize: "2rem", marginBottom: "0.5rem" }}
                >
                  Política de privacidad
                </h4>
                <h4
                  className="font-PlayfairDisplayItalic text-white"
                  style={{ fontSize: "1rem" }}
                >
                  Políticas Del Servicio Pag Web, Taller y Andares
                </h4>
              </div>
            </div>
          </div>
          <div className=" col-12 col-sm-5 col-md-6 p-0   d-flex  justify-content-end ">
            <img src={ImagenVideo} className="img-fluid" />
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center align-items-center flex-column font-Oxygen p-2">
        <div
          className="d-flex flex-column mt-2 p-2 font-Oxygen"
          style={{ backgroundColor: "#FFFFFF" }}
        >
          <div class="p-2 font-Oxygen">
            Al momento de la compra, el cliente acepta los términos, condiciones
            y políticas de entrega enumerados a continuación:
          </div>
          <div class="p-2 font-Oxygen">
            <p className="font-Oxygen" style={{ fontWeight: "bold" }}>
              ENTREGA GARANTIZADA
            </p>{" "}
            Michelle Pourroy Estudio Floral Creativo, cuya política es la
            satisfacción total de nuestros clientes,garantiza la entrega de su{" "}
            <br />
            pedido en un máximo de 24 horas a partir de la confirmación de su
            pago o bien el la fecha fijada por el cliente, una vez pactado con{" "}
            <br />
            la empresa, para cualquiera de sus canales de venta , llámense
            Pagina Web, Taller y Punto de venta Andares.
          </div>
          <div class="p-2 font-Oxygen">
            <p className="font-Oxygen" style={{ fontWeight: "bold" }}>
              CONFIDENCIALIDAD
            </p>
            Michelle Pourroy Estudio Floral Creativo garantiza la información de
            nuestros clientes, la cual viaja de manera encriptada a nuestro{" "}
            <br />
            sitio y es manejada de tal forma que no puede ser descifrada por
            agentes externos. Leer apartado de privacidad <br /> ( pendiente
            aviso de privacidad, muy importante).
          </div>
          <div class="p-2 font-Oxygen">
            <p className="font-Oxygen" style={{ fontWeight: "bold" }}>
              DISPONIBILIDAD
            </p>
            Algunos tipos de flores no están disponibles todo el año por
            fenómenos naturales o por problemas exclusivos de nuestros <br />
            proveedores, es por ello que Michelle Pourroy Estudio Floral
            Creativo, sólo en estos casos elegirá el tipo de flor, el color y la{" "}
            <br />
            forma que más se parezca a la del arreglo que se haya solicitado,
            sin demeritar la calidad ni el diseño del mismo.
          </div>
          <div class="p-2 font-Oxygen">
            <p className="font-Oxygen" style={{ fontWeight: "bold" }}>
              ESPECIFICACIONES DE LOS ARREGLOS
            </p>
            Algunos tipos de flores no están disponibles todo el año por
            fenómenos naturales o por problemas exclusivos de nuestros <br />
            proveedores, es por ello que Michelle Pourroy Estudio Floral
            Creativo, sólo en estos casos elegirá el tipo de flor, el color y la{" "}
            <br />
            forma que más se parezca a la del arreglo que se haya solicitado,
            sin demeritar la calidad ni el diseño del mismo.
          </div>
          <div class="p-2 font-Oxygen">
            <p className="font-Oxygen" style={{ fontWeight: "bold" }}>
              TEMPORADAS FESTIVAS
            </p>
            Son consideradas fechas especiales: 12-14 de febrero 7-10 de mayo
            Estos días debido a la alta demanda el horario de entrega <br />{" "}
            será abierto y el pedido se entregará en el transcurso del día.
            <br />
            Solicitamos a nuestros clientes confirmación de pedidos por lo menos
            con 3 días de anticipación; esto para darle la mejor atención <br />{" "}
            y que su arreglo llegue en el día solicitado, en estos días no
            tenemos horarios específicos de entrega dado la afluencia de pedidos
            solicitados <br />, sería entregado en día solicitado.
            <br /> Michelle Pourroy Estudio Floral Creativo se compromete a
            elaborar composiciones florales autorizadas y expuestos exclusivos
            para <br /> esta fecha, se podrá hacer modificación a los modelos
            bajo la misma autorización de la casa floral.
          </div>
          <div class="p-2 font-Oxygen">
            <p className="font-Oxygen" style={{ fontWeight: "bold" }}>
              FORMAS DE PAGOS
            </p>
            – Depósito bancario: El cliente se ve obligado a realizar el
            depósito bancario por la cantidad acordada para que su pedido sea
            elaborado <br /> y enviado. De igual forma, el cliente deberá
            confirmar su depósito para iniciar con el procedimiento de envío.{" "}
            <br /> – Transferencia electrónica: Cuando la operación haya pasado
            el mismo día, el cliente sólo tiene que avisar vía whatsapp al +52
            33237579. <br /> – Tarjeta de crédito: Michelle Pourroy Estudio
            Floral Creativo asume como propias las condiciones de uso del
            servicio de <br /> PayPal y de Terminal Bancaria, que es quien
            ofrece el sistema para este tipo de cobro.
          </div>
          <div class="p-2 font-Oxygen">
            <p className="font-Oxygen" style={{ fontWeight: "bold" }}>
              POLÍTICAS DE ENTREGA
            </p>
            Nuestra cobertura de entrega es la Zona Metropolitana de Guadalajara
            Jalisco. El cliente se obliga a proporcionar la información
            necesaria <br /> del destinatario. Los cargos por servicio a
            domicilio incluyen sólo una entrega. Por lo que de presentarse
            alguna situación que nos impida <br /> entregar el arreglo y ésta no
            sea atribuible a Michelle Pourroy Estudio Floral Creativo, se tendrá
            que cubrir otro envío a domicilio o en su <br /> defecto que el
            cliente o la persona que reciba el arreglo pase a recogerlo a
            nuestro taller de diseño. A fin de garantizar el mejor servicio{" "}
            <br />
            pedimos al cliente nos proporcione al menos un rango de horario de
            entrega, máximo dos horas de rango. <br /> Esto bajo el entendido de
            que es responsabilidad del cliente verificar que el destinatario se
            encuentre en esos horarios. <br /> De no encontrarse la persona a
            quién se le destina el arreglo en el domicilio el mensajero seguirá
            el siguiente procedimiento: <br /> A.- Se dejará el arreglo con
            quien se encuentre en ese momento en el domicilio, firmando este de
            responsable, el mensajero tomará nota <br /> de quien lo recibió, la
            hora y el parentesco con el destinatario, en caso de ser el lugar de
            trabajo se tomará el nombre del recepcionista o guardia. <br /> B.-
            En caso de no haber nadie en el domicilio, el mensajero esperará 10
            minutos para la entrega del arreglo , se intentará localizar al
            cliente para <br /> contactar al destinatario, de lo contrario se
            retornará el producto a las instalaciones de Michelle Pourroy
            Estudio Floral Creativo.
          </div>
          <div class="p-2 font-Oxygen">
            <p className="font-Oxygen" style={{ fontWeight: "bold" }}>
              LÍMITE Y RESPONSABILIDAD
            </p>
            Michelle Pourroy Estudio Floral Creativo no se hace responsable por
            compras hechas con tarjetas de crédito sin autorización previa del
            dueño <br /> o robadas; se entiende que la persona que realiza la
            compra está plenamente facultada para realizar la transacción y por
            tanto <br /> Michelle Pourroy Estudio Floral Creativo no puede
            negarla, el realizar transacciones por Internet sin el
            consentimiento previo del dueño o con <br /> tarjetas robadas se
            constituye un fraude el cual está penado por la legislación mexicana
            hasta con 10 años de cárcel. <br />
            Michelle Pourroy Estudio Floral Creativo no acepta por ningún motivo
            solicitudes de pago no reconocido por desacuerdos con el servicio o{" "}
            <br />
            desconocimiento de la forma de cobro <br /> Michelle Pourroy Estudio
            Floral Creativo envía a través de un correo electrónico su recibo de
            pago en el cual se especifica que en su <br /> estado de cuenta le
            aparecerá la compra realizada. <br /> El hecho de establecer una
            reclamación con el banco, desconociendo con esto su compra hecha de
            manera consciente y responsable, implicará <br /> una SOSPECHA DE
            FRAUDE POTENCIAL por lo que con la información del pedido, firma de
            recepción del arreglo por parte del destinatario y <br /> las
            grabaciones telefónicas se tendrán suficientes pruebas para
            comprobar que se realizó un fraude con el banco y este puede
            cancelar su crédito <br /> inmediatamente. Será entonces de vital
            importancia se ponga siempre en contacto con nosotros para aclarar
            cualquier punto.
          </div>
          <div class="p-2 font-Oxygen">
            <p className="font-Oxygen" style={{ fontWeight: "bold" }}>
              POLÍTICA DE DEVOLUCIONES
            </p>
            Debido a que nuestros productos son perecederos (arreglos naturales
            ) no es posible hacer una devolución del producto. <br /> En el caso
            de los ramos artificiales o rehidratados solo es posible hacer
            devolución del producto cuando este vaya defectuoso de origen.{" "}
            <br /> Si es así el consumidor tendrá 3 días para hacer la
            devolución del producto y se corrija el defecto de manufactura.{" "}
            <br /> En el caso de los ramos preservados no hay devolución ya que
            se trata de un producto que previamente ya se envió constancia,
            mediante <br /> foto y video, que el producto va en perfecto estado.
          </div>
          <div class="p-2 font-Oxygen">
            <p className="font-Oxygen" style={{ fontWeight: "bold" }}>
              PROTECCIÓN AL CONSUMIDOR
            </p>
            Michelle Pourroy Estudio Floral Creativo se compromete a acatar lo
            dispuesto por la Ley de Protección al Consumidor.
          </div>
          <div class="p-2 font-Oxygen">
            <p className="font-Oxygen" style={{ fontWeight: "bold" }}>
              NO PREVISTOS
            </p>
            Cualquier situación no prevista en estas políticas, serán resueltas
            por el área administrativa de Michelle Pourroy Estudio Floral
            Creativo. <br /> En todo momento se verificará bajo la filosofía de
            máxima satisfacción con el cliente, el cumplimiento de nuestros
            acuerdos bajo el <br /> entendimiento que existirán agentes externos
            a nosotros que nos impedirán cumplir con nuestro propósito.
          </div>
          <div class="p-2 font-Oxygen">
            <p className="font-Oxygen" style={{ fontWeight: "bold" }}>
              POLÍTICA DE ENTREGA
            </p>
            Cobertura de entrega Zona Metropolitana de Guadalajara, Jalisco.{" "}
            <br />
            El cliente se obliga a proporcionar la información necesaria del
            destinatario. <br />
            El pedido deberà estar 100% liquidado antes de la entrega <br />
            Los cargos por servicio a domicilio incluyen sólo una entrega. Por
            lo que de presentarse alguna situación que nos impida entregar el{" "}
            <br />
            arreglo y ésta no sea atribuible a Michelle Pourroy Estudio Floral
            Creativo, se tendrá que cubrir otro envío a domicilio o en su
            defecto <br /> que el cliente o la persona que reciba el arreglo
            pase a recogerlo a nuestro taller de diseño. A fin de garantizar el
            mejor servicio pedimos <br /> al cliente nos proporcione al menos un
            rango de horario de entrega, máximo dos horas de rango, el cliente
            tendrá que verificar que <br /> el destinatario se encuentre el día
            programado. De no encontrarse la persona a quién se le destina el
            arreglo en el domicilio el mensajero <br /> seguirá el siguiente
            procedimiento: <br />
            A.- Se dejará el arreglo con quien se encuentre en ese momento en el
            domicilio, firmando este de responsable, el mensajero tomará <br />{" "}
            nota de quien lo recibió, la hora y el parentesco con el
            destinatario, en caso de ser el lugar de trabajo se tomará el nombre
            del <br /> recepcionista o guardia. <br /> B.- En caso de no haber
            nadie en el domicilio, el mensajero esperará 10 minutos para la
            entrega del arreglo , <br /> se intentará localizar al cliente para
            contactar al destinatario, de lo contrario se retornará el producto
            a las instalaciones de <br /> Michelle Pourroy Estudio Floral
            Creativo.
          </div>
          <div class="p-2 font-Oxygen">
            <p className="font-Oxygen" style={{ fontWeight: "bold" }}>
              POLÍTICAS PARA DÍAS ESPECIALES (14 DE FEBRERO Y 10 DE MAYO)
            </p>
            - El pedido tiene que ser liquidado por completo antes de ser
            enviado. <br />- Servicio a domicilio desde $120 dependiendo de la
            distancia. <br />
            - Las entregas que se realicen el día 14 de febrero o 10 de mayo, NO
            tendrán horario especifico. Únicamente el especificado <br /> en la
            página web (matutino o vespertino) <br />
            - Si la persona no se encuentra en el domicilio el arreglo será
            devuelto a alguna de nuestras sucursales y su reenvió tendrá <br />{" "}
            costo adicional. <br />
            - Se tomarán pedidos hasta agotar existencias. <br />
            - Color y tipo de flor sujeto a disponibilidad. <br />
            - Arreglos hechos con flores naturales, por lo que podrán tener
            algunas modificaciones. <br />
            - Cada arreglo tiene su propia composición. <br />
            -El color de las bases pueden variar y están sujetas a
            disponibilidad. <br />
          </div>
          <div class="p-2 font-Oxygen">
            <p
              className="font-Oxygen"
              style={{ fontWeight: "bold", textTransform: "uppercase" }}
            >
              Garantía
            </p>
            - Es uno de los pilares de nuestra compañía. Utilizamos flores de
            calidad, las elegimos cuidadosamente y <br /> armamos los arreglos
            florales con nuestro equipo de floristas profesionales. <br />
            - Sabemos que cuando envías flores estás buscando enviar lo mejor
            para sorprender en esa ocasión especial para ti . <br />- Trabajamos
            para que tu compra sea siempre ágil y satisfactoria. <br />
            - Mantenemos una constante labor para brindarte lo mejor. Si por
            algún motivo no estás 100% satisfecho comunícate con nuestro <br />{" "}
            Centro de Atención al Cliente <br />
            - +52 33 23437579 , vía telefónica, Whatsapp o bien envíar un correo
            electrónico a ventas_mpourroy@gmail.com y un asesor estará <br /> a
            tu disposición a la brevedad posible <br />
            - Por tratarse de productos perecederos favor de contactarse en un
            plazo máximo de 24 hrs de efectuada la entrega. <br />
          </div>
        </div>
      </div>
    </>
  );
}

export default PoliticaPrivacidad;
