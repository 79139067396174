import { useWindowSize } from "./WindowSize";

const Breakpoints = {
    xs: 576,
    sm: 576,
    md: 768
}

export const useSmallBreakpointBootstrap = ({
    bigger = false,
    smaller = false,
    inRange = false
}) => {
    const { width, height } = useWindowSize();
    if (bigger) {
        return width >= Breakpoints.sm
    }
    if (smaller) {
        return width <= Breakpoints.sm
    }
    if (inRange) {
        return width >= Breakpoints.sm && width <= Breakpoints.md
    }
}

export const useExtraSmallBreakpoint = ({
    bigger = false,
    inRange = false
}) => {
    const { width, height } = useWindowSize();
    if (bigger) {
        return width >= Breakpoints.xs
    }
    if (inRange) {
        return width >= 0 && width <= Breakpoints.sm
    }
}