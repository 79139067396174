import React, { useCallback, useEffect, useMemo, useState } from "react";
import { getLocalStorageJWT } from "../../Context/Storage";
import { Link } from "react-router-dom";
import { Box, Tab, Tabs, Typography, SvgIcon } from "@mui/material";
import { makeStyles } from "@material-ui/core";
import { useMediaQuery } from "@material-ui/core";

import { ReactComponent as DecoCreditos } from "../../assets/DecoCreditos.svg";
import { ReactComponent as Perfil } from "../../assets/Perfil.svg";
import { ReactComponent as Facturacion } from "../../assets/Facturacion.svg";
import { ReactComponent as MisPedidos } from "../../assets/MisPedidos.svg";
import { ReactComponent as Domicilios } from "../../assets/Domicilios.svg";
import { ReactComponent as Tarjetas } from "../../assets/Tarjetas.svg";
import DatosPerfil from "./DatosPerfil";
import DatosFaturacion from "./DatosFacturacion";
import MisDomicilios from "./MisDomicilios";
import DatosPedidos from "./MisPedidos/DatosPedidos"
import DatosCreditos from "./Creditos/DatosCreditos";
import MisTarjetas from "./Tarjetas/MisTarjetas";
const entorno = process.env.NODE_ENV;

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "row",
    borderRight: "none",
  },
  indicator: {
    left: "auto",
    right: 0,
    top: 0,
    bottom: 0,
    position: "absolute",
    backgroundColor: "#C9A396",
    width: "3px",
  },
});

export default function MiPerfil() {
  const [width, setWidth] = useState(window.innerWidth);
  const [isLoged, setIsLoged] = useState(false);
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`,
    };
  }
  const getInfoUsuario = async () => {
    if (getLocalStorageJWT() != "") {
      setIsLoged(true);
    }
  };
  const handleResize = useMemo(() => {
    setWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    getInfoUsuario().then(setTimeout(() => { }, 1500));
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [handleResize]);

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  const isSmallScreen = useMediaQuery("(max-width:768px)");

  const classes = useStyles();
  return !isLoged ? (
    <>
      <div className="espacio-header" />
      <div className="w-100 h-100 d-flex justify-content-center align-content-center">
        <div className="align-self-center">
          <Typography className="m-1 text-center mt-5 font-Oxygen">
            Aún no inicias sesión
          </Typography>

          <Link to="/login" style={{ textDecoration: "none", color: "black" }}>
            <center>
              <Typography className="m-1 mt-0 text-center font-OxygenBold text-rosa  animated-text">
                Inicar sesión
              </Typography>
            </center>
          </Link>
        </div>
      </div>
    </>
  ) : (
    <>
      <div className="espacio-header" />
      <div className="container-fluid h-100 d-flex px-0 w-100">
        <div
          className={"row align-content-start w-100" + (!isSmallScreen ? " h-100" : "")}
          style={{ width: isSmallScreen ? "100%" : "100%" }}
        >
          <div
            className={
              "col-12 col-md-3 col-lg-2 px-0 " + (isSmallScreen ? "" : "h-100")
            }
            style={{
              backgroundColor: "#F7F7F7",
              height: isSmallScreen ? "71px" : "unset",
            }}
          >
            <Tabs
              variant="scrollable"
              indicatorColor="#C9A396"
              value={value}
              onChange={handleChange}
              orientation={isSmallScreen ? "horizontal" : "vertical"}
              className={classes.root}
              classes={{
                indicator: classes.indicator,
              }}
            >
              <Tab
                icon={
                  <SvgIcon
                    component={Perfil}
                    viewBox="0 0 30 30"
                    fontSize="40px"
                    style={{ fontSize: "20px", marginRight: "20px" }}
                  />
                }
                iconPosition="start"
                className="font-OxygenBold text-dark ps-4 d-flex justify-content-start align-content-center align-items-center"
                label="Mi perfil"
                style={{ height: "20px", textTransform: "none" }}
                {...a11yProps(0)}
              />

              <Tab
                icon={
                  <SvgIcon
                    component={Facturacion}
                    viewBox="0 0 30 30"
                    fontSize="30px"
                    style={{ fontSize: "20px", marginRight: "20px" }}
                  />
                }
                iconPosition="start"
                className="font-OxygenBold text-dark ps-4 d-flex justify-content-start align-content-center align-items-center"
                label="Facturación"
                style={{ height: "20px", textTransform: "none" }}
                {...a11yProps(1)}
              />

              <Tab
                icon={
                  <SvgIcon
                    component={MisPedidos}
                    viewBox="0 0 30 30"
                    fontSize="30px"
                    style={{ fontSize: "20px", marginRight: "20px" }}
                  />
                }
                iconPosition="start"
                className="font-OxygenBold text-dark ps-4 d-flex justify-content-start align-content-center align-items-center"
                label="Mis Pedidos"
                style={{ height: "20px", textTransform: "none" }}
                {...a11yProps(2)}
              />

              <Tab
                icon={
                  <SvgIcon
                    component={Domicilios}
                    viewBox="0 0 30 30"
                    fontSize="30px"
                    style={{ fontSize: "20px", marginRight: "20px" }}
                  />
                }
                iconPosition="start"
                className="font-OxygenBold text-dark ps-4 d-flex justify-content-start align-content-center align-items-center"
                label="Domicilios"
                style={{ height: "20px", textTransform: "none" }}
                {...a11yProps(3)}
              />

              <Tab
                icon={
                  <SvgIcon
                    component={Tarjetas}
                    viewBox="0 0 35 35"
                    fontSize="30px"
                    style={{ fontSize: "20px", marginRight: "20px" }}
                  />
                }
                iconPosition="start"
                className="font-OxygenBold text-dark ps-4 d-flex justify-content-start align-content-center align-items-center"
                label="Tarjetas"
                style={{ height: "20px", textTransform: "none" }}
                {...a11yProps(4)}
              />

              <Tab
                icon={
                  <SvgIcon
                    component={DecoCreditos}
                    viewBox="0 0 35 35"
                    fontSize="30px"
                    style={{ fontSize: "20px", marginRight: "20px" }}
                  />
                }
                iconPosition="start"
                className="font-OxygenBold text-dark ps-4 d-flex justify-content-start align-content-center align-items-center"
                label="Flower Wallet"
                style={{ height: "20px", textTransform: "none" }}
                {...a11yProps(5)}
              />
            </Tabs>
          </div>
          <div className="col-12 col-md-9 col-lg-10 px-0">
            <TabPanel value={value} index={0} className="p-0 w-100">
              <DatosPerfil />
            </TabPanel>

            <TabPanel value={value} index={1} className="p-0 w-100">
              <DatosFaturacion />
            </TabPanel>
            <TabPanel value={value} index={2} className="p-0 w-100">
              <DatosPedidos />
            </TabPanel>
            <TabPanel value={value} index={3} className="p-0 w-100">
              <MisDomicilios />
            </TabPanel>

            <TabPanel value={value} index={4} className="p-0 w-100">
              <MisTarjetas />
            </TabPanel>
            <TabPanel value={value} index={5} className="p-0 w-100">
              {/*entorno === "production" ? (
                <div className="w-100 h-100 d-flex justify-content-center mt-5 pt-5">
                  <div>
                    <div>
                      <center></center>
                    </div>
                    <h4 className="font-OxygenBold mt-5 pt-5">
                      Muy pronto esta opción estara dísponible
                    </h4>
                  </div>
                </div>
              ) : (
                <DatosCreditos/>
              )*/}
              <DatosCreditos />


            </TabPanel>
          </div>
        </div>
      </div>
    </>
  );
}
