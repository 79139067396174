import { stringify } from "query-string";
import { getData, KEY } from "../../Context/backend";

export const getLista = async (query) => {
  let data = [];
  let queryParamsObj = {
    key: KEY,
    order: 5,
  };
  const url = `categorias/ListarCategorias?${stringify(queryParamsObj)}`;
  const res = await getData(url, false);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const getOpcionesCategorias = async (query = "") => {
  let data = [];
  let queryParamsObj = {
    query: query,
    IS_ECOMMERCE: 1,
  };
  const url = `categorias/getOpciones?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      //console.log(data);
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const getOpcionesCategoriasEcommerce = async (query = "") => {
  let data = [];
  let queryParamsObj = {
    query: query,
    IS_ECOMMERCE: 1,
    key: KEY
  };
  const url = `categorias/getOpcionesEcommerce?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      //console.log(data);
      resolve(data);
    } else {
      reject(res);
    }
  });
};
