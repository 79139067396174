import dayjs from "dayjs";
import { getData, postdData } from "../../Context/backend"; 
import { stringify } from "query-string";

 

export const getInfoMonedero = async () => {
  let data = [];
  const url = `perfil/ConsultarSaldoMonedero`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    
    if (!res.error) { 
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};
export const getInfoCompra = async (UUID) => {
  let data = [];
  const url = `ventas/getFechaVentaID?UUID=`+UUID;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    
    if (!res.error) { 
      data = JSON.parse(res.data.respuesta.data)[0];
      resolve(data);
    } else {
      reject(res);
    }
  });
};


export const pagarOrden = async (cartInfo,  cartItems, 
  getTotalPrice, 
  getItemCount,
  getTotalItems) => {
  
  let error = false; 
 
if(cartInfo.paymentMethod!="TARJETA" && cartInfo.paymentMethod!="NONEDERO")
{ 
  /*
  return new Promise((resolve, reject) => {
    resolve({ mensaje: "Lo sentimos, esta forma de pago aún no esta disponible" });
  });
  */

}

let Total = getTotalPrice();

let datosCompra = {
    id: "0",
    iD_CLIENTE: "0",
    fechA_ENTREGA: dayjs(cartInfo.deliveryDate).format("YYYY-MM-DD") ,
    observaciones: "",
    direccion: cartInfo.deliveryMethod=="DOMICILIO" 
    ? cartInfo.domicilio.domicilio : cartInfo.sucursal.domicilio ,
    tipO_ENVIO: cartInfo.deliveryMethod=="DOMICILIO" 
    ? 1 : 2,
    descuento: 0,
    tipO_DESCUENTO: 0,
    descuentO_MAX: 0,
    subtotal: getTotalPrice(),
    total: getTotalPrice()+cartInfo.precioEnvio.precioEnvio,
    pagado: true,
    iD_SUCURSAL: String(cartInfo.sucursal.id),
    iD_DIRECCION: String(cartInfo.domicilio.id),
    iD_ZONA:cartInfo.precioEnvio.idZona,
    codigo: "",
    ubicacion: "",
    productos: cartItems,
    iD_TARJETA:  cartInfo.shippingMethod.id,
    metodO_PAGO: cartInfo.paymentMethod,
    horariO_ENTREGA: {
      inicio: cartInfo.deliveryTime.inicio,
      fin: cartInfo.deliveryTime.fin
      },
    metodoS_PAGO: {
      tarjeta: cartInfo.paymentMethod == "TARJETA" ? Total : 0,
      transferencia:cartInfo.paymentMethod == "TRANSFERENCIA" ? Total : 0,
      efectivo: cartInfo.paymentMethod == "PAGOTIENDAS" ? Total : 0,
      monedero: cartInfo.paymentMethod == "MONEDERO" ? Total : 0
      },
    costO_ENVIO: cartInfo.precioEnvio.precioEnvio,
    envia:   cartInfo.dedicatoria.envia ,
    recibe:   cartInfo.dedicatoria.recibe ,
    dedicatoria:   cartInfo.dedicatoria.dedicatoria 
    } 
   
   
    // return;

  if (!error) 
  {
    try {
    let data = []; 
    const url = "ventas/InsertarDeCliente"; 
    const body = datosCompra;
    const res = await postdData(url, body); 
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
}
    catch{
      return new Promise((resolve, reject) => {
        resolve({ mensaje: "Ha ocurrrido un error" });
      });

    }

  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "Ha ocurrrido un error, verifica los dartos de tu compra" });
    });
  }
}; 
export const getPrecioZona = async (lat, lng) => { 
  let data = [];
  let queryParamsObj = { lat, lng };
  const url = `zonas/ConsultarPrecio?${stringify(queryParamsObj)}`; 
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) { 
      data = JSON.parse(res.data.respuesta); 
      resolve(data);
    } else {
      reject({
        mensaje: "Error al conectar a internet, revisa tu conexion a internet",
      });
    }
  });
};