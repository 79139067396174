import { Alert, MenuItem, Snackbar, TextField } from "@mui/material";

import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import Loading from "../../../Includes/Loading";

import withReactContent from "sweetalert2-react-content";
import { styled } from "@mui/material/styles";
import Swal from "sweetalert2";

import {
  getListaPedidos,
  guardarInfoComprobante,
} from "../../Funciones/Pedidos";

import AccBuscar from "./../../../assets/Buscar.svg";
import AccSkeleton2 from "./../../../assets/Skeleton2.webp";

import {
  ConvertDate,
  GetDate,
} from "../../../Lib/Funciones Generales/ObtenerFecha";
import { BasicDatePicker } from "../../../Includes/DatePicker";
import { ColorButton } from "../../../Lib/Componentes/Diseño";
import ModalCargarImagen from "../../../Lib/Imagen/Componentes/ModalCargarImagen";
import { IMG } from "../../../Lib/Funciones Generales/Constantes";
import Pedidos from "./Pedidos";
import ModalComprobanteLista from "./Dialogos/ModalComprobanteLista";
import ModalComprobanteCargar from "./Dialogos/ModalComprobanteCargar";
import ModalVerComprobante from "./Dialogos/ModalComprobante";
import ModalFacturacion from "./Dialogos/ModalFacturacion";
import ModalEnvioFactura from "./Dialogos/ModalEnvioFactura";

const MySwal = withReactContent(Swal);
const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
  ({ theme }) => ({
    "& .MuiSnackbar-root": {
      top: theme.spacing(15),
    },
  })
);

const EstatusPedidos = [
  { label: "Todos", value: -1 },
  { label: "Recibido", value: 1 },
  { label: "En proceso", value: 2 },
  { label: "Terminado", value: 3 },
  { label: "Cancelado", value: 0 },
];

const MisPedidos = () => {
  //#region VARIABLES STATE
  const initComprobante = {
    id: 0,
    id_venta: 0,
    img: "",
    nombre: "",
    comentario: "",
    actualizar_comprobante: true,
  };
  const [open, setOpen] = useState(false);

  const [pedidoSeleccionado, setPedidoSeleccionado] = useState(-1);
  const [comprobanteSeleccionado, setComprobanteSeleccionado] = useState(-1);
  const [showDialogComprobante, setShowDialogComprobante] = useState(false);
  const [showDialogVerComprobante, setShowDialogVerComprobante] =
    useState(false);
  const [showDialogCargarComprobante, setShowDialogCargarComprobante] =
    useState(false);
  const [showDialogFacturacion, setShowDialogFacturacion] = useState(false);
  const [idSeleccion, setIdSeleccion] = useState(0);

  const [IsLoading, setIsLoading] = useState(true);
  const [mensaje, setMensaje] = useState("");
  const [comprobante, setComprobante] = useState(initComprobante);

  const [showModalEnviarFactura, setShowModalEnviarFactura] = useState(false);
  const [idFactura, setIdFactura] = useState(0);

  const [Lista, setLista] = useState([]);
  const [filtro, setFiltro] = useState({
    estatus_pedido: -1,
    fecha_inicio: GetDate(-7),
    fecha_fin: GetDate(0),
  });
  //#endregion

  //#region HANDLES
  const handleClose = () => {
    setOpen(false);
  };

  const handlInputChange = ({ target }) => {
    if (target.name == "fecha_inicio" || target.name == "fecha_fin") {
      setFiltro({ ...filtro, [target.name]: ConvertDate(target.value) });
    } else {
      setFiltro({ ...filtro, [target.name]: target.value });
    }
  };
  //#endregion

  //#region FUNCIONES

  const GuardarComprobante = () => {
    setPedidoSeleccionado(-1);
    new Swal({
      title: "¿Estás seguro de guardar el comprobante?",
      text: "Esta acción no se puede deshacer",
      icon: "warning",
      showDenyButton: true,
      denyButtonText: "No, cancelar",
      confirmButtonText: "Si, estoy seguro",
      confirmButtonColor: "#3ABE88",
      denyButtonColor: "#65748B",
      reverseButtons: true,
    }).then(function (isConfirm) {
      console.log(isConfirm.isConfirmed);
      if (isConfirm.isConfirmed) {
        guardarInfoComprobante(comprobante)
          .then((data) => {
            if (data.codigo == "200") {
              MySwal.fire({
                title: "Correcto",
                html: data.respuesta.register,
                icon: "success",
                confirmButtoColor: "#3ABE88",
                showConfirmButton: false,
                timer: 1700,
              }).then((result) => {
                setComprobante({ ...initComprobante });
                // if (window.fnConsultarComprobantes) {
                //     window.fnConsultarComprobantes()
                // }
              });
            } else {
              setMensaje(data.mensaje);
              setOpen(true);
            }
          })
          .catch((data) => {
            setMensaje(data.mensaje);
            setOpen(true);
          });
      } else {
        setComprobante({ ...initComprobante });
      }
    });
  };

  const getPedidos = () => {
    getListaPedidos(
      filtro.estatus_pedido,
      filtro.fecha_inicio,
      filtro.fecha_fin
    ).then((resp) => {
      const list = JSON.parse(resp.data);
      setIsLoading(false);
      setLista(list);
    });
  };

  const BuscarPedidos = () => {
    setIsLoading(true);
    getPedidos();
  };

  //#endregion

  useEffect(() => {
    getPedidos();
  }, []);

  useEffect(() => {
    if (comprobante.img != "") {
      GuardarComprobante();
    }
  }, [comprobante]);

  return (
    <>
      <StyledSnackbar
        direction="right"
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {mensaje}
        </Alert>
      </StyledSnackbar>

      {showDialogComprobante && (
        <ModalComprobanteLista
          setShowDialogVerComprobante={setShowDialogVerComprobante}
          pedidoSeleccionado={pedidoSeleccionado}
          setShowDialogComprobante={setShowDialogComprobante}
          setComprobanteSeleccionado={setComprobanteSeleccionado}
          setShowDialogCargarComprobante={setShowDialogCargarComprobante}
        />
      )}

      {showDialogVerComprobante && comprobanteSeleccionado != -1 && (
        <ModalVerComprobante
          comprobanteSeleccionado={comprobanteSeleccionado}
          setShowDialogVerComprobante={setShowDialogVerComprobante}
        />
      )}

      {showDialogCargarComprobante && pedidoSeleccionado != -1 && (
        <ModalCargarImagen
          id={0}
          aceptarPDF={true}
          imgUrl={AccSkeleton2}
          tipo={IMG.TIPO_COMPROBANTE}
          Titulo="Cargar comprobante"
          Subtitulo=" el archivo"
          mostrar={true}
          borderRadius={20}
          fnCerrar={() => {
            setShowDialogCargarComprobante(false);
          }}
          fnDevolverImagen={(img) => {
            setComprobante({
              ...comprobante,
              id_venta: pedidoSeleccionado,
              img,
            });
          }}
        />
      )}

      <div>
        <Row className="p-3">
          <Col sm={12}>
            <Row>
              <Col sm={12} md={6} lg={3} className="pb-3">
                <TextField
                  fullWidth
                  select
                  label="Estatus"
                  name="estatus_pedido"
                  value={filtro.estatus_pedido}
                  error={false}
                  onChange={handlInputChange}
                >
                  {EstatusPedidos.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Col>
              <Col sm={12} md={6} lg={3} className="pb-3">
                <BasicDatePicker
                  fullWidth
                  format="DD/MM/YYYY"
                  label="Desde"
                  fecha={filtro.fecha_inicio}
                  setFecha={(fecha) => {
                    handlInputChange({
                      target: { name: "fecha_inicio", value: fecha },
                    });
                  }}
                />
              </Col>
              <Col sm={12} md={6} lg={3} className="pb-3">
                <BasicDatePicker
                  fullWidth
                  format="DD/MM/YYYY"
                  label="Hasta"
                  fecha={filtro.fecha_fin}
                  setFecha={(fecha) => {
                    handlInputChange({
                      target: { name: "fecha_fin", value: fecha },
                    });
                  }}
                />
              </Col>
              <Col sm={12} md={6} lg={3} className="pb-3">
                <ColorButton
                  size="small"
                  className="shadow-none font-OxigenBold"
                  variant="contained"
                  onClick={BuscarPedidos}
                  style={{ width: "100%" }}
                >
                  <img
                    src={AccBuscar}
                    className="me-2 mb-1"
                    style={{ width: "15px" }}
                  />{" "}
                  Buscar
                </ColorButton>
              </Col>
            </Row>
          </Col>

          {IsLoading ? (
            <Col sm={12} className="pt-5">
              <Loading />
            </Col>
          ) : (
            <div>
              <Row className="p-2">
                {Lista.map((row, index) => {
                  //console.log(row);

                  return (
                    <Pedidos
                      row={row}
                      index={index}
                      Lista={Lista}
                      setLista={setLista}
                      setPedidoSeleccionado={setPedidoSeleccionado}
                      setShowDialogComprobante={setShowDialogComprobante}
                      setShowDialogCargarComprobante={
                        setShowDialogCargarComprobante
                      }
                      setComprobanteSeleccionado={setComprobanteSeleccionado}
                      showDialogFacturacion={showDialogFacturacion}
                      setShowDialogFacturacion={setShowDialogFacturacion}
                      setIdSeleccion={setIdSeleccion}
                      showModalEnviarFactura={showModalEnviarFactura}
                      setShowModalEnviarFactura={setShowModalEnviarFactura}
                      setIdFactura={setIdFactura}
                    />
                  );
                })}
              </Row>
            </div>
          )}
          {showDialogFacturacion && (
            <ModalFacturacion
              pedidoSeleccionado={idSeleccion}
              setShowDialogFacturacion={setShowDialogFacturacion}
              getListaPedidos={getPedidos}
            />
          )}
          {showModalEnviarFactura && (
            <ModalEnvioFactura
              facturaSeleccionada={idFactura}
              correoEnvio={""}
              setCorreoEnvio={() => {}}
              setFacturaSeleccionada={() => {}}
              setShowModalEnviarFactura={setShowModalEnviarFactura}
            />
          )}
        </Row>
      </div>
    </>
  );
};

export default MisPedidos;
