import { formatMoneda } from "../../Lib/Funciones Generales/Generales";

const Descuentos = ({ descuentos, precio }) => {
  if(descuentos == undefined) return <></>;
  return (
        <h6>
          {JSON.parse(descuentos).TIPO_DESCUENTO === 0 ? (
            formatMoneda(parseFloat(precio))
          ) : (
            <>
              <span >
                {formatMoneda(
                  parseFloat(JSON.parse(descuentos).PRECIO_DESCUENTO)
                )}
                <span
                  className="p-2"
                  style={{
                    color: "#BFB7B9",
                    textDecoration: "line-through",
                  }}
                >
                  {formatMoneda(parseFloat(precio))}
                </span>
              </span>
              <div
                className="mt-2"
                style={{
                  color: "#66BEA0",
                }}
              >
                {JSON.parse(descuentos).TIPO_DESCUENTO === 1 ? (
                  <>{JSON.parse(descuentos).DESCUENTO} % de descuento</>
                ) : (
                  <>
                    {formatMoneda(parseFloat(JSON.parse(descuentos).DESCUENTO))}{" "}
                    pesos de descuento
                  </>
                )}
              </div>
            </>
          )}
        </h6>
  );
};
export default Descuentos;
