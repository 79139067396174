import React, { useEffect } from "react";
import GoogleLogin from "react-google-login";
import { gapi } from "gapi-script";

export default function GoogleButton({ handleRsCredencials = () => {} }) {
  const clienteID =
    "891072618530-17socbq1oarvr5dri5tbq2f0d9trss1s.apps.googleusercontent.com";
  function handleLoginSuccess(response) {
    //console.log("Inicio de sesión exitoso:", response);

    const datos = {
      nombre: response.profileObj.name,
      correo: response.profileObj.email,
      img: response.profileObj.imageUrl,
      idRS: response.googleId,
      tipo: "Google",
    };
    handleRsCredencials(datos);
  }

  function handleLoginFailure(error) {
    console.log("Error en el inicio de sesión:", error);
  }
  useEffect(() => {
    const initClient = () => {
      gapi.auth2.init({
        clientId: clienteID,
        scope: "",
      });
    };

    gapi.load("client:auth2", initClient);
  });
  return (
    <>
      <GoogleLogin
        clientId={clienteID}
        buttonText="Continuar con Google"
        onSuccess={handleLoginSuccess}
        onFailure={handleLoginFailure}
        cookiePolicy={"single_host_origin"}
        className="btn w-100  google-login-button font-Oxygen"
      />
    </>
  );
}
