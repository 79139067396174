import {
  Alert,
  Backdrop,
  CircularProgress,
  Snackbar,
  Typography,
} from "@mui/material";

import React, { useState, useEffect, useRef } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import withReactContent from "sweetalert2-react-content";
import BACKEND_URL from "../../Context/backend";
import Swal from "sweetalert2";
import loogo from "./../../assets/LogoNegrocreativoMichelle.svg";
import { Link } from "react-router-dom";
const MySwal = withReactContent(Swal);



export default function ChangePasswordForm() {
  const [Values, setValues] = useState({
    Contraseña: "",
    RepiteContraseña: "",
  });
  const [openLoading, setOpenLoading] = useState(false);
  const [mostrarFormulario, setMostrarFormulario] = useState(false);
  const [RepiteContraseñaError, setRepiteContraseñaError] = useState(false);
  const [ContraseñaError, setContraseñaError] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [open, setOpen] = useState(false);

  const refIniciar = useRef(null)

  const handlInputChange = ({ target }) => {
    setValues({
      ...Values,
      [target.name]: target.value,
    });

    if (target.name == "Contraseña") {
      if (!target.value.trim() || target.value.trim() == "") {
        setContraseñaError(true);
      }
      else {
        setContraseñaError(false);
      }
    }
    else {
      if (!target.value.trim() || target.value.trim() == "") {
        setRepiteContraseñaError(true);
      } else {
        if (Values.Contraseña == Values.RepiteContraseña) {
          setRepiteContraseñaError(false);
        }
      }
    }
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      cliccambiarpass();
    }
  };

  const handleClose = () => {
    setOpen(false);
  };
  const cliccambiarpass = () => {
    let error = false;
    if (!Values.Contraseña || Values.Contraseña == "") {
      setContraseñaError(true);
      error = true;
      setMensaje("El campo de contraseña es requerido");
      setOpen(true);
    } else {
      if (!Values.RepiteContraseña || Values.RepiteContraseña == "") {
        setRepiteContraseñaError(true);
        error = true;
        setMensaje("El campo de repetir contraseña es requerido");
        setOpen(true);
      } else {
        if (Values.Contraseña != Values.RepiteContraseña) {
          setRepiteContraseñaError(true);
          error = true;
          setMensaje("Las contraseñas debe que coincidir");
          setOpen(true);
        }
      }
    }
    if (!error) {
      setOpenLoading(true);
      const info = {
        nuevA_CONTRASENA: Values.Contraseña,
      };
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(info),
      };
      fetch(BACKEND_URL + "acceso/cambiarcontrasena?token=" + token, requestOptions)
        .then((response) => response.json())
        .then((response) => {
          setOpenLoading(false);
          if (response.resp.codigo == "200") {
            MySwal.fire({
              title: "Se actualizado tu contraseña,ya puedes entrar al sistema",
              icon: "success",
              confirmButtoColor: "#65748B",
              showConfirmButton: true,
            }).then((result) => {
              console.log("I was closed by the timer 2");
              refIniciar.current.click();
              window.location.href = "/";
            });
          } else {
            setMensaje(response.resp.mensaje);
            setOpen(true);
          }
        })
        .catch((error) => {
          setOpenLoading(false);
          setMensaje(
            "Ha ocurrido un error al conectar con nuestros servidores, intenta mas tarde."
          );
          setOpen(true);
          return;
        });
    }
  };

  const url = window.location.href;
  const token = url.substring(url.lastIndexOf('/') + 1);

  useEffect(() => {
    isValidToken(token).then((isValid) => {
      if (isValid) {
        setMostrarFormulario(true);
      } else {
        setMostrarFormulario(false);
      }
    });
  }, [token]);

  function isValidToken(token) {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "text/html" },
      body: "",
    };
    return fetch(BACKEND_URL + "acceso/comprobartoken?token=" + token, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        setOpenLoading(false);
        if (response.resp.codigo == "200") {
          return true;
        } else {
          return false;
        }
      })
      .catch((error) => {
        return false;
      });
  }

  React.useEffect(() => {
    document.title = `Cambiar contraseña`;
  }, []);

  return (
    <>
      <Snackbar
        direction="left"
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {mensaje}
        </Alert>
      </Snackbar>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openLoading}
      >
        <CircularProgress color="primary" />
      </Backdrop>
      <div className="mt-5"></div>
      {mostrarFormulario && (
        <Card className="shadow-lg p-4 m-auto mt-5" sx={{ maxWidth: 350 }}>
          <center>
            <img
              src={loogo}
              className="my-4"
              width={150}
              style={{ margin: "auto" }}
            />
          </center>
          <CardContent>
            <TextField
              error={ContraseñaError}
              fullWidth
              id="Contraseña"
              name="Contraseña"
              label="Contraseña"
              variant="outlined"
              type="password"
              value={Values.Contraseña}
              onChange={handlInputChange}
            />
            <TextField
              fullWidth
              error={RepiteContraseñaError}
              id="RepiteContraseña"
              name="RepiteContraseña"
              label="Repite contraseña"
              variant="outlined"
              className="mt-3"
              type="password"
              value={Values.RepiteContraseña}
              onChange={handlInputChange}
              onKeyDown={handleKeyDown}
            />
            <Button
              fullWidth
              variant="contained"
              size="large"
              className="btn-Crexendo mt-3"
              onClick={cliccambiarpass}
            >
              <Typography
                style={{
                  fontFamily: "Avenir",
                  textTransform: "none",
                }}
              >
                Cambiar contraseña
              </Typography>
            </Button>
            <Button fullWidth color="primary" className="mt-4" ref={refIniciar} >
              <Link style={{
                fontFamily: "Avenir",
                textTransform: "none",
                color: "#65748B",
                fontWeight: 650,
                textDecoration: "none",

              }} to="/">Iniciar sesión</Link>
            </Button>
          </CardContent>
        </Card>
      )}
    </>
  );
}
