import { Button, Divider, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { CartContext } from "../../Context/CartContext";
import { useContext } from "react";
import { formatMoneda } from "../../Lib/Funciones Generales/Generales";
 
import Transferencia from "../../assets/Transferencia.svg";
import Tiendas from "../../assets/Tiendas.svg";
import FlowerCredits from "../../assets/FlowerCredits.svg";
import Tarjetas from "../../assets/Tarjetas.svg";
import ModalTarjeta from "../../MiPerfil/Componentes/Tarjetas/ModalTarjeta";
import { useCartInfo } from "../../Context/CartInfoContext";
import DoneIcon from "@mui/icons-material/Done";

import Direccion from "./Direccion";
import Sucursal from "./Sucursales";
import { Link } from "react-router-dom";
import { getLista } from "../../MiPerfil/Funciones/Tarjetas";
import SelectTarjetas from "./FormasPago/Tarjetas";
import PagoEnOxxo from "./FormasPago/PagoOxxo";
import Monedero from "./FormasPago/Monedero";
import Deposito from "./FormasPago/Deposito";

const FormasPago = ({ envio = 0 }) => {
  const {
    cartItems,
    addToCart,
    removeFromCart,
    clearCart,
    deleteItem,
    getItemCount,
    getTotalItems,
    getTotalPrice,
  } = useContext(CartContext);

  const { cartInfo, setCartInfo } = useCartInfo();
  const [paymentMethod, setPaymentMethod] = useState(cartInfo.paymentMethod);
  const [IsLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [showModalTarjeta, SetshowModalTarjeta] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [Lista, setLista] = useState([]);
  const [tarjeta, setTarjeta] = useState(cartInfo.shippingMethod);


  const handlePlaceOrder = () => {
    cartInfo.paymentMethod = paymentMethod;
    cartInfo.shippingMethod = tarjeta;
    setCartInfo({ ...cartInfo });
  };

  const getTarjetas = () => {
    setIsLoading(true);
    getLista("")
      .then((resp) => {
        let list = JSON.parse(resp.data);
        console.log(list);
        setLista(list);
        setIsLoading(false);
      })
      .catch((resp) => {
        setLista([]);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getTarjetas();
  }, []);
  useEffect(() => {
    handlePlaceOrder();
  }, [tarjeta, paymentMethod]);





 

  return (
    <>
      {showModalTarjeta ? (
        <ModalTarjeta
          SetshowModalTarjeta={SetshowModalTarjeta}
          getTarjetas={getTarjetas}
        />
      ) : (
        ""
      )}

      <div className="pt-3 pb-5">
        <Button
         
          onClick={(e) => setPaymentMethod("TARJETA")}
          className={
            "btn shadow-none btn-outline-black d-flex  mt-4 w-100 text-star  py-3 px-4 " +
            (paymentMethod == "TARJETA" ? " btn-michelle " : "")
          }
          variant="contained"
        >
          <div className="d-flex justify-content-star w-100">
            <img src={Tarjetas} className="img-fluid me-3" />
            <span>Pagar con tarjeta</span>
            <div className="flex-grow-1 text-end">
              {paymentMethod == "TARJETA" && <DoneIcon className="" />}
            </div>
          </div>
        </Button> 
        {paymentMethod == "TARJETA" && (
          <SelectTarjetas
            paymentMethod={paymentMethod}
            tarjeta={tarjeta}
            setTarjeta={setTarjeta}
          />
        )}

<Button
          onClick={(e) => setPaymentMethod("TRANSFERENCIA")}
          className={
            "btn shadow-none btn-outline-black d-flex  mt-3 w-100 text-star  py-3 px-4 " +
            (paymentMethod == "TRANSFERENCIA" ? " btn-michelle " : "")
          }
          variant="contained"
        >
          <div className="d-flex justify-content-star w-100">
            <img src={Transferencia} className="img-fluid me-3" />
            <span>Transferencia electrónica</span>
            <div className="flex-grow-1 text-end">
              {paymentMethod == "TRANSFERENCIA" && <DoneIcon className="" />}
            </div>
          </div>
        </Button> 
        {paymentMethod == "TRANSFERENCIA" && (
         <>  <Deposito /></>
        )}



{
  /*


    <Button
          onClick={(e) => setPaymentMethod("PAGOTIENDAS")}
          className={
            "btn shadow-none btn-outline-black d-flex  mt-3 w-100 font-OxygenMedium text-star  py-3 px-4 " +
            (paymentMethod == "PAGOTIENDAS" ? " btn-michelle " : "")
          }
          variant="contained"
        >
          <div className="d-flex justify-content-star w-100">
            <img src={Tiendas} className="img-fluid me-3" />
            <span>Pago en tiendas</span>
            <div className="flex-grow-1 text-end">
              {paymentMethod == "PAGOTIENDAS" && <DoneIcon className="" />}
            </div>
          </div>
        </Button> 
        {paymentMethod == "PAGOTIENDAS" && (
         <>
         <PagoEnOxxo />
         </>
        )} 




  */
}
   

        <Button 
          onClick={(e) => setPaymentMethod("MONEDERO")}
          className={
            "btn  shadow-none btn-outline-black d-flex  mt-3 w-100 text-star  py-3 px-4 " +
            (paymentMethod == "MONEDERO" ? " btn-michelle " : "")
          }
          variant="contained"
        >
          <div className="d-flex justify-content-star w-100">
            <img src={FlowerCredits} className="img-fluid me-3" />
            <span>Monedero electrónico</span>
            <div className="flex-grow-1 text-end">
              {paymentMethod == "MONEDERO" && <DoneIcon className="" />}
            </div>
          </div>
        </Button> 
        {paymentMethod == "MONEDERO" && (
         <>  <Monedero /></>
        )} 
      </div>

      <center>
        <Link
          className="w-100 text-center"
          to={"/orden/datosenvio"}
          style={{
            textTransform: "none",
            textDecoration: "none",
          }}
        >
          <Typography className="m-1 mt-3 mb-5 text-center font-OxygenBold animated-text">
            Regresar a datos de envío
          </Typography>
        </Link>
      </center>
    </>
  );
};

export default FormasPago;
