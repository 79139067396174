import React, { useContext, useEffect, useState } from "react";
import Favoritos from "../../assets/Favoritos.svg";
import FavoritoON from "../../assets/FavoritosON.svg";
import FavoritoOFF from "../../assets/FavoritosOFF.svg";
import { Link, useParams } from "react-router-dom";
import { getProducto } from "../Funciones/Productos";
import { guardarFavorito } from "../../Lib/Favoritos/Funciones/Favoritos";
import { Button, Rating, Typography, Snackbar, Alert } from "@mui/material";
import Loading from "../../Includes/Loading";
import { styled } from "@mui/material/styles";
import DescripcionReserñas from "./DescripcionReserñas";
import GaleriaImagenes from "./GaleriaImagenes";
import AccEliminar from "./../../assets/AccEliminar.svg";
import AddIcon from "@mui/icons-material/Add";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import { CartContext } from "../../Context/CartContext";
import { ButtonGroup } from "react-bootstrap";
import CheckIcon from "@mui/icons-material/Check";
import CarruselRelacionados from "./CarruselRelacionados";
import { getLocalStorageJWT } from "../../Context/Storage";
import Descuentos from "./Descuentos";
import ModalReseñas from "./Dialogos/ModalReseñas";
import ModalReseñasEditar from "./Dialogos/ModalReseñasEditar";
import SelectColor from "./SelectColor";
import TallasSelector from "./TallaSelector";
import BotonFavorito from "../../Lib/Favoritos/BotonFavorito";

const Producto = () => {
  const [showDialogReseña, setShowDialogReseña] = useState(false);
  const [showDialogReseñaEditar, setShowDialogReseñaEditar] = useState(false);
  const [comentarioEditar, setComentarioEditar] = useState(null);
  const [resenasModal, setReseñasModal] = useState([]);

  const [IsLoading, setIsLoading] = useState(true);
  const [producto, setProducto] = useState([]);
  const [colores, setColores] = useState([]);
  const [tallas, setTallas] = useState([]);
  const [imagenes, setImagenes] = useState([]);
  const [resenas, setReseñas] = useState([]);
  const [mensaje, setMensaje] = useState("");
  const [open, setOpen] = useState(false);

  const [estilos, setEstilos] = useState({
    idColor: 0,
    idTalla: 0,
    setColor: true,
    setTalla: true,
    colorValue: "",
    tallaValue: "",
  });

  const [totalResenas, setTotalResenas] = useState(0);
  const [estaEnCarrito, setEstaEnCarrito] = useState(false);
  const [fav, setFav] = useState(false);
  const [iconMsg, setIconMsg] = useState("");
  const [idCliente, setIdCliente] = useState(0);

  const { id } = useParams();
  const {
    cartItems,
    addToCart,
    removeFromCart,
    clearCart,
    isInCart,
    getItemCount,
  } = useContext(CartContext);

  const handleClose = () => {
    setOpen(false);
  };

  const getInforProducto = () => {
    setIsLoading(true);
    getProducto(id == undefined ? 0 : id)
      .then((resp) => {
        let list = JSON.parse(resp.producto);
        let img = JSON.parse(resp.imagenes);
        let res = JSON.parse(resp.resenas);
        let colores = JSON.parse(resp.colores);
        let tallas = JSON.parse(resp.tamanos);
        let totalRes = resp.totalResenas;
        if (list.length > 0) {
          setProducto(list[0]);
          setFav(list[0].FAVORITO);
          setImagenes(img);
          setReseñas(res);
          setTotalResenas(totalRes);
          setIdCliente(resp.cliente);
          setColores(colores);
          setTallas(tallas);
        }

        if (tallas.length === 0 || colores.length === 0) {
          setEstilos({
            ...estilos,
            setTalla: tallas.length === 0 ? false : true,
            setColor: colores.length === 0 ? false : true,
          });
        }
        setIsLoading(false);
      })
      .catch((resp) => {
        setProducto([]);
        setColores([]);
        setTallas([]);
        setIsLoading(false);
      });
  };

  const GuardarFavorito = (idProducto) => {
    if (getLocalStorageJWT() === "") {
      setIconMsg("error");
      setMensaje(
        "Debes de iniciar sesión para poder guardalo productos en favoritos"
      );
      setOpen(true);
    } else {
      guardarFavorito(idProducto, fav)
        .then((data) => {
          if (data.codigo == "200") {
            if (data.respuesta === "0") {
              setFav(true);
            } else {
              setFav(false);
            }
            setIconMsg("success");
            setMensaje(data.mensaje);
            setOpen(true);
          } else {
            setIconMsg("error");
            setMensaje(data.mensaje);
            setOpen(true);
          }
        })
        .catch((data) => {
          setIconMsg("error");
          setMensaje(data.mensaje);
          setOpen(true);
        });
    }
  };

  const ID_CATEGORIA = producto.ID_CATEGORIA;

  useEffect(() => {
    getInforProducto();
    if (isInCart({ id: id })) {
      setEstaEnCarrito(true);
    }
  }, []);

  useEffect(() => {
    checkSiEnCarrito();
  });

  const checkSiEnCarrito = () => {
    const itemInCart = cartItems.find(
      (cartItem) =>
        cartItem.id === id &&
        cartItem.id_color === estilos.idColor &&
        cartItem.id_talla === estilos.idTalla
    );

    setEstaEnCarrito(!!itemInCart);
  };

  const handleSelectChange = (event) => {
    const selectedOption = colores.find(
      (option) => option.ID === event.target.value
    );

    setEstilos({
      ...estilos,
      idColor: event.target.value,
      colorValue: selectedOption.NOMBRE,
      setColor: false,
    });

    checkSiEnCarrito();
  };

  const handleTallaSelect = (tallaId, tallaNombre) => {
    setEstilos({
      ...estilos,
      idTalla: tallaId,
      tallaValue: tallaNombre,
      setTalla: false,
    });
    checkSiEnCarrito();
  };
  const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
    ({ theme }) => ({
      "& .MuiSnackbar-root": {
        top: theme.spacing(15),
      },
    })
  );

  if (IsLoading) {
    return (
      <>
        <div className="container pt-5"></div>
        <div className="container pt-5"></div>
        <div className="container h-100 pt-5">
          <Loading />
        </div>
        <div className="container pt-5"></div>
      </>
    );
  }

  const StyledRating = styled(Rating)({
    "& .MuiRating-iconFilled": {
      color: "#c9a396",
    },
    "& .MuiRating-iconHover": {
      color: "#ff3d47",
    },
  });

  if (producto.length == 0) {
    return (
      <>
        <div className="container pt-5"></div>
        <div className="container pt-5"></div>
        <h4 className=" text-center font-OxygenBold">
          No se encontro información del producto solicitado
        </h4>
        <center>
          <Link
            className="w-100 mt-5 "
            to={"/"}
            style={{
              textTransform: "none",
              textDecoration: "none",
            }}
          >
            <Typography className="m-1 mt-0 text-center font-OxygenBold text-rosa  animated-text">
              Ir a inicio
            </Typography>
          </Link>
        </center>

        <div className="container pt-5"></div>
        <div className="container pt-5"></div>
      </>
    );
  }
  return (
    <>
      <StyledSnackbar
        direction="right"
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity={iconMsg} sx={{ width: "100%" }}>
          {mensaje}
        </Alert>
      </StyledSnackbar>
      <div className="container pt-5"></div>
      <div className="container-lg ">
        <div className="row">
          <div className="col-12 col-md-6 pt-5rem">
            <GaleriaImagenes imagenes={imagenes} />
            {/* <DescripcionReserñas
              id_producto={id}
              descipcion={producto.DESC_LARGA.split("<br>").join("\n")}
              resenas={resenas}
              total_resenas={totalResenas}
              setReseñas={setReseñas}
              id_cliente={idCliente}
              setShowDialogReseña={setShowDialogReseña}
              setShowDialogReseñaEditar={setShowDialogReseñaEditar}
              setComentarioEditar={setComentarioEditar}
            /> */}

            <div className="container pt-5"></div>
          </div>

          <div className="col-12 col-md-6 p-4 pt-0  ">
            <div className="sticky-top pt-5rem">
              <h5 className="font-PlayfairDisplay fw-bold mb-2 mt-0 text-star">
                {producto.DESC_CORTA}
                {/* <img
                  src={fav ? FavoritoON : FavoritoOFF}
                  onClick={() => {
                    GuardarFavorito(producto.ID)
                  }}
                  width="25px"
                /> */}
              </h5>

              <h6 className="font-OxygenBold mb-4 mt-2 text-star">
                <Descuentos
                  descuentos={producto.DESCUENTOS}
                  precio={producto.PRECIO_PUBLICO}
                />
              </h6>

              {/* <h6
                style={{ fontSize: "12px" }}
                className="font-OxygenBold mt-2 text-star"
              >
                SKU: {producto.CODIGO}
              </h6> */}
              <div className="d-flex justify-content-star w-100 ">
                {/* <div className="me-3 aligin-align-self-center">
                  <h6
                    style={{ fontSize: "12px" }}
                    className="font-OxygenMedium m-0 mt-1 text-star"
                  >
                    Calificación: {parseFloat(producto.CALIFICACION).toFixed(1)}
                  </h6>
                </div>
                <div className="aligin-align-self-center">
                  <StyledRating
                    readOnly={true}
                    name="customized-color"
                    defaultValue={2}
                    precision={1}
                    size="small"
                    value={producto.CALIFICACION}
                  />
                </div> */}
              </div>

              {colores.length != 0 ? (
                <>
                  <div className="mt-4 text-star">
                    <SelectColor
                      options={colores}
                      onChange={handleSelectChange}
                    />
                  </div>
                </>
              ) : (
                <></>
              )}

              {tallas.length != 0 ? (
                <>
                  <div className="mt-3 text-star">
                    <div className="mt-3 text-star">
                      <TallasSelector
                        tallas={tallas}
                        onSelect={handleTallaSelect}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}

              <ButtonGroup
                className="buttonGroupCant mt-4"
                variant="outlined"
                aria-label="outlined button group"
              >
                <Button
                  className="btnAdd"
                  onClick={() => {
                    removeFromCart({
                      id: id,
                      cantidad: 1,
                      descripcion: producto.DESC_CORTA,
                      img: producto.IMG,
                      precio: producto.PRECIO_PUBLICO,
                      descuento: producto.DESCUENTOS,
                      dias_renta: 0,
                      id_color: estilos.idColor,
                      id_talla: estilos.idTalla,
                      color: estilos.colorValue,
                      talla: estilos.tallaValue,
                    });

                    checkSiEnCarrito();
                  }}
                >
                  {estaEnCarrito &&
                    getItemCount({
                      id: id,
                      id_color: estilos.idColor,
                      id_talla: estilos.idTalla,
                    }) == 1 ? (
                    <img src={AccEliminar} />
                  ) : (
                    <HorizontalRuleIcon style={{ width: "13px" }} />
                  )}
                </Button>
                <Button disabled className="btnCant">
                  {getItemCount({
                    id: id,
                    id_color: estilos.idColor,
                    id_talla: estilos.idTalla,
                  })}
                </Button>
                <Button
                  disabled={
                    colores.length === 0 || tallas.length === 0
                      ? false
                      : estilos.idColor === 0 || estilos.idTalla === 0
                        ? true
                        : false
                  }
                  onClick={() => {
                    addToCart({
                      id: id,
                      cantidad: 1,
                      descripcion: producto.DESC_CORTA,
                      img: producto.IMG,
                      precio: producto.PRECIO_PUBLICO,
                      descuento: producto.DESCUENTOS,
                      dias_renta: 0,
                      id_color: estilos.idColor,
                      id_talla: estilos.idTalla,
                      color: estilos.colorValue,
                      talla: estilos.tallaValue,
                    });

                    checkSiEnCarrito();
                  }}
                  className="btnDism"
                >
                  <AddIcon style={{ width: "13px" }} />
                </Button>
              </ButtonGroup>

              <div className="my-5 mt-4 ">
                {/* <Button
                                    className="w-100 btn-outline-black font-OxygenMedium"
                                    variant="contained"
                                    disableElevation
                                  >
                                    <img
                                      src={Calendario}
                                      style={{ width: "18px" }}
                                      className="me-1"
                                    />
                                    Selecciona la fecha de entrega
                                  </Button>
                  */}

                {colores.length != 0 || tallas.length != 0 ? (
                  <>
                    <Button
                      disabled={estilos.setColor || estilos.setTalla}
                      onClick={() => {
                        if (!estaEnCarrito) {
                          addToCart({
                            id: id,
                            cantidad: 1,
                            descripcion: producto.DESC_CORTA,
                            img: producto.IMG,
                            precio: producto.PRECIO_PUBLICO,
                            descuento: producto.DESCUENTOS,
                            dias_renta: 0,
                            id_color: estilos.idColor,
                            id_talla: estilos.idTalla,
                            color: estilos.colorValue,
                            talla: estilos.tallaValue,
                          });
                        }
                        checkSiEnCarrito();
                      }}
                      loadingPosition="start"
                      className="btn btn-michelle mt-3 w-100  py-2 px-4 "
                      variant="contained"
                      xs={{ with: "100%" }}
                    >
                      {/* {estaEnCarrito && (
                        <CheckIcon style={{ color: "white" }} />
                      )} */}
                      {estaEnCarrito ? (
                        <span className="ms-1" style={{ color: "#4caf50" }}>
                          En tu carrito:{" "}
                          {getItemCount({
                            id: id,
                            id_color: estilos.idColor,
                            id_talla: estilos.idTalla,
                          })}
                        </span>
                      ) : (
                        <span>
                          {colores.length !== 0 &&
                            tallas.length !== 0 &&
                            estilos.idColor === 0 &&
                            estilos.idTalla === 0 &&
                            "Selecciona el color y la talla"}
                          {colores.length !== 0 &&
                            tallas.length !== 0 &&
                            estilos.idColor === 0 &&
                            estilos.idTalla !== 0 &&
                            "Selecciona el color"}
                          {colores.length !== 0 &&
                            tallas.length !== 0 &&
                            estilos.idTalla === 0 &&
                            estilos.idColor !== 0 &&
                            "Selecciona tu talla"}
                          {colores.length !== 0 &&
                            tallas.length !== 0 &&
                            estilos.idColor !== 0 &&
                            estilos.idTalla !== 0 &&
                            "Agregar al carrito"}
                        </span>
                      )}
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      disabled={estaEnCarrito}
                      onClick={() => {
                        if (!estaEnCarrito) {
                          addToCart({
                            id: id,
                            cantidad: 1,
                            descripcion: producto.DESC_CORTA,
                            img: producto.IMG,
                            precio: producto.PRECIO_PUBLICO,
                            descuento: producto.DESCUENTOS,
                            dias_renta: 0,
                            id_color: estilos.idColor,
                            id_talla: estilos.idTalla,
                            color: estilos.colorValue,
                            talla: estilos.tallaValue,
                          });
                        }
                        checkSiEnCarrito();
                      }}
                      loadingPosition="start"
                      className="btn shadow-none btn-michelle mt-3 w-100  py-2 px-4"
                      variant="contained"
                      xs={{ with: "100%" }}
                    >
                      {/* {estaEnCarrito && (
                        <CheckIcon style={{ color: "white" }} />
                      )} */}
                      {estaEnCarrito ? (
                        <span className="ms-1" style={{ color: "#4caf50" }}>
                          En tu carrito
                        </span>
                      ) : (
                        <span>Agregar al carrito</span>
                      )}
                    </Button>
                  </>
                )}

                {estaEnCarrito && (
                  <Link
                    className="w-100 text-center"
                    to={"/orden"}
                    style={{
                      textTransform: "none",
                      textDecoration: "none",
                    }}
                  >
                    <Button
                      loadingPosition="start"
                      className="btn shadow-none btn-michelle mt-3 w-100  py-2 px-4 "
                      variant="contained"
                    >
                      <span>Pagar ahora</span>
                    </Button>
                  </Link>
                )}
                <Button
                  className="w-100 btn-outline-black mt-3"
                  variant="contained"
                  disableElevation
                  onClick={() => {
                    GuardarFavorito(producto.ID);
                  }}
                >
                  {" "}
                  {/* <img
                    src={Favoritos}
                    style={{ width: "15px" }}
                    className="me-1"
                  /> */}
                  {fav ? "Ya esta en tus favoritos" : "Añadir a tus favoritos"}
                </Button>
                {/*
                <Button
                  onClick={() => {
                    clearCart();
                  }}
                  className="w-100 btn-outline-black font-OxygenMedium mt-3"
                  variant="contained"
                  disableElevation
                >
                  Limpiar carrito
                </Button>


                  */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container pt-5"></div>
      <div className="w-100 my-5 py-4" />
      <h2
        className="font-PlayfairDisplay text-center mb-4"
        style={{ fontWeight: "bold" }}
      >
        Productos relacionados
      </h2>

      <div className="container p-3">
        <div className="row">
          <div className="col-12">
            <CarruselRelacionados id_categoria={ID_CATEGORIA} />
          </div>
        </div>
      </div>

      <div className="container pt-5"></div>
      <div className="container pt-5"></div>
      <div className="container pt-5"></div>
      <div className="container pt-5"></div>
      <div className="container pt-5"></div>
      <div className="container pt-5"></div>

      {showDialogReseña && (
        <ModalReseñas
          resenas={resenasModal}
          setReseñas={setReseñasModal}
          id_producto={id}
          id_cliente={idCliente}
          descipcion_producto={producto.DESC_LARGA.split("<br>").join("\n")}
          setShowDialogReseña={setShowDialogReseña}
          setShowDialogReseñaEditar={setShowDialogReseñaEditar}
          setComentarioEditar={setComentarioEditar}
          top={30}
        />
      )}

      {showDialogReseñaEditar && comentarioEditar && (
        <ModalReseñasEditar
          id_producto={id}
          setShowDialogReseñaEditar={setShowDialogReseñaEditar}
          comentarioEditar={comentarioEditar}
          setComentarioEditar={setComentarioEditar}
          setReseñas={setReseñas}
          resenas={resenas}
          resenasModal={resenasModal}
          setReseñasModal={setReseñasModal}
        />
      )}
    </>
  );
};
export default Producto;
