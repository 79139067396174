import { Typography } from "@mui/material"
import { Col, Row } from "react-bootstrap"

const DetallePedido = ({
    SUCURSAL,
    TIPO_ENVIO,
    FECHA_DE_ENTREGA,
    ENTREGA_INICIO,
    ENTREGA_FIN,
    D_NOMBRE_CONTACTO,
    D_TELEFONO,
    D_CALLE,
    D_NUMERO_EXT,
    D_NUMERO_INT,
    D_CP,
    D_COLONIA,
    D_CALLE1,
    D_CALLE2,
    D_INDICACIONES,
    FORMA_DE_PAGO,
    ENVIA,
    RECIBE,
    DEDICATORIA,
}) => {

    return (
        <>
            {
                (D_NOMBRE_CONTACTO && D_NOMBRE_CONTACTO.trim() != "") || (D_TELEFONO && D_TELEFONO != 0) && (
                    <>
                        <Typography
                            variant="p"
                            gutterBottom
                            component="div"
                            className="w-100 mt-3"
                        >
                            <strong> Contacto:</strong>
                        </Typography>
                        {
                            (D_NOMBRE_CONTACTO && D_NOMBRE_CONTACTO.trim() != "") && (
                                <Typography
                                    variant="p"
                                    gutterBottom
                                    component="div"
                                    className="w-100"
                                >
                                    {D_NOMBRE_CONTACTO}
                                </Typography>
                            )
                        }

                        {
                            (D_TELEFONO && D_TELEFONO != 0) && (
                                <Typography
                                    variant="p"
                                    gutterBottom
                                    component="div"
                                    className="w-100"
                                >
                                    {D_TELEFONO}
                                </Typography>
                            )
                        }
                    </>
                )
            }

            {
                TIPO_ENVIO == 1 ? (
                    <>
                        <Typography
                            variant="p"
                            gutterBottom
                            component="div"
                            className="w-100 font-OxygenBold"
                        >
                            Direccion de entrega
                        </Typography>
                        <Typography
                            variant="p"
                            gutterBottom
                            component="div"
                            className="w-100 font-Oxygen"
                        >
                            {D_CALLE +
                                " " +
                                (D_NUMERO_EXT != 0
                                    ? ", NO.EXT. " + D_NUMERO_EXT
                                    : "") +
                                " " +
                                (D_NUMERO_INT != 0
                                    ? ", NO.INT. " + D_NUMERO_INT
                                    : "") +
                                " " +
                                (D_COLONIA != "" ? ", COL. " + D_COLONIA : "") +
                                " " +
                                (D_CP != "" ? ", CP. " + D_CP : "")}
                        </Typography>
                    </>
                ) : (
                    <>
                        <Typography
                            variant="p"
                            gutterBottom
                            component="div"
                            className="w-100 font-OxygenBold"
                        >
                            Sucursal
                        </Typography>
                        <Typography
                            variant="p"
                            gutterBottom
                            component="div"
                            className="w-100 font-Oxygen"
                        >
                            {SUCURSAL}
                        </Typography>
                    </>

                )
            }

            <Typography
                variant="p"
                gutterBottom
                component="div"
                className="w-100 font-OxygenBold"
            >
                Entre calles
            </Typography>
            <Typography
                variant="p"
                gutterBottom
                component="div"
                className="w-100 font-Oxygen"
            >
                {D_CALLE1 +
                    " " +
                    (D_CALLE2 != 0 ? " y " + D_CALLE2 : "")}
            </Typography>
            <Typography
                variant="p"
                gutterBottom
                component="div"
                className="w-100 font-OxygenBold"
            >
                Fecha de entrega
            </Typography>
            <Typography
                variant="p"
                gutterBottom
                component="div"
                className="w-100 font-Oxygen"
            >
                {FECHA_DE_ENTREGA + ' ' + ENTREGA_INICIO + ' - ' + ENTREGA_FIN}
            </Typography>
            <Typography
                variant="p"
                gutterBottom
                component="div"
                className="w-100 font-OxygenBold"
            >
                Indicaciones
            </Typography>
            <Typography
                variant="p"
                gutterBottom
                component="div"
                className="w-100 font-Oxygen"
            >
                {D_INDICACIONES}
            </Typography>

            <Typography
                variant="p"
                gutterBottom
                component="div"
                className="w-100 font-OxygenBold"
            >
                Forma de pago
            </Typography>
            <Typography
                variant="p"
                gutterBottom
                component="div"
                className="w-100 font-Oxygen"
            >
                {FORMA_DE_PAGO}
            </Typography>
            <Row className="w-100">
                <Col sm={12} md={6}>
                    <Typography
                        variant="p"
                        gutterBottom
                        component="div"
                        className="w-100 font-OxygenBold"
                    >
                       Envía
                    </Typography>
                    <Typography
                        variant="p"
                        gutterBottom
                        component="div"
                        className="w-100 font-Oxygen"
                    >
                        {ENVIA}
                    </Typography>
                </Col>
                <Col sm={12} md={6}>
                    <Typography
                        variant="p"
                        gutterBottom
                        component="div"
                        className="w-100 font-OxygenBold"
                    >
                       Recibe
                    </Typography>
                    <Typography
                        variant="p"
                        gutterBottom
                        component="div"
                        className="w-100 font-Oxygen"
                    >
                        {RECIBE}
                    </Typography>
                </Col>
            </Row>
            <Typography
                variant="p"
                gutterBottom
                component="div"
                className="w-100 font-OxygenBold"
            >
                Dedicatoria
            </Typography>
            <Typography
                variant="p"
                gutterBottom
                component="div"
                className="w-100 font-Oxygen"
            >
                {DEDICATORIA}
            </Typography>
        </>
    )
}

export default DetallePedido