import {
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Snackbar,
  TextField,
  Card,
  InputAdornment,
  Checkbox,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { styled } from "@mui/material/styles";
import { LoadingButton } from "@mui/lab";
import { guardarDatosCreditos } from "../../Funciones/Perfiles"
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { getLista } from "../../Funciones/Tarjetas";
import Loading from "../../../Includes/Loading";
import ModalTarjeta from "../Tarjetas/ModalTarjeta";


const MySwal = withReactContent(Swal);

const ModalCreditos = ({ SetshowModalCreidtos, GetInfoCreditos }) => {

  const [showModalTarjeta, SetshowModalTarjeta] = useState(false);
  const [IsLoading, setIsLoading] = useState(true);
  const [ListaTarjetas, setListaTarjetas] = useState([]);

  const [IsGuardando, setIsGuardando] = useState(false);
  const [open, setOpen] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [selectedCard, setSelectedCard] = useState(null);
  const [Values, setValues] = useState({
    monto: "",
    tarjeta: "",
  });


  const [Errores, setErrores] = useState({
    monto: false
  });

  useEffect(() => {
    getTarjetas();
  }, []);

  const getTarjetas = () => {
    setIsLoading(true);
    getLista("")
      .then((resp) => {
        let list = JSON.parse(resp.data);
        setListaTarjetas(list);
        setIsLoading(false);
      })
      .catch((resp) => {
        setListaTarjetas([]);
        setIsLoading(false);
      });
  };

  const handleCardClick = (id) => {

    setSelectedCard(id === selectedCard ? null : id);
    if (id === 1) {
      setValues({
        ...Values,
        monto: 500,
      });
    }
    if (id === 2) {
      setValues({
        ...Values,
        monto: 1000,
      });
    }
  };

  const handlInputChange = ({ target }) => {
    setSelectedCard(null)
    const { name, value } = target;
    const regex = /^[0-9\b]+$/; // Expresión regular para aceptar solo números
    if (value === '' || regex.test(value)) {
      setValues({ ...Values, [name]: value });
    }

    setErrores({
      ...Errores,
      [name]: false,
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const GuardarDatosCreditos = () => {
    setIsGuardando(true);
    guardarDatosCreditos(Values, Errores, setErrores)
      .then((data) => {
        if (data.codigo == "200") {
          setIsGuardando(false);
          MySwal.fire({
            title: "Correcto !",
            html: data.mensaje,
            icon: "success",
            confirmButtoColor: "#3ABE88",
            showConfirmButton: false,
            timer: 1700,
          }).then((result) => {

            GetInfoCreditos();
            SetshowModalCreidtos(false);

          });
        } else {
          setMensaje(data.mensaje);

          setOpen(true);
          setIsGuardando(false);
        }
      })
      .catch((data) => {
        setMensaje(data.mensaje);
        setOpen(true);
        setIsGuardando(false);
      });
  };

  const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
    ({ theme }) => ({
      "& .MuiSnackbar-root": {
        top: theme.spacing(15),
      },
    })
  );

  return (
    <>
      <StyledSnackbar
        direction="right"
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {mensaje}
        </Alert>
      </StyledSnackbar>

      <Dialog fullWidth maxWidth="xs" open={true}>
        <IconButton
          aria-label="Close"
          className="m-3"
          style={{ position: "absolute", right: "0%" }}
          onClick={() => {
            SetshowModalCreidtos(false);
          }}
        >
          <CloseIcon size="lg" />
        </IconButton>
        <DialogTitle>
          <h6 className="fw-bolder f-12 mt-2">Agregar Flower Wallet</h6>
        </DialogTitle>
        <DialogContent>
          <Row>
            <Col sm={12} className="p-3">
              <Card
                variant="standard"
                onClick={() => handleCardClick(1)}
                sx={{
                  cursor: "pointer",
                  backgroundColor: selectedCard === 1 ? "#F7F7F7" : "white",
                  border: "1px solid black",
                  height: 40,
                }}
              >
                <center className="mt-2">$500</center>
              </Card>
            </Col>

            <Col sm={12} className="p-3">
              <Card
                variant="standard"
                onClick={() => handleCardClick(2)}
                sx={{
                  cursor: "pointer",
                  backgroundColor: selectedCard === 2 ? "#F7F7F7" : "white",
                  border: "1px solid black",
                  height: 40,
                }}
              >
                <center className="mt-2">$1,000</center>
              </Card>
            </Col>


            <Col sm={12} className="p-3">
              <TextField
                fullWidth
                name="monto"
                label="Otro monto"
                variant="outlined"
                value={Values.monto}
                error={Errores.monto}
                onChange={handlInputChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
            </Col>

            <Col sm={12} className="p-3">
              <h6 className="fw-bolder f-12">Método de pago</h6>
            </Col>
            <FormControl>

              {IsLoading ? (
                <Col sm={12} className="pt-5 pb-5">
                  <Loading />
                </Col>
              ) : (
                <RadioGroup
                  name="tarjeta"
                  onChange={handlInputChange}>

                  {
                    ListaTarjetas && ListaTarjetas.map(row => (
                      <Col sm={12} className="pb-3 px-3">
                        <Card
                        className="px-2"
                          variant="standard"
                          style={{ backgroundColor: "#F7F7F7" }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <FormControlLabel
                              control={<Radio />}
                              label={row.TIPO + " **** **** **** " + row.NUMERO}
                              sx={{
                                marginLeft: "-5px",
                                marginTop: "5px",
                                display: "flex",
                                alignItems: "center",
                              }}
                              value={row.TIPO + " **** **** **** " + row.NUMERO}
                            />
                            <p style={{ marginRight: 10 }}> {row.VENCIMIENTO} </p>
                          </div>
                        </Card>
                      </Col>
                    ))
                  }
                </RadioGroup>
              )}
            </FormControl>
            <Col sm={12} >
              <Button className="text-center text-dark w-100 p-3" onClick={() => {
                SetshowModalTarjeta(true)
              }}>
                Agregar nueva tarjeta
              </Button>

            </Col>
            {/* <Col sm={12} className="p-3">
              <FormControlLabel
                control={<Checkbox name="checked" color="primary" />}
                label="Tarjeta prederteminada"
              />
            </Col> */}
          </Row>
        </DialogContent>
        <DialogActions>
          <Col sm={12} className="p-3">
            <LoadingButton
              onClick={() => GuardarDatosCreditos()}
              loading={IsGuardando}
              loadingPosition="start"
              className="btn shadow-none w-100 btn-michelle  py-2 px-4 "
              variant="contained"
              xs={{ with: "100$" }}
            >
              <span
                className={IsGuardando ? "px-4 " : "px-2 "}
              >
                {IsGuardando ? "Procesando..." : "Pagar"}
              </span>
            </LoadingButton>
          </Col>
        </DialogActions>
      </Dialog>

      {showModalTarjeta && (
        <ModalTarjeta
          SetshowModalTarjeta={SetshowModalTarjeta}
          getTarjetas={getTarjetas}
        />
      )}
    </>
  );
};
export default ModalCreditos;
