import { Button, Divider, Typography } from "@mui/material";
import React, { useEffect, useState } from "react"; 
import {  Row } from "react-bootstrap"; 
import ModalTarjeta from "../../../MiPerfil/Componentes/Tarjetas/ModalTarjeta"; 
import DoneIcon from "@mui/icons-material/Done"; 
import { getLista } from "../../../MiPerfil/Funciones/Tarjetas";
import Loading from "../../../Includes/Loading";

const SelectTarjetas = ({ paymentMethod, tarjeta, setTarjeta}) => {
   
 
  const [IsLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [showModalTarjeta, SetshowModalTarjeta] = useState(false); 
  const [mensaje, setMensaje] = useState("");
  const [Lista, setLista] = useState([]);
 

   
  const getTarjetas = () => {
    setIsLoading(true);
    getLista("")
      .then((resp) => {
        let list = JSON.parse(resp.data);
        console.log(list);
        setLista(list);
        setIsLoading(false);
      })
      .catch((resp) => {
        setLista([]);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getTarjetas();
  }, []);
 
  return (
    <>
      {showModalTarjeta ? (
        <ModalTarjeta
          SetshowModalTarjeta={SetshowModalTarjeta}
          getTarjetas={getTarjetas}
        />
      ) : (
        ""
      )}

      <div className="pt-2"> 
        {paymentMethod == "TARJETA" ? (
          <h6 className=" text-star mb-1 mt-3 fw-bolder font-PlayfairDisplay">
            Selecciona una tarjeta
          </h6>
        ) : (
          ""
        )}

        <Row>

        {IsLoading ? (
        <div className="col-12 my-5">
          <Loading />
        </div>
      ) : 
      <> 
          <div className="col-12">
            {Lista.map((row) => (
              <>
                <div
                  onClick={() => {
                    setTarjeta({
                      id: row.ID_TARJETA_CONEKTA,
                      tarjeta: row.TIPO + "**** **** **** " + row.NUMERO,
                      nombre: row.NOMBRE_SUCURSAL,
                      tipo: "DOMICILIO",
                    }); 
                  }}
                  className="px-3 py-2  mt-3 direccion-hover"
                  style={{
                    backgroundColor:
                      tarjeta.id == row.ID_TARJETA_CONEKTA
                        ? "#ebe1d6"
                        : "#F7F7F7",
                    cursor: "pointer",
                  }}
                >
                  <div className="d-flex justify-content-star w-100">
                    <div className="row">
                      <div className="col-12">
                        <h6 className="font-OxygenBold">{row.NOMBRE} </h6>
                      </div>

                      <div className="col-12  mt-2 mt-md-0">
                        <small>
                          <p className="font-OxygenBold my-1">
                            {row.TIPO} **** **** **** {row.NUMERO}
                          </p>
                        </small>
                      </div>
                    </div>
                    <div className="flex-grow-1 text-end align-self-center">
                      {tarjeta.id == row.ID_TARJETA_CONEKTA && (
                        <DoneIcon className="" />
                      )}
                    </div>
                  </div>
                </div>
              </>
            ))}
          </div>
          <div className="col-12 mt-3 text-star">
            <Button
              onClick={() => SetshowModalTarjeta(true)}
              className="btn shadow-none btn-outline-black  py-2 px-4 "
              variant="contained"
              style={{ max: "400px" }}
            >
              <span className={"px-2"}>Agregar tarjeta</span>
            </Button>
          </div>
          </>
}
        </Row>
      </div>


      
    </>
  );
};

export default SelectTarjetas;
