import React, { useState, useEffect } from "react";
import { Typography } from "@mui/material";

import { Col, Row } from "react-bootstrap";
import Loading from "../../../Includes/Loading";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";

import {
  getColoresEstatus,
  getListaDetallePedidoCompleto,
} from "../../Funciones/Pedidos";
import { formatMoneda } from "../../../Lib/Funciones Generales/Generales";

import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import UploadIcon from "@mui/icons-material/Upload";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DetallePedido from "./DetallePedido";
import DetalleProductos from "./DetalleProductos";
import { ESTATUS_PEDIDOS } from "../../../Lib/Funciones Generales/EstatusPedidos";

const Pedidos = ({
  row,
  index,
  Lista,
  setLista,
  setShowDialogComprobante,
  setPedidoSeleccionado,
  setComprobanteSeleccionado,
  setShowDialogCargarComprobante,
  showDialogFacturacion = false,
  setShowDialogFacturacion = () => {},
  setIdSeleccion = () => {},
  showModalEnviarFactura,
  setShowModalEnviarFactura = () => {},
  setIdFactura = () => {},
}) => {
  const ActualizarPedido = (pedido, index, Lista, setLista) => {
    Lista[index] = pedido;
    setLista([...Lista]);
  };

  const ConsultarDetalle = (id, index, Lista, setLista) => {
    const pedido = Lista[index];
    if (pedido.CONSULTADO == 0) {
      getListaDetallePedidoCompleto(id).then((resp) => {
        const detalle = JSON.parse(resp.data);
        pedido.DETALLE = detalle.DETALLE[0];
        pedido.PRODUCTOS = detalle.PRODUCTOS;
        pedido.LOADING = 0;
        pedido.CONSULTADO = 1;
        ActualizarPedido(index, pedido, Lista, setLista);
      });
    }
    pedido.SHOW = pedido.SHOW == 0 ? 1 : 0;
    pedido.LOADING = pedido.CONSULTADO == 0 ? 1 : pedido.LOADING;
    ActualizarPedido(index, pedido, Lista, setLista);
  };

  const VerComprobantes = () => {
    setShowDialogComprobante(true);
    setPedidoSeleccionado(row.ID);
  };

  const VerUbicacion = (lat, lng) => {};

  const CargarComprobante = () => {
    setPedidoSeleccionado(row.ID);
    setShowDialogCargarComprobante(true);
    // setComprobanteSeleccionado(0)
  };

  const { colorSstatus, backgrorundStatus, backgrorundStatusStyle } =
    getColoresEstatus(row.ID_ESTATUS);

  return (
    <>
      <div className="d-flex align-items-center mt-5 mb-2">
        {<Typography className="font-Oxygen me-auto">{row.FECHA}</Typography>}
        {/* 
                    <Typography
                        style={{ cursor: 'pointer' }}
                        onClick={() => VerUbicacion(row.LAT, row.LNG)}
                        className="d-flex align-items-center mx-2">
                        <img
                            src={AccUbicacion}
                            className="me-2 mb-1"
                            style={{ width: "15px" }}
                        />
                        <Typography className="font-OxygenMedium">
                            Ubicación
                        </Typography>
                    </Typography> 
                    */}

        {(row.FORMA_DE_PAGO == "PAGOTIENDAS" ||
          row.FORMA_DE_PAGO == "TRANSFERENCIA") && (
          <div
            onClick={VerComprobantes}
            style={{ textDecoration: "none", color: "#212529" }}
          >
            <Typography
              style={{ cursor: "pointer" }}
              className="d-flex align-items-center mx-2"
            >
              <RemoveRedEyeIcon className="me-2" />
              <Typography className="font-Oxygen">Ver comprobantes</Typography>
            </Typography>
          </div>
        )}

        {row.ID_ESTATUS == 1 &&
          (row.FORMA_DE_PAGO == "PAGOTIENDAS" ||
            row.FORMA_DE_PAGO == "TRANSFERENCIA") && (
            <Typography
              style={{ cursor: "pointer" }}
              onClick={CargarComprobante}
              className="d-flex align-items-center mx-2"
            >
              {/* <img
                                src={AccSubir}
                                className="me-2 mb-1"
                                style={{ width: "15px" }}
                            /> */}
              <UploadIcon style={{ color: "#c9a396" }} className="me-2" />
              <Typography className="font-OxygenMedium text-rosa">
                Cargar comprobante
              </Typography>
            </Typography>
          )}
      </div>
      <div
        className="pb-0 px-3 px-md-4 pt-3 pb-3"
        style={{ backgroundColor: "#F7F7F7" }}
      >
        <Accordion
          onChange={() => {
            ConsultarDetalle(row.ID, index, Lista, setLista);
            //setIdSeleccion(row.ID);
          }}
          style={{
            border: "none",
            backgroundColor: "transparent",
            boxShadow: "none",
          }}
        >
          <AccordionSummary
            className="d-flex justify-content-between"
            expandIcon={row.SHOW == 0 ? <AddIcon /> : <RemoveIcon />}
            id={"pedido_" + row.ID}
          >
            <Typography className="font-OxygenBold d-flex align-items-center">
              No. Pedido {row.FOLIO}
              <div
                className="d-flex align-items-center justify-content-center  ms-3 me-2"
                style={{
                  backgroundColor: row.PAGADO == 1 ? "#E0FFD2" : "#FFF1D2",
                  width: "140px",
                  height: "35px",
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  borderRadius: "15px",
                }}
              >
                {row.PAGADO == 1 ? (
                  <p
                    style={{ fontSize: "0.8rem" }}
                    className="d-flex align-items-center"
                  >
                    <CheckCircleOutlineIcon color="green" className="me-2" />{" "}
                    PAGADO
                  </p>
                ) : (
                  <p
                    style={{ fontSize: "0.8rem" }}
                    className="d-flex align-items-center"
                  >
                    <WarningAmberIcon color="orange" className="me-2" /> NO
                    PAGADO
                  </p>
                )}
              </div>
              <div
                className={
                  "d-flex align-items-center justify-content-center ms-2 " +
                  backgrorundStatus
                }
                style={{
                  ...backgrorundStatusStyle,
                  width: "140px",
                  height: "35px",
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  borderRadius: "15px",
                }}
              >
                {row.ID_ESTATUS == ESTATUS_PEDIDOS.RECIBIDO ? (
                  <p
                    style={{ color: colorSstatus, fontSize: "0.8rem" }}
                    className="d-flex align-items-center font-Oxygen"
                  >
                    <LocalShippingOutlinedIcon className="me-2" /> RECIBIDO
                  </p>
                ) : row.ID_ESTATUS == ESTATUS_PEDIDOS.EN_PROCESO ? (
                  <p
                    style={{ color: colorSstatus, fontSize: "0.8rem" }}
                    className="d-flex align-items-center font-Oxygen"
                  >
                    <AccessTimeIcon className="me-2" /> EN PROCESO
                  </p>
                ) : row.ID_ESTATUS == ESTATUS_PEDIDOS.TERMINADO ? (
                  <p
                    style={{ color: colorSstatus, fontSize: "0.8rem" }}
                    className="d-flex align-items-center font-Oxygen"
                  >
                    <CheckCircleOutlineIcon className="me-2" /> TERMINADO
                  </p>
                ) : row.ID_ESTATUS == ESTATUS_PEDIDOS.EN_RUTA ? (
                  <p
                    style={{ color: colorSstatus, fontSize: "0.8rem" }}
                    className="d-flex align-items-center font-Oxygen"
                  >
                    <CheckCircleOutlineIcon className="me-2" /> EN RUTA
                  </p>
                ) : row.ID_ESTATUS == ESTATUS_PEDIDOS.ENTREGADO ? (
                  <p
                    style={{ color: colorSstatus, fontSize: "0.8rem" }}
                    className="d-flex align-items-center font-Oxygen"
                  >
                    <CheckCircleOutlineIcon className="me-2" /> ENTREGADO
                  </p>
                ) : (
                  <p
                    style={{ color: colorSstatus, fontSize: "0.8rem" }}
                    className="d-flex align-items-center font-Oxygen"
                  >
                    <HighlightOffIcon className="me-2" /> CANCELADO
                  </p>
                )}
              </div>
            </Typography>

            <Typography className="font-Oxygen ms-auto">TOTAL</Typography>
            <Typography
              className="font-OxygenBold text-end me-lg-5 me-md-3"
              style={{ width: 120 }}
            >
              {formatMoneda(row.TOTAL)}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {row.LOADING == 1 ? (
              <Col sm={12} className="pt-3 pb-3">
                <Loading />
              </Col>
            ) : (
              row.DETALLE && (
                <Row>
                  <Col sm={12} md={6}>
                    <DetallePedido {...row.DETALLE} />
                  </Col>
                  <Col sm={12} md={6} className="d-flex flex-wrap">
                    <DetalleProductos
                      {...{ ...row.DETALLE, PRODUCTOS: row.PRODUCTOS }}
                      showDialogFacturacion={showDialogFacturacion}
                      setShowDialogFacturacion={setShowDialogFacturacion}
                      setIdSeleccion={setIdSeleccion}
                      id={row.ID}
                      pagado={row.PAGADO}
                      facturado={row.ID_FACTURA}
                      showModalEnviarFactura={showModalEnviarFactura}
                      setShowModalEnviarFactura={setShowModalEnviarFactura}
                      setIdFactura={setIdFactura}
                    />
                  </Col>
                </Row>
              )
            )}
          </AccordionDetails>
        </Accordion>
      </div>
    </>
  );
};

export default Pedidos;
