import React, { useState } from "react";
//import ContactoBanner from "../../assets/Contacto/BannerContacto.webp";
import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { Col, Row } from "react-bootstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { EnviarCorreo } from "../Funciones/Conacto";
import "../../Lib/Styles/Styles.css";
import { ColorButton, CssTextField } from "../../Lib/Componentes/Diseño";

const MySwal = withReactContent(Swal);


const Sucursal = () => {
  return <div
    className="p-4"
    style={{
      backgroundColor: "#ebe1d6",
      height: "100%",
      maxHeight: "35%",
    }}
  >
    <p className="font-OxygenBold" style={{ fontWeight: "bold" }}>
      Taller
    </p>
    <p className="font-Oxygen">
      Naciones Unidas 4838, Col. La Patria Universidad.
    </p>
    <p className="font-OxygenBold" style={{ fontWeight: "bold" }}>
      Horario
    </p>
    <p className="font-Oxygen mb-0">
      Lunes a Viernes de 9:30 am a 6:00 pm <br />
      Sábado de 9:30 am a 2:00 pm
    </p>
  </div>
}
// const CssTextField = styled(TextField)({
//   "& label.Mui-focused": {
//     color: "#979797",
//   },
//   "& .MuiInput-underline:after": {
//     borderBottomColor: "#979797",
//   },
//   "& .MuiOutlinedInput-root": {
//     "& fieldset": {
//       borderColor: "#979797",
//     },
//     "&:hover fieldset": {
//       borderColor: "#979797",
//     },
//     "&.Mui-focused fieldset": {
//       borderColor: "#979797",
//     },
//     "& input": {
//       color: "white",
//     },
//   },
// });

// const ColorButton = styled(Button)(({ theme }) => ({
//   color: theme.palette.getContrastText("#ebe1d6"),
//   backgroundColor: "#ebe1d6",
//   "&:hover": {
//     backgroundColor: "#ebe1d6",
//   },
//   height: 50,
//   fontWeight: "bold",
//   textTransform: "capitalize",
// }));

function Contacto() {
  // const ContactoBanner =
  //   "https://florcreativa.blob.core.windows.net/imagenes/BannerContacto.webp";
  const [open, setOpen] = useState(false);
  const ContactoBanner =
    "https://florcreativa.blob.core.windows.net/imagenes/BannerContacto.webp";

  const [mensaje, setMensaje] = useState("");
  const [IsEnviado, setIsEnviado] = useState(false);
  const [Values, setValues] = useState({
    nombre: "",
    apellidos: "",
    correo: "",
    telefono: "",
    mensaje: "",
  });
  const [Errores, setErrores] = useState({
    nombre: false,
    apellidos: false,
    correo: false,
    telefono: false,
    mensaje: false,
  });

  const handleInputChange = ({ target }) => {
    const Name = target.name;
    const Value = target.value;
    let NewValue = {
      ...Values,
      [Name]: Value,
    };
    if (Name == "telefono") {
      const re = /^[0-9\b]+$/;
      if (re.test(target.value)) {
        NewValue = { ...NewValue, telefono: target.value };
      }
    }
    setValues(NewValue);
    setErrores({
      ...Errores,
      [Name]: false,
    });
  };

  const handleSubmit = (e) => {
    EnviarCorreo(Values, Errores, setErrores)
      .then((data) => {
        if (data.codigo == "200") {
          setIsEnviado(false);
          MySwal.fire({
            title: "Correcto",
            html: data.mensaje,
            icon: "success",
            confirmButtoColor: "#3ABE88",
            showConfirmButton: false,
            timer: 1700,
          }).then((result) => { });
        } else {
          MySwal.fire({
            title: "Error",
            html: data.mensaje,
            icon: "danger",
            confirmButtoColor: "#3ABE88",
            showConfirmButton: false,
            timer: 1700,
          }).then((result) => { });
        }
      })
      .catch((data) => {
        setMensaje(data.mensaje);
        setOpen(true);
        setIsEnviado(false);
      });
  };

  return (
    <>
      <div className="espacio-header" />

      {/*<div className="container-fluid" style={{ backgroundColor: "#1d1d1b" }}>
        <div className="row">
          <div
            className="col-12 col-sm-7 col-md-6
           d-flex    
           align-items-center
          justify-content-center 
          
          
          "
          >
            <div>
              <div className="my-5 my-md-0 align-self-center">
                <h4
                  className="font-Oxygen text-white"
                  style={{ fontSize: "1.5rem", marginBottom: "0.5rem" }}
                >
                  We want to hear from you
                </h4>
                <h4
                  className="font-PlayfairDisplayItalic text-white"
                  style={{ fontSize: "4rem" }}
                >
                  Contacto
                </h4>
              </div>
            </div>
          </div>
          <div className=" col-12 col-sm-5 col-md-6 p-0   d-flex  justify-content-end ">
            <img src={ContactoBanner} className="img-fluid" />
          </div>
        </div>
  </div>*/}
      <div
        className="banner-contacto img-fluid"
        style={{
          backgroundImage: `url("${ContactoBanner}")`,
        }}
      >
        <div
          className="container d-flex flex-wrap  align-items-center justify-content-start ps-5"
          style={{ height: 500, background: "" }}
        >
          <div className="flex-grow-2 text-start ps-5 d-flex align-items-start flex-column">
            <div className="d-flex justify-content-start ">
              <h4
                className="font-PlayfairDisplay text-white"
                style={{ fontSize: "3rem", fontWeight: 100, textAlign:"start" }}
              >
                We{"   "}want
              </h4>
              <h4
                className="font-PlayfairDisplayItalic text-white  ms-3"
                style={{ fontSize: "3rem", fontWeight: 100, textAlign:"start" }}
              >
                to
              </h4>
              <h4
                className="font-PlayfairDisplay text-white  ms-3"
                style={{ fontSize: "3rem", fontWeight: 100, textAlign:"start" }}
              >
                hear
              </h4>
            </div>
            <div className="d-flex justify-content-start ">
              <h4
                className="font-PlayfairDisplay text-white"
                style={{ fontSize: "3rem", fontWeight: 100, textAlign:"start" }}
              >
                from
              </h4>
              <h4
                className="font-PlayfairDisplay text-white ms-3"
                style={{ fontSize: "3rem", fontWeight: "bold", textAlign:"start" }}
              >
                you
              </h4>
            </div>
          </div>
        </div>
      </div>

      <div className="w-100 my-4 py-4" />
      <div className="d-flex justify-content-center">
        <h1
          className="text-black font-PlayfairDisplay"
          style={{ fontWeight: "100" }}
        >
          Sucursales
        </h1>
      </div>
      <div className="w-100 my-3 py-3" />
      <div className="container">

        <div className="w-100 d-md-none">
          <Sucursal />
        </div>
        <div className="w-75 d-none d-md-block mx-auto">
          <Sucursal />
        </div>
        <div className="w-100 my-2 py-2" />
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d427.84452054448053!2d-103.41878451408988!3d20.688204686944555!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8428afd40ea69c17%3A0x860a5eb71ac0a6ac!2sMichelle%20Pourroy!5e0!3m2!1ses-419!2smx!4v1682009389065!5m2!1ses-419!2smx"
          height="400"
          width="100%"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
      <div className="w-100" style={{ backgroundColor: "#212529" }}>
        <div className="w-100 my-5 py-5" />
        <div className="d-flex justify-content-center">
          <h1
            className="text-white font-PlayfairDisplay"
            style={{ fontWeight: "100" }}
          >
            Contáctanos
          </h1>
        </div>
        <div className="w-100 my-3 py-3" />
        <div className="d-flex align-items-center justify-content-center">
          <div className="LineRosa" />
          <h6
            className="font-PlayfairDisplay ms-4"
            style={{ color: "#c8a295", fontSize: "20px" }}
          >
            (Write it down)
          </h6>
          <div className="LineRosa" />
        </div>
        <div className="w-100 my-3 py-3" />

        <div
          className="container"
          style={{ maxWidth: "500px", margin: "auto" }}
        >
          <div className="row">
            <div className="col-sm-12 col-md-6">
              <CssTextField
                fullWidth
                id="Nombre"
                name="nombre"
                label="Nombre"
                variant="outlined"
                InputLabelProps={{
                  style: { color: "white" },
                }}
                className="mx-auto my-2 text-field"
                value={Values.nombre}
                error={Errores.nombre}
                onChange={handleInputChange}
              />
            </div>
            <div className="col-sm-12 col-md-6">
              <CssTextField
                fullWidth
                id="Apellidos"
                name="apellidos"
                label="Apellidos"
                variant="outlined"
                InputLabelProps={{
                  style: { color: "white" },
                }}
                className="mx-auto my-2 text-field"
                value={Values.apellido}
                error={Errores.apellido}
                onChange={handleInputChange}
              />
            </div>
            <div className="col-sm-12 col-md-6">
              <CssTextField
                fullWidth
                id="Correo"
                name="correo"
                label="Correo electrónico"
                variant="outlined"
                InputLabelProps={{
                  style: { color: "white" },
                }}
                className="mx-auto my-2 text-field"
                value={Values.correo}
                error={Errores.correo}
                onChange={handleInputChange}
              />
            </div>
            <div className="col-sm-12 col-md-6">
              <CssTextField
                fullWidth
                id="Telefono"
                name="telefono"
                label="Teléfono"
                variant="outlined"
                InputLabelProps={{
                  style: { color: "white" },
                }}
                className="mx-auto my-2 text-field"
                value={Values.telefono}
                error={Errores.telefono}
                onChange={handleInputChange}
              />
            </div>
            <div className="col-sm-12 col-md-12">
              <CssTextField
                fullWidth
                multiline
                rows={4}
                id="Mensaje"
                name="mensaje"
                label="Mensaje"
                variant="outlined"
                InputLabelProps={{
                  style: { color: "white" },
                }}
                InputProps={{
                  style: { color: "white" },
                }}
                className="mx-auto my-4 text-field"
                value={Values.mensaje}
                error={Errores.mensaje}
                onChange={handleInputChange}
              />
            </div>
            <div className="col-sm-12 col-md-12">
              <ColorButton
                variant="contained"
                onClick={handleSubmit}
                style={{ width: "100%" }}
              >
                Enviar
              </ColorButton>
            </div>
            <div className="w-100 my-.5 py-.5" />
          </div>
        </div>
        <div className="w-100 my-3 py-3" />
        <div className="d-flex align-items-center justify-content-center">
          <div className="align-items-center">
            <p
              className="text-white font-Oxygen text-center"
              style={{ fontWeight: "bold" }}
            >
              Contacto Administración
            </p>
            <a
              href="mailto:  adm.entreflor@gmail.com"
              style={{ textDecoration: "none" }}
            >
              <h6
                className="font-Oxygen text-center"
                style={{ color: "white" }}
              >
                adm.entreflor@gmail.com
              </h6>
            </a>
            <div className="w-100 my-3 py-3" />
            <p
              className="text-white font-Oxygen text-center"
              style={{ fontWeight: "bold" }}
            >
              Contacto Ventas
            </p>
            <a
              href="mailto:ventas.mpourroy@gmail.com"
              style={{ textDecoration: "none" }}
            >
              <h6
                className="font-Oxygen text-center"
                style={{ color: "white" }}
              >
                ventas.mpourroy@gmail.com
              </h6>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contacto;
