import { stringify } from "query-string";
import { KEY, getData, postUrl, postdData } from "../../../Context/backend";
import validator from "validator";

export const getSucursalesByKey = async () => {
  let data = [];
  const url = `sucursales/getSucursalesByID?KEY=${KEY}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      console.log("Sucursales", data);
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const getEstados = async (query = "") => {
  let data = [];
  let queryParamsObj = {
    query: query,
  };
  const url = `direcciones/ListarEstados?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const getMunicipios = async (id_estado, query = "") => {
  let data = [];
  let queryParamsObj = {
    estado: id_estado,
    query: query,
  };
  const url = `direcciones/ListarMunicipios?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const getInfoEstado = async (id) => {
  let data = [];
  let queryParamsObj = {
    id: id,
  };
  const url = `direcciones/ConsultarEstado?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = JSON.parse(res.data.respuesta.data)[0];
      let Values = {
        id: String(data.ID),
        nombre: String(data.NOMBRE),
      };
      resolve(Values);
    } else {
      reject(res);
    }
  });
};

export const getInfoMunicipio = async (id) => {
  let data = [];
  let queryParamsObj = {
    id: id,
  };
  const url = `direcciones/ConsultarMunicipio?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = JSON.parse(res.data.respuesta.data)[0];
      let Values = {
        id: String(data.ID),
        nombre: String(data.NOMBRE),
      };
      resolve(Values);
    } else {
      reject(res);
    }
  });
};
export const getLista = async (query) => {
  let data = [];
  let queryParamsObj = {
    query: query,
  };

  const url = `direcciones/Listar?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const getInfoDireccion = async (id) => {
  let data = [];
  let queryParamsObj = {
    id: id,
  };
  const url = `direcciones/Consultar?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = JSON.parse(res.data.respuesta.data);

      if (data.length == 0) {
        resolve({
          err: [],
          mensaje: "No se encontró información de este dimicilio",
        });
      } else {
        data = JSON.parse(res.data.respuesta.data)[0];

        let Values = {
          id: String(data.ID),
          id_cliente: String(data.ID_USUARIO),
          nombre: String(data.NOMBRE_CONTACTO),
          telefono: String(data.TELEFONO),
          cp: String(data.CP),
          calle: String(data.CALLE),
          numeroext: String(data.NUMERO_EXT),
          numeroint: String(data.NUMERO_INT),
          colonia: String(data.COLONIA),
          estado: data.ID_ESTADO,
          municipio: data.ID_MUNICIPIO,
          entrecalle1: String(data.CALLE1),
          entrecalle2: String(data.CALLE2),
          tipodomicilio: String(data.TIPODOMICILIO),
          indicaciones: String(data.INDICACIONES),
        };
        resolve(Values);
      }
    } else {
      reject({
        mensaje: "Error al conectar a internet, revisa tu conexion a internet",
      });
    }
  });
};

////////////////////
/* FUNCIONES POST */
////////////////////
export const guardar = async (Values, Errores, setErrores, isDefault) => {
  let error = false;

  if (!Values.nombre || Values.nombre == "") {
    Errores.nombre = true;
    setErrores(Errores);
    error = true;
  }

  if (!Values.telefono || Values.telefono == "") {
    Errores.telefono = true;
    setErrores(Errores);
    error = true;
  } else {
    if (!validator.isNumeric(Values.telefono)) {
      Errores.telefono = true;
      setErrores(Errores);
      error = true;
    } else if (Values.telefono.length != 10) {
      Errores.telefono = true;
      setErrores(Errores);
      error = true;
    }
  }

  if (!Values.cp || Values.cp == "") {
    Errores.cp = true;
    setErrores(Errores);
    error = true;
  } else {
    if (!validator.isNumeric(Values.cp)) {
      Errores.cp = true;
      setErrores(Errores);
      error = true;
    } else if (Values.cp.length != 5) {
      Errores.cp = true;
      setErrores(Errores);
      error = true;
    }
  }

  if (!Values.calle || Values.calle == "") {
    Errores.calle = true;
    setErrores(Errores);
    error = true;
  }
  if (!Values.numeroext || Values.numeroext == "") {
    Errores.numeroext = true;
    setErrores(Errores);
    error = true;
  }

  if (!Values.colonia || Values.colonia == "") {
    Errores.colonia = true;
    setErrores(Errores);
    error = true;
  }

  if (!Values.estado || Values.estado == "" || Values.estado == "0") {
    Errores.estado = true;
    setErrores(Errores);
    error = true;
  }

  if (!Values.municipio || Values.municipio == "" || Values.municipio == "0") {
    Errores.municipio = true;
    setErrores(Errores);
    error = true;
  }

  if (!Values.tipodomicilio || Values.tipodomicilio == "") {
    Errores.tipodomicilio = true;
    setErrores(Errores);
    error = true;
  }

  Values.isDefault = isDefault;

  // console.log(Values)

  if (!error) {
    let data = [];
    const body = Values;

    const url =
      Values.id == undefined || Values.id == "" || Values.id == 0
        ? "direcciones/Insertar"
        : "direcciones/Editar";
    const res = await postdData(url, body);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;

        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "Completa todos los campos requeridos" });
    });
  }
};

export const Eliminar = async (id) => {
  if (id != undefined) {
    let data = [];
    let queryParamsObj = {
      id: id,
    };
    const url = `direcciones/Eliminar?${stringify(queryParamsObj)}`;
    const res = await postUrl(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};

export const seDefault = async (id) => {
  if (id != undefined) {
    let data = [];
    let queryParamsObj = {
      id: id,
    };
    const url = `direcciones/seDefault?${stringify(queryParamsObj)}`;
    const res = await postUrl(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};
