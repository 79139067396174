import React from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

const SelectColor = ({ options, value, onChange }) => {
  return (
    <FormControl sx={{ width: "100%", maxWidth: "500px" }}>
      <InputLabel>Selcciona un color</InputLabel>
      <Select
        value={value}
        onChange={onChange}
        variant="outlined"
        displayEmpty={true}
        label="Selcciona un color"
      >
        {options.map((option) => (
          <MenuItem key={option.ID} value={option.ID}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                className="Oxygen"
                style={{

                  width: "24px",
                  height: "24px",
                  marginRight: "8px",
                  backgroundColor: option.CODIGO,
                  borderRadius: "50%",
                }}
              />
              {option.NOMBRE}
            </div>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectColor;
