import React, { useState } from "react";
import { getLocalStorageJWT } from "../../Context/Storage";
import FavoritosOFF from "../../assets/FavoritosOFF.svg";
import FavoritosON from "../../assets/FavoritosON.svg";
import { guardarFavorito } from "./Funciones/Favoritos";
import { IconButton, Snackbar, Alert } from "@mui/material";
import { styled } from "@mui/material/styles";

const BotonFavorito = ({ id, favorito, Lista, setLista, setStateRow }) => {
  const [open, setOpen] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [iconMsg, setIconMsg] = useState("")

  const handleClose = () => {
    setOpen(false);
  };

  const GuardarFavorito = (idProducto, checked) => {
    if (getLocalStorageJWT() === "") {
      setIconMsg("error")
      setMensaje(
        "Debes de iniciar sesión para poder guardalo productos en favoritos"
      );
      
      setOpen(true);
    } else {
      if (idProducto != undefined) {
        let icon = checked ? FavoritosON : FavoritosOFF;

        guardarFavorito(idProducto, checked)
          .then((data) => {
            const listaFavoritos = Lista.filter((item) => {
              if (item.ID == idProducto) {
                item.FAVORITO = !checked;
              }

              return item;
            });
            setMensaje(data.mensaje);
            setLista(listaFavoritos);
            setStateRow(icon);
            setIconMsg("success")
            setOpen(true);
            
          })
          .catch((resp) => {
            setMensaje("Error");
            setIconMsg("error")
            setOpen(true);
          });
      }
    }
  };

  const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
    ({ theme }) => ({
      "& .MuiSnackbar-root": {
        top: theme.spacing(15),
      },
    })
  );

  return (
    <>
      <StyledSnackbar
        direction="right"
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity={iconMsg} sx={{ width: "100%" }}>
          {mensaje}
        </Alert>
      </StyledSnackbar>
      <IconButton
        aria-label="Close"
        className="m-0"
        onClick={(event) => {
          event.preventDefault();
          console.log("Se dio click en " + id, favorito);
          GuardarFavorito(id, favorito);
        }}
      >
        <img
          src={favorito ? FavoritosON : FavoritosOFF}
          className="img-fluid"
          style={{ width: "30px" }}
        />
      </IconButton>
    </>
  );
};
export default BotonFavorito;
