import {
    Alert,
    Avatar,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    List,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    Snackbar,
    Tooltip
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useState, useEffect } from "react";
import { eliminarComprobante, getComprobantes } from "../../../Funciones/Pedidos";
import Loading from "../../../../Includes/Loading";
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import AccEditar from "../../../../assets/AccEditar.svg";
import AccEliminar from "../../../../assets/AccEliminar.svg";

import { styled } from "@mui/material/styles";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

const MySwal = withReactContent(Swal);
const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
    ({ theme }) => ({
        "& .MuiSnackbar-root": {
            top: theme.spacing(15),
        },
    })
);

const ModalComprobanteLista = ({
    pedidoSeleccionado
    , setShowDialogVerComprobante
    , setShowDialogComprobante
    , setComprobanteSeleccionado
    , setShowDialogCargarComprobante

}) => {

    const [mensaje, setMensaje] = useState("");
    const [openAlert, setOpenAlert] = useState(false);
    const [IsLoading, setIsLoading] = useState(true)
    const [comprobantes, setComprobantes] = useState([])
    const [comprobante, setComprobante] = useState(0);
    const [estatusPedido, setEstatusPedido] = useState(-1)

    const ConsultarComprobantes = () => {
        getComprobantes(pedidoSeleccionado).then(resp => {
            setIsLoading(false)
            setComprobantes(Array.isArray(resp.data) ? resp.data : [])
            setEstatusPedido(resp.estatus_pedido)
        }).catch(error => {
            setIsLoading(false);
        })
    }

    const EliminarComprobante = (id) => {
        new Swal({
            title: "¿Estás seguro de eliminar el comprobante?",
            text: "Esta acción no se puede deshacer",
            icon: "warning",
            showDenyButton: true,
            denyButtonText: "No, cancelar",
            confirmButtonText: "Si, estoy seguro",
            confirmButtonColor: "#3ABE88",
            denyButtonColor: "#65748B",
            reverseButtons: true,
        }).then(function (isConfirm) {
            console.log(isConfirm.isConfirmed);
            if (isConfirm.isConfirmed) {
                eliminarComprobante(id, pedidoSeleccionado, true)
                    .then((data) => {
                        if (data.codigo == "200") {
                            MySwal.fire({
                                title: "Correcto",
                                html: data.mensaje,
                                icon: "success",
                                confirmButtoColor: "#3ABE88",
                                showConfirmButton: false,
                                timer: 1700,
                            }).then((result) => {
                                setShowDialogCargarComprobante(false);
                                if (window.fnConsultarComprobantes) {
                                    window.fnConsultarComprobantes()
                                }
                            });
                        } else {
                            setMensaje(data.mensaje);
                            setOpenAlert(true);
                        }
                    })
                    .catch((data) => {
                        setMensaje(data.mensaje);
                        setOpenAlert(true);
                    });
            }
        });
    }

    useEffect(() => {
        ConsultarComprobantes()
    }, [])
    window.fnConsultarComprobantes = ConsultarComprobantes


    return (
        <>
            <StyledSnackbar
                direction="right"
                open={openAlert}
                autoHideDuration={6000}
                onClose={() => setOpenAlert(false)}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            >
                <Alert onClose={() => setOpenAlert(false)} severity="error" sx={{ width: "100%" }}>
                    {mensaje}
                </Alert>
            </StyledSnackbar>

            <Dialog
                fullWidth
                maxWidth="sm"
                open={true}
                PaperProps={{ elevation: 0 }}
            >
                <IconButton
                    aria-label="Close"
                    className="m-3"
                    style={{ position: "absolute", right: "0%" }}
                    onClick={() => {
                        setShowDialogComprobante(false);
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogTitle>
                    <h6 className="fw-bolder f-12 mt-2">Comprobantes de pago</h6>
                </DialogTitle>
                <DialogContent>

                    {IsLoading ? (
                        <div className="col-12  ">
                            <Loading />
                        </div>
                    ) : (
                        comprobantes.length == 0 ? (
                            <div className="p-5">
                                <h5 className="text-center"> Sin comprobantes cargados </h5>
                            </div>
                        ) : (
                            < List className="p-0">
                                {comprobantes.map((row) => {
                                    return (
                                        <ListItemButton
                                            style={{
                                                backgroundColor:
                                                    row.ID == comprobante ? "lightgray" : "",
                                            }}
                                            onClick={() => {
                                                setComprobante(row.ID);
                                            }}
                                        >
                                            <ListItemAvatar>
                                                {
                                                    row.URL == "" ? (
                                                        <Avatar>
                                                            <TextSnippetIcon />
                                                        </Avatar>
                                                    ) : (
                                                        row.URL.includes(".pdf") ? (
                                                            <Avatar>
                                                                <PictureAsPdfIcon />
                                                            </Avatar>
                                                        ) : (
                                                            <Avatar className="border  " src={row.URL} />
                                                        )

                                                    )
                                                }
                                            </ListItemAvatar>
                                            <ListItemText
                                                style={{ overflowWrap: 'break-word', paddingRight: '30px' }}
                                                primary={"Observaciones"}
                                                secondary={row.COMENTARIO.trim() == "" ? 'Sin observaciones' : row.COMENTARIO}
                                            />
                                            <div className="d-flex ">
                                                <Tooltip title="Ver comprobante" placement="top" style={{ width: 52, height: 52 }}>
                                                    <IconButton onClick={() => {
                                                        setShowDialogVerComprobante(true)
                                                        setComprobanteSeleccionado(row.ID)
                                                    }}>
                                                        <RemoveRedEyeIcon style={{ color: "#000" }} />
                                                    </IconButton>
                                                </Tooltip>
                                                {
                                                    estatusPedido == 1 && (
                                                        <Tooltip title="Eliminar comprobante" placement="top" style={{ width: 52, height: 52 }}>
                                                            <IconButton
                                                                onClick={() => EliminarComprobante(row.ID)}>
                                                                <img src={AccEliminar} />
                                                            </IconButton>
                                                        </Tooltip>
                                                    )
                                                }

                                            </div>
                                        </ListItemButton>
                                    );
                                })}
                            </List>
                        )
                    )
                    }

                </DialogContent>
            </Dialog >
        </>
    )
}

export default ModalComprobanteLista