import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import LogoutIcon from "@mui/icons-material/Logout";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { clearStorageJWT, getLocalStorage } from "../Context/Storage"; 
import Swal from "sweetalert2";
import { Avatar } from "@mui/material";

const CerrarSesion = () =>
  new Swal({
    title: "¿Estás seguro de cerrar sesión?",
    icon: "warning",
    showDenyButton: true,
    denyButtonText: "No, cancelar",
    confirmButtonText: "Si, estoy seguro",
    confirmButtonColor: "#c9a396",
      denyButtonColor: "#979797",
    reverseButtons: true,
  }).then((result) => {
    if (result.isConfirmed) {
      Swal.fire({
        icon: "success",
        title: "Cerrando sesión...",
        showConfirmButton: false,
        timer: 1500,
      }).then(clearStorageJWT);
    }
  });


const AccountMenu = ({ Values, IsLoading }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const img_url = IsLoading ? getLocalStorage("img") : Values.img;

  return (
    <React.Fragment>
      <Tooltip title="Mi perfil">
        <IconButton className="p-0 ms-2">
          <Avatar
          className="border"
            sx={{ width: 30, height: 30 }}
            src={img_url}
            onClick={handleClick}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          ></Avatar>
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Link to="perfil/" style={{ textDecoration: "none", color: "black" }}>
          <MenuItem>
            <ListItemIcon>
              <ManageAccountsOutlinedIcon />
            </ListItemIcon>
            Mi perfil
          </MenuItem>
        </Link>
        <Link
          onClick={CerrarSesion}
          style={{ textDecoration: "none", color: "black" }}
        >
          <MenuItem>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            Cerrar sesión
          </MenuItem>
        </Link>
      </Menu>
    </React.Fragment>
  );
};

export default AccountMenu;
