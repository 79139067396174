import { getData } from "../../Context/backend";

export const getHorarios = async (ID_SUCURSAL, FECHA) => {
  let data = [];
  const url = `horarios/getopciones?FECHA=${FECHA}&ID_SUCURSAL=${ID_SUCURSAL}`;
  const res = await getData(url, true);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};
