import {
    Alert,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormLabel,
    IconButton,
    Radio,
    RadioGroup,
    Snackbar,
    TextField,
    Typography,
  } from "@mui/material";
  import CloseIcon from "@mui/icons-material/Close";
  import { useEffect, useState } from "react";
  import { Button, Col, Row } from "react-bootstrap";
  import {  getSucursalesByKey } from "../Funciones/Direcciones";
  import Estado from "./Estado";
  import Municipio from "./Municipio";
  
  import withReactContent from "sweetalert2-react-content";
  import Swal from "sweetalert2";
  import Loading from "../../../Includes/Loading";
  import { styled } from "@mui/material/styles";
  import { LoadingButton } from "@mui/lab";
  const MySwal = withReactContent(Swal);
  
  const ModalSelectSucursal = ({
    SetshowModalDireccion,  
    setSucursal
  }) => {



    const [IsLoading, setIsLoading] = useState(true); 
    const [Lista, setLista] = useState([]);


    useEffect(() => {
        getDireciones();
      }, []);
    
      const getDireciones = () => {
        setIsLoading(true);
        getSucursalesByKey("")
          .then((resp) => {
            let list = JSON.parse(resp.data);
            setLista(list);
            setIsLoading(false);
          })
          .catch((resp) => {
            setLista([]);
            setIsLoading(false);
          });
      };
    
  
    const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
      ({ theme }) => ({
        "& .MuiSnackbar-root": {
          top: theme.spacing(15),
        },
      })
    );
  
  
   
    return (
      <> 
        <Dialog fullWidth maxWidth="xs" open={true}>
          <IconButton
            aria-label="Close"
            className="m-3"
            style={{ position: "absolute", right: "0%" }}
            onClick={() => {
              SetshowModalDireccion(false);
            }}
          >
            <CloseIcon size="lg" />
          </IconButton>
          <DialogTitle>
            <h6 className="fw-bolder f-12 mt-2">Selecciona una sucursal</h6>
          </DialogTitle>
          <DialogContent>
            {IsLoading ? (
              <Col sm={12} className="pt-5">
                <Loading />
              </Col>
            ) : (
                <Row className="p-2">
                {Lista.map((row) => (
                  <>
                    <div
                    onClick={()=>
                    { 
                        setSucursal({
                            id:row.ID,
                             domicilio:row.DIRECCION_SUCURSAL
                            ,nombre: row.NOMBRE_SUCURSAL
                            ,tipo:"DOMICILIO"
                            });
                        SetshowModalDireccion(false);

                    }}
                      className="pb-0 px-3 px-md-4 pt-3  mt-3 direccion-hover"
                      style={{ backgroundColor: "#F7F7F7", cursor:"pointer" }}
                    >
                      <div className="row">
                        <div className="col-12">
                          <h6 className="font-OxygenBold">{row.NOMBRE_SUCURSAL} </h6>
                        </div>
    
                        <div className="col-12  mt-3 mt-md-1">
                          <small>
                            <p className="font-OxygenBold my-1">Dirección</p>
                            <p className="font-OxygenLight my-1">
                                {row.DIRECCION_SUCURSAL}
                                </p>
                          </small>
                        </div> 
                      </div>
                    </div>
                  </>
                ))}
              </Row>
            )}
          </DialogContent>
          <DialogActions>
           
          </DialogActions>
        </Dialog>
      </>
    );
  };
  export default ModalSelectSucursal;
  