import { Route, Routes } from "react-router-dom";

import Contacto from "./Componentes/Contacto";
import Error404 from "../Includes/Error404";
const ContactoRote = () => {
  return (
    <>
      <Routes>
        <Route index element={<Contacto />} />
        <Route path="*" element={<Error404 />} />
      </Routes>
    </>
  );
};

export default ContactoRote;
