export function getTiempoFromMinutos(minutos) {
  if (parseInt(minutos) < 60) return minutos + " Minutos";
  if (parseInt(minutos) >= 60 && parseInt(minutos) < 1440) {
    let min = minutos % 60;
    let horas = (minutos - min) / 60;
    return (
      Math.round(horas) +
      " Hrs " +
      (min > 0 ? " " + Math.round(min) + " Min" : "")
    );
  }
  if (Math.round(parseInt(minutos) / 60) == 24) {
    return Math.round(parseInt(minutos) / 60 / 24) + " días";
  }
  if (Math.round(parseInt(minutos) / 60) > 24) {
    let horas = (parseInt(minutos) / 60) % 24;
    let dias = (parseInt(minutos) / 60 - horas) / 24;

    return dias + (dias == 1 ? " Día " : " Días ") + Math.round(horas) + " Hrs";
  }
}

export function getTiempoFromMinutosToHoras(minutos) {
  if (parseInt(minutos) < 60) return minutos + " Minutos";
  if (parseInt(minutos) >= 60) {
    let min = minutos % 60;
    let horas = (minutos - min) / 60;
    return (
      Math.round(horas) +
      " Hrs " +
      (min > 0 ? " " + Math.round(min) + " Min" : "")
    );
  }
}

export function getPorcentaje(porcentaje = "") {
  let porciento = porcentaje.toString();
  let clase = "info";
  if (porciento <= 20) {
    return (clase = "bg-warning");
  } else if (porciento <= 50) {
    return (clase = "bg-info");
  } else if (porciento <= 70) {
    return (clase = "bg-primary");
  } else if (porciento == 100) {
    return (clase = "bg-success");
  }
}
export function getPorcentaje1(porcentaje = "") {
  let porciento = porcentaje.toString();
  let clase = "info";
  if (porciento <= 20) {
    return (clase = "bg-dark");
  } else if (porciento <= 50) {
    return (clase = "bg-dark");
  } else if (porciento <= 70) {
    return (clase = "bg-dark");
  } else if (porciento == 100) {
    return (clase = "bg-dark");
  }
}

export function firstLeterMayus(miOracion) {
  //console.log(miOracion);
  miOracion = miOracion ?? "";
  miOracion = miOracion.toLowerCase();
  const palabras = miOracion.split(" ");
  for (let i = 0; i < palabras.length; i++) {
    palabras[i] =
      palabras[i][0] == undefined || palabras[i][0] == ""
        ? ""
        : palabras[i][0].toUpperCase() + palabras[i].substr(1);
  }
  return palabras.join(" ");
}

export function format2decimales(valu) {
  return valu.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function formatMoneda(number) {
  try { 
    const formattedNumber = new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(number);
    return formattedNumber;
  } catch {
    return "$0";
  }
}

export function avatarLetters(nombre = "") {
  try {
    const arrPalabras = nombre.split(" ");
    //console.log(arrPalabras)
    //console.log(arrPalabras[7][0])

    const avatarString =
      arrPalabras[0][0]?.toUpperCase() +
      (arrPalabras.length > 1
        ? arrPalabras[1][0]
        : arrPalabras[0][1]
      )?.toUpperCase();

    return avatarString;
  } catch {
    return "0";
  }
}
