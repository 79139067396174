import { getData, postdData } from "./../../Context/backend";
import validator from "validator";
import { setLocalStorageJWT } from "../../Context/Storage";
import RFCValidator from "../../Lib/Funciones Generales/ValidarRFC";

///////////////////
/* FUNCIONES GET */
///////////////////

export const getInfoPerfil = async (imgDefault, setTok = false) => {
  let data = [];
  const url = `perfil/Consultar?nwt=${setTok}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = JSON.parse(res.data.respuesta.data)[0];

      let Values = {
        id: String(data.ID),
        nombre: String(data.NOMBRE),
        pass_default: String(data.DEFAULT_PASS) == "0" ? false : true,
        razon_social: String(data.RAZON_SOCIAL),
        regimen_fiscal: String(data.REGIMEN_FISCAL),
        rfc: String(data.RFC),
        direccion: String(data.DIRECCION),
        cp: String(data.CP),
        correo: String(data.CORREO),
        telefono: String(data.TELEFONO),
        img:
          String(data.IMG) == ""
            ? imgDefault
            : String(data.IMG) + "?" + Math.floor(Math.random() * 1000000) + 1,
        type: res.data.respuesta.typeUser,
        apellido: String(data.APELLIDO),
      };

      console.log(Values);
      let permisos = []; //  JSON.parse(res.data.respuesta.permisos);

      if (setTok) {
        if (res.data.respuesta.setToken.confirm) {
          setLocalStorageJWT(res.data.respuesta.setToken.token);
        }
      }
      resolve({ Values: Values, permisos: permisos });
    } else {
      reject(res);
    }
  });
};

export const getInfoFactura = async () => {
  let data = [];
  const url = `perfil/ConsultarFactura`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = JSON.parse(res.data.respuesta.data)[0];

      let Values = {
        id: String(data.ID),
        rfc: String(data.RFC).toUpperCase(),
        razon_social: String(data.RAZON_SOCIAL).toUpperCase(),
        cp: data.CP == "0" ? "" : String(data.CP),
        regimen_fiscal: String(data.REGIMEN_FISCAL),
        requiere_factura: data.REQUIERE_FACTURA,
        cfdi: String(data.USO_CFDI),
      };

      console.log(Values);

      resolve({ Values: Values });
    } else {
      reject(res);
    }
  });
};

export const getInfoMonedero = async () => {
  let data = [];
  const url = `perfil/ConsultarMonedero`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
    //  console.log(res.data.respuesta)
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

////////////////////
/* FUNCIONES POST */
////////////////////
export const guardar = async (Values, Errores, setErrores, perfilType) => {
  let error = false;

  if (!Values.nombre || Values.nombre == "") {
    Errores.nombre = true;
    setErrores(Errores);
    error = true;
  }
  if (!Values.correo || Values.correo == "") {
    Errores.correo = true;
    setErrores(Errores);
    error = true;
  } else {
    if (!validator.isEmail(Values.correo)) {
      Errores.correo = true;
      setErrores(Errores);
      error = true;
    }
  }
  if (!Values.telefono || Values.telefono == "") {
    Errores.telefono = true;
    setErrores(Errores);
    error = true;
  } else {
    if (!validator.isNumeric(Values.telefono)) {
      Errores.telefono = true;
      setErrores(Errores);
      error = true;
    } else if (Values.telefono.length != 10) {
      Errores.telefono = true;
      setErrores(Errores);
      error = true;
    }
  }

  if (!Values.apellido || Values.apellido == "") {
    Errores.apellido = true;
    setErrores(Errores);
    error = true;
  }

  if (!error) {
    if (Values.img.includes("base64")) {
      Values.img = Values.img.split("base64,")[1];
    }

    let data = [];
    const body = {
      nombre: Values.nombre,
      apellidos: Values.apellido,
      correo: Values.correo,
      telefono: Values.telefono,
    };

    const url = "perfil/Editar";
    const res = await postdData(url, body);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;

        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "Completa todos los campos requeridos" });
    });
  }
};

export const guardarDatosFactura = async (Values, Errores, setErrores) => {
  let error = false;

  let Errores2 = Errores;
  if (!Values.razon_social || Values.razon_social == "") {
    Errores2.razon_social = true;
    setErrores(Errores2);
    error = true;
  }

  if (!Values.cp || Values.cp == "" || Values.cp.length > 5) {
    Errores2.cp = true;
    setErrores(Errores2);
    error = true;
  }

  /*if (!Values.cfdi || Values.cfdi == "") {
    Errores2.cfdi= true;
    setErrores(Errores2);
    error = true;
  }*/

  if (!Values.rfc || Values.rfc == "") {
    Errores2.rfc = true;
    setErrores(Errores2);
    error = true;
  } else {
    if (!RFCValidator(Values.rfc)) {
      Errores.rfc = true;
      setErrores(Errores);
      error = true;
    }
  }

  if (
    !Values.regimen_fiscal ||
    Values.regimen_fiscal == "" ||
    Values.regimen_fiscal == "0"
  ) {
    Errores2.regimen_fiscal = true;
    setErrores(Errores2);
    error = true;
  }

  Values.regimen_fiscal = String(Values.regimen_fiscal);
  let data = [];
  const body = Values;
  if (!error) {
    const url = "perfil/EditarFactura";
    const res = await postdData(url, body);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "Completa todos los campos requeridos" });
    });
  }
};

export const guardarDatosCreditos = async (Values, Errores, setErrores) => {
  let error = false;
  let Errores2 = Errores;

  if (!Values.monto || Values.monto == "" || Values.monto < 0) {
    Errores2.monto = true;
    setErrores(Errores2);
    error = true;
  }

  let data = [];
  const body = Values;

  if (!error) {
    const url = "perfil/AgregarCreditos";
    const res = await postdData(url, body);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "Completa todos los campos requeridos" });
    });
  }
};

export const actualizarContrasena = async (Values, Errores, setErrores) => {
  let error = false;

  if (!Values.contrasena_actual || Values.contrasena_actual == "") {
    Errores.contrasena_actual = true;
    setErrores(Errores);
    error = true;
  } else {
    if (!Values.nueva_contrasena || Values.nueva_contrasena == "") {
      Errores.nueva_contrasena = true;
      setErrores(Errores);
      error = true;
    } else {
      if (Values.confirmar_nueva_contrasena != Values.nueva_contrasena) {
        Errores.confirmar_nueva_contrasena = true;
        setErrores(Errores);
        error = true;
      }
    }
  }

  if (!error) {
    let data = [];
    const body = Values;

    const url = "perfil/CambiarPass";
    const res = await postdData(url, body);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        //console.log(data);
        setLocalStorageJWT(data.respuesta.nwToken);
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "Completa todos los campos requeridos" });
    });
  }
};
