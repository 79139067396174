import { Route, Routes, Outlet, useLocation, Link } from "react-router-dom";
import Resumen from "./Resumen";

import Error404 from "../../Includes/Error404";
import {
  Alert,
  Backdrop,
  Button,
  CircularProgress,
  Snackbar,
} from "@mui/material";
import CarritoVacio from "../../assets/CarritoVacio.svg";
import Carrito from "./Carrito";
import { useContext, useEffect, useState } from "react";
import DatosEnvio from "./DatosEnvio";
import FormasPago from "./FormasPago";
import { getLocalStorageJWT } from "../../Context/Storage";
import { useCartInfo } from "../../Context/CartInfoContext";
import { CartContext } from "../../Context/CartContext";
import dayjs from "dayjs";
import "dayjs/locale/es";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { pagarOrden } from "../Funciones/Orden";
import { styled } from "@mui/material/styles";
import CartVacio from "../../Includes/CartVacio";
import { BasicDatePicker } from "../../Includes/DatePicker";
import Horarios from "./Horarios";

const Orden = () => {
  dayjs.locale("es");
  const MySwal = withReactContent(Swal);
  const [IsGuardando, setIsGuardando] = useState(false);
  const [open, setOpen] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [step, setStep] = useState(0);
  const { cartInfo, setCartInfo } = useCartInfo();
  const { cartItems, getTotalPrice, getItemCount, getTotalItems } =
    useContext(CartContext);

  const [fecha, setFecha] = useState(
    cartInfo.deliveryDate == "" ? dayjs() : cartInfo.deliveryDate
  );
  const [horario, setHorario] = useState(cartInfo.deliveryTime);
  const [deliveryMethod, setDeliveryMethod] = useState(cartInfo.deliveryMethod);
  const [sucursal, setSucursal] = useState(cartInfo.sucursal);
  const [siEditarFecha, SetSiEditarFecha] = useState(false);

  const location = useLocation();

  const handlePlaceOrder = () => {
    cartInfo.deliveryDate = fecha;
    cartInfo.deliveryTime = horario;
    setCartInfo({ ...cartInfo });
  };
  const PayOrden = () => {
    new Swal({
      title: "¿Estás seguro de realizar esta compra?",
      text: " Verifica que los datos de envío sean los correctos",
      icon: "warning",
      showDenyButton: true,
      denyButtonText: "No, cancelar",
      confirmButtonText: "Si, estoy seguro",
      confirmButtonColor: "#c9a396",
      denyButtonColor: "#979797",
      reverseButtons: true,
    }).then(function (isConfirm) {
      if (isConfirm.isConfirmed) {
        setIsGuardando(true);
        pagarOrden(
          cartInfo,
          cartItems,
          getTotalPrice,
          getItemCount,
          getTotalItems
        )
          .then((data) => {
            if (data.codigo == "200") {
              setIsGuardando(false);
              localStorage.removeItem("cartInfo");
              localStorage.removeItem("cartItems");
              window.location.href =
                "/orden/pagosuccess/" + data.respuesta.token;
            } else {
              setMensaje(data.mensaje);
              setOpen(true);
              setIsGuardando(false);
            }
          })
          .catch((data) => {
            setMensaje(data.mensaje);
            setOpen(true);
            setIsGuardando(false);
          });
      }
    });
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (location.pathname === "/orden") {
      setStep(1);
    }
    if (location.pathname === "/orden/datosenvio") {
      setStep(2);
    }
    if (location.pathname === "/orden/pay") {
      setStep(3);
    }
    if (location.pathname === "/orden/resumen") {
      setStep(4);
    }
  }, [location]);
  useEffect(() => {
    handlePlaceOrder();
  }, [deliveryMethod, fecha, horario]);

  /*
  useEffect(() => {
    setHorario({ id: 0, 
      inicio: "00:00",
      fin: "00:00",  
  })
  }, [fecha]);
  */

  const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
    ({ theme }) => ({
      "& .MuiSnackbar-root": {
        top: theme.spacing(15),
      },
    })
  );

  return (
    <>
      {IsGuardando && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
          <h3 className="ms-4">
            Estamos realizando tu compra,
            <br />
            por favor espera...
          </h3>
        </Backdrop>
      )}

      <StyledSnackbar
        direction="right"
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {mensaje}
        </Alert>
      </StyledSnackbar>

      <div className="container">
        <div className="row  ">
          <div className="col-12 col-md-7 pt-5rem">
            <Routes>
              <Route
                path="/"
                element={getTotalPrice() != 0 ? <Carrito /> : <CartVacio />}
              />
              <Route
                path="/datosenvio"
                element={getTotalPrice() != 0 ? <DatosEnvio /> : <CartVacio />}
              />
              <Route
                path="/pay"
                element={getTotalPrice() != 0 ? <FormasPago /> : <CartVacio />}
              />
              <Route path="*" element={<Error404 />} />
            </Routes>
          </div>
          <div
            className="col-12 col-md-5 bg-gray p-4"
            style={{ minHeight: "calc(100vh - 5rem)" }}
          >
            <div className="sticky-top pt-5rem ">
              <Resumen />

              {!getLocalStorageJWT() ? (
                <Link
                  className=""
                  to="/login"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <Button
                    loadingPosition="start"
                    className="btn btn-michelle mt-4 w-100  py-2 px-4 "
                    variant="contained"
                  >
                    <span>Inicia sesión para continuar </span>
                  </Button>
                </Link>
              ) : (
                <>
                  {step == 1 ? (
                    <Link
                      className=""
                      to="/orden/datosenvio"
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <Button
                        disabled={getTotalPrice() == 0}
                        loadingPosition="start"
                        className="btn btn-michelle mt-4 w-100  py-2 px-4 "
                        variant="contained"
                      >
                        <span>Continuar </span>
                      </Button>
                    </Link>
                  ) : step == 3 ? (
                    <Button
                      disabled={
                        cartInfo.paymentMethod == "" ||
                        cartInfo.deliveryTime.id == 0 ||
                        IsGuardando
                      }
                      loading={IsGuardando}
                      onClick={PayOrden}
                      loadingPosition="start"
                      className="btn btn-michelle mt-4 w-100  py-2 px-4 "
                      variant="contained"
                    >
                      <span className={IsGuardando ? "px-4" : "px-2"}>
                        {IsGuardando ? "Creando orden..." : "Pagar orden"}
                      </span>
                    </Button>
                  ) : (
                    <></>
                  )}

                  {/*

    <div className="p-2 btn-michelle mt-3">
                    <center>
                      <img
                        src={CarritoVacio}
                        width={50}
                        className="img-fluid m-auto"
                      />
                    </center>

                    <h6 className="font-OxygenBold mt-3">
                      Lo sentimos, debido a la alta demanda, en este de momento
                      no podemos recibir tu orden, intenta más tarde
                    </h6>

                  </div>

                    */}

                  <div className="mt-5 d-flex">
                    <h6 className="text-star fw-bolder font-PlayfairDisplay">
                      Fecha de entrega
                    </h6>
                  </div>
                  <div className="">
                    <h6 className="text-star mb-1  font-Oxygen">
                      {cartInfo.deliveryDate != "" &&
                        dayjs(cartInfo.deliveryDate).format("dddd D MMMM")}
                      <br />
                      {cartInfo.deliveryTime.id != 0
                        ? "Entre las " +
                          dayjs(
                            !cartInfo.deliveryTime.inicio
                              ? "00:00:00"
                              : cartInfo.deliveryTime.inicio,
                            "HH:mm:ss"
                          ).format("HH:mm:ss") +
                          " y las " +
                          dayjs(
                            !cartInfo.deliveryTime.fin
                              ? "00:00:00"
                              : cartInfo.deliveryTime.fin,
                            "HH:mm:ss"
                          ).format("HH:mm:ss")
                        : step == 3 && "Sin horario seleccionado"}
                    </h6>
                    {step == 3 && (
                      <span
                        onClick={() => SetSiEditarFecha(!siEditarFecha)}
                        className="animated-text"
                        style={{ cursor: "pointer" }}
                      >
                        {siEditarFecha ? "Ocultar" : "Editar"}
                      </span>
                    )}
                  </div>

                  {step == 3 && siEditarFecha && (
                    <>
                      <div className="row mt-3">
                        <div className="col-12 col-md-6  mb-3 ">
                          <BasicDatePicker
                            size="small"
                            format="DD/MM/YYYY"
                            label="Fecha de entrega"
                            fecha={fecha}
                            setFecha={setFecha}
                          />
                        </div>

                        <div className="col-12 col-md-6 mb-3  ">
                          <Horarios
                            size="small"
                            horario={horario}
                            setHorario={setHorario}
                            fecha={dayjs(fecha).format("YYYY-MM-DD")}
                            sucursal={
                              deliveryMethod == "SUCURSAL" ? sucursal.id : 0
                            }
                          />
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}

              {step == 3 && (
                <>
                  <div className="mt-3 mb-3">
                    <h6 className="text-star fw-bolder font-PlayfairDisplay">
                      Dedicatoria
                    </h6>
                  </div>
                  <div className="">
                    <div className="row">
                      <div className="col-6">
                        <p className="m-0 mb-1 font-OxygenMedium ">
                          Quién envía
                        </p>
                        <p className="m-0 mb-3 font-Oxygen ">
                          {cartInfo.dedicatoria.envia == ""
                            ? "Anónimo"
                            : cartInfo.dedicatoria.envia}
                        </p>
                      </div>
                      <div className="col-6">
                        <p className="m-0 mb-1 font-OxygenMedium ">
                          Quién recibe
                        </p>
                        <p className="m-0 mb-3 font-Oxygen ">
                          {cartInfo.dedicatoria.recibe == ""
                            ? "-"
                            : cartInfo.dedicatoria.recibe}
                        </p>
                      </div>

                      <div className="col-12 ">
                        <p className="m-0 mb-1 font-OxygenMedium ">
                          Dedicatoria
                        </p>
                        <p className="m-0 mb-2 font-Oxygen parrafo-truncado3lines">
                          {cartInfo.dedicatoria.dedicatoria == ""
                            ? "-"
                            : cartInfo.dedicatoria.dedicatoria}
                        </p>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Orden;
