import {
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import { getListaComentarios } from "../../Funciones/Productos";
import Loading from "../../../Includes/Loading";
import DescripcionReserñas from "../DescripcionReserñas";


const ModalReseñas = ({
    setShowDialogReseña
    , id_producto
    , id_cliente
    , descipcion_producto
    , top = 30
    , setShowDialogReseñaEditar
    , setComentarioEditar
    , resenas
    , setReseñas
}) => {

    const [IsLoading, setIsLoading] = useState(true);
    
    useEffect(() => {
        getListaComentarios(id_producto, top).then(resp => {
            const list = JSON.parse(resp.data)
            setReseñas(list)
            setIsLoading(false)
        }).catch(error => {
            console.log(error)
            setIsLoading(false)
        })
    }, [])

    return (
        <Dialog
            fullWidth
            maxWidth="sm"
            open={true}
            PaperProps={{ elevation: 0 }}
        >
            <IconButton
                aria-label="Close"
                className="m-3"
                style={{ position: "absolute", right: "0%" }}
                onClick={() => {
                    setShowDialogReseña(false);
                }}
            >
                <CloseIcon size="lg" />
            </IconButton>
            <DialogTitle>
                <h6 className="fw-bolder text-center f-12 mt-2"> Reseñas del producto</h6>
            </DialogTitle>
            <DialogContent>
                {
                    IsLoading ? (
                        <div className="pt-5 pb-5">
                            <Loading />
                        </div>
                    ) : (
                        <DescripcionReserñas
                            id_cliente={id_cliente}
                            id_producto={id_producto}
                            descipcion={descipcion_producto}
                            resenas={resenas}
                            total_resenas={resenas.length}
                            setReseñas={setReseñas}
                            setShowDialogReseña={setShowDialogReseña}
                            setShowDialogReseñaEditar={setShowDialogReseñaEditar}
                            setComentarioEditar={setComentarioEditar}
                            hideInputComentario={true}
                            hideDescripcion={true}
                        />
                    )
                }
            </DialogContent>
        </Dialog>
    )
}

export default ModalReseñas