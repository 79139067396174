import * as React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import TextField from "@mui/material/TextField";
import dayjs from "dayjs";


export const BasicTimeClock = ({
  setHora,
  hora
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} >
          <MobileTimePicker
            className="w-100"
            label={'Hora'}
            value={dayjs(hora)}
            views={['hours', 'minutes']}
            renderInput={(params) => <TextField {...params} />}
            onChange={(inputHora) => {
              setHora(inputHora);
            }}
        />
      
    </LocalizationProvider>
  );
}