import { useEffect, useState } from "react";
import dayjs from 'dayjs';


const Countdown  = ({ timeTillDate, timeFormat }) => {
   
const [time, setTime] = useState({
    days: undefined,
    hours: undefined,
    minutes: undefined,
    seconds: undefined
});
// const [now, setNow] = useState(dayjs());

const countdownDate = new Date('2023-05-01T23:59:59Z').getTime();

var interval =null;
    const componentDidMount = ()=> {
		interval = setInterval(() => {
		 
			
           /*   const daysToSubtract = 20;
            let then = now.subtract(daysToSubtract, 'day');
			const countdown = dayjs(then - now);
           //  console.log(countdown)
         
			const days = countdown.format('D');
			const hours = countdown.format('HH');
			const minutes = countdown.format('mm');
			const seconds = countdown.format('ss');
*/

            const now = new Date().getTime();
      const distance = countdownDate - now;
      
      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

            // console.log(now)
           //  console.log(then)

			setTime({ days, hours, minutes, seconds });
		}, 1000);
	}
 
        const componentWillUnmount = ()=> {
		if(interval) {
			clearInterval(interval);
		}
	}
	 
    useEffect(()=>{
        componentDidMount();
    
        return () => {
            componentWillUnmount();
          };
    }, [])





		const { days, hours, minutes, seconds } = time;
		const daysRadius = mapNumber(days, 30, 0, 0, 360);
		const hoursRadius = mapNumber(hours, 24, 0, 0, 360);
		const minutesRadius = mapNumber(minutes, 60, 0, 0, 360);
		const secondsRadius = mapNumber(seconds, 60, 0, 0, 360);

		if(!seconds) {
			// return null;
		}
		
		return (
			<div className="d-flex justify-content-center flex-column h-100 text-center">
				<h5 className="mb-5 font-Oxygen">Muy pronto estaremos listos para recibir tus pedidos</h5>
				<div className='countdown-wrapper'> 
						<div className='countdown-item'>
							<SVGCircle radius={daysRadius} />
							{days} 
							<span>Días</span>
						</div> 
						<div className='countdown-item'>							
							<SVGCircle radius={hoursRadius} />
							{hours} 
							<span>Horas</span>
						</div> 
						<div className='countdown-item'>
							<SVGCircle radius={minutesRadius} />
							{minutes} 
							<span>Minutos</span>
						</div> 
						<div className='countdown-item'>
							<SVGCircle radius={secondsRadius} />
							{seconds} 
							<span>Segundos</span>
						</div> 
				</div>
			</div>
		);
	}
 

const SVGCircle = ({ radius }) => (
	<svg className='countdown-svg'>
		<path fill="none" stroke="#333" stroke-width="4" d={describeArc(50, 50, 48, 0, radius)}/>
	</svg>
);

 
// From stackoverflow: https://stackoverflow.com/questions/5736398/how-to-calculate-the-svg-path-for-an-arc-of-a-circle
function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
  var angleInRadians = (angleInDegrees-90) * Math.PI / 180.0;

  return {
    x: centerX + (radius * Math.cos(angleInRadians)),
    y: centerY + (radius * Math.sin(angleInRadians))
  };
}

function describeArc(x, y, radius, startAngle, endAngle){

    var start = polarToCartesian(x, y, radius, endAngle);
    var end = polarToCartesian(x, y, radius, startAngle);

    var largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

    var d = [
        "M", start.x, start.y, 
        "A", radius, radius, 0, largeArcFlag, 0, end.x, end.y
    ].join(" ");

    return d;       
}

// Stackoverflow: https://stackoverflow.com/questions/10756313/javascript-jquery-map-a-range-of-numbers-to-another-range-of-numbers
function mapNumber(number, in_min, in_max, out_min, out_max) {
  return (number - in_min) * (out_max - out_min) / (in_max - in_min) + out_min;
}


export default Countdown