import { Typography, IconButton, Tooltip } from "@mui/material";
import { formatMoneda } from "../../../Lib/Funciones Generales/Generales";
import { Link } from "react-router-dom";
//import ModalFacturacion from "./Dialogos/ModalFacturacion";
import XML from "../../../assets/XML.svg";
import Pdf from "../../../assets/Pdf.svg";
import Enviar from "../../../assets/Enviar.svg";
import BACKEND_URL from "../../../Context/backend";
const DetalleProductos = ({
  DESCUENTO,
  TOTAL,
  SUBTOTAL,
  COSTO_ENVIO,
  PRODUCTOS,
  showDialogFacturacion,
  setShowDialogFacturacion = () => {},
  setIdSeleccion = () => {},
  id,
  pagado,
  facturado = 0,
  showModalEnviarFactura,
  setShowModalEnviarFactura = () => {},
  setIdFactura = () => {},
}) => {
  return (
    <>
      <div className="w-100">
        {PRODUCTOS &&
          Array.isArray(PRODUCTOS) &&
          PRODUCTOS.map((producto) => (
            <div className="w-100 d-flex align-align-items-center px-lg-5">
              <Typography
                variant="p"
                gutterBottom
                component="div"
                className="w-100 font-Oxygen "
              >
                {producto.CANTIDAD + " " + producto.PRODUCTO + " "}
                <div style={{ fontSize: "11px" }}>
                  {producto.COLOR != null || producto.TAMANIO != null
                    ? producto.COLOR + ", " + producto.TAMANIO
                    : ""}
                </div>
              </Typography>
              <Typography
                variant="p"
                gutterBottom
                component="div"
                className="w-100 font-OxygenBold text-end"
              >
                {formatMoneda(producto.CANTIDAD * producto.PRECIO)}
              </Typography>
            </div>
          ))}
      </div>

      <div className="w-100 mt-auto">
        <div
          class="LineRosa w-100 mt-2 mb-2 "
          style={{ backgroundColor: "lightgray" }}
        />

        <div className="w-100 d-flex align-align-items-center px-lg-5">
          <Typography
            variant="p"
            gutterBottom
            component="div"
            className="w-100 font-Oxygen"
          >
            Subtotal
          </Typography>
          <Typography
            variant="p"
            gutterBottom
            component="div"
            className="w-100 font-OxygenBold text-end"
          >
            {formatMoneda(SUBTOTAL)}
          </Typography>
        </div>

        {COSTO_ENVIO != 0 && (
          <div className="w-100 d-flex align-align-items-center px-lg-5">
            <Typography
              variant="p"
              gutterBottom
              component="div"
              className="w-100 font-Oxygen"
            >
              Envío
            </Typography>
            <Typography
              variant="p"
              gutterBottom
              component="div"
              className="w-100 font-OxygenBold text-end"
            >
              {formatMoneda(COSTO_ENVIO)}
            </Typography>
          </div>
        )}

        {DESCUENTO != 0 && (
          <div className="w-100 d-flex align-align-items-center px-lg-5">
            <Typography
              variant="p"
              gutterBottom
              component="div"
              className="w-100 font-Oxygen"
            >
              Descuento
            </Typography>
            <Typography
              variant="p"
              gutterBottom
              component="div"
              className="w-100 font-OxygenBold text-end"
            >
              {formatMoneda(DESCUENTO)}
            </Typography>
          </div>
        )}

        <div className="w-100 d-flex align-align-items-center px-lg-5">
          <Typography
            variant="p"
            gutterBottom
            component="div"
            className="w-100 font-OxygenBold"
          >
            Total
          </Typography>
          <Typography
            variant="p"
            gutterBottom
            component="div"
            className="w-100 font-OxygenBold text-end"
          >
            {formatMoneda(TOTAL)}
          </Typography>
        </div>
        {pagado && (
          <div className="w-100 d-flex justify-content-between align-content-end align-items-end  px-lg-5">
            <Typography
              variant="p"
              gutterBottom
              component="div"
              className="font-OxygenBold align-self-end"
            >
              Factura
            </Typography>

            <div>
              {facturado > 0 ? (
                <>
                  <Tooltip
                    title="Enviar factura"
                    placement="top"
                    onClick={() => {
                      setIdSeleccion(id);
                      setIdFactura(facturado);
                      setShowModalEnviarFactura(true);
                    }}
                  >
                    <IconButton>
                      <img src={Enviar} width={20} />
                    </IconButton>
                  </Tooltip>

                  <Link
                    onClick={() => {
                      setIdSeleccion(id);
                    }}
                    to={"/pdf/factura/" + facturado}
                    target="_blank"
                    style={{ textDecoration: "none" }}
                  >
                    <Tooltip title="Ver factura" placement="top">
                      <IconButton>
                        <img src={Pdf} width={20} />
                      </IconButton>
                    </Tooltip>
                  </Link>

                  <a
                    onClick={() => {
                      setIdSeleccion(id);
                    }}
                    href={
                      BACKEND_URL + `pedidos/XMLFactura?ID_FACTURA=${facturado}`
                    }
                    target="_blank"
                    style={{ textDecoration: "none" }}
                  >
                    <Tooltip title="Descargar xml" placement="top">
                      <IconButton>
                        <img src={XML} width={20} />
                      </IconButton>
                    </Tooltip>
                  </a>
                </>
              ) : (
                <Typography
                  gutterBottom
                  component="div"
                  className="m-1 mt-0 text-center font-OxygenBold text-rosa  animated-text text-end"
                  onClick={() => {
                    //console.log("facturando ando...");
                    setIdSeleccion(id);
                    setShowDialogFacturacion(!showDialogFacturacion);
                  }}
                >
                  Facturar pedido
                </Typography>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default DetalleProductos;
