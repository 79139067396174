import { Alert, Button, Divider, Snackbar } from "@mui/material";
import React, { useEffect, useState } from "react";
import {  Stack } from "react-bootstrap";
import { Col, Row } from "react-bootstrap";
import Loading from "../../Includes/Loading";
import AddIcon from "@mui/icons-material/Add";

import withReactContent from "sweetalert2-react-content";
import { styled } from "@mui/material/styles";
import Swal from "sweetalert2";
import ModalDireccion from "../../Lib/Direcciones/Componentes/ModalDireccion";
import { getLista } from "../../Lib/Direcciones/Funciones/Direcciones";
import AccEditar from "./../../assets/AccEditar.svg";
import AccEliminar from "./../../assets/AccEliminar.svg";
import ModalSelectSucursal from "../../Lib/Direcciones/Componentes/ModalSelectSucursal";
import ModalSelectDireccion from "../../Lib/Direcciones/Componentes/ModalSelectDireccion";

const MySwal = withReactContent(Swal);

const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
  ({ theme }) => ({
    "& .MuiSnackbar-root": {
      top: theme.spacing(15),
    },
  })
);

const Direccion = ({ domicilio, setDomicilio }) => {
  const [IsLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [showModalSelectDirec, SetshowModalSelectDirec] = useState(false);
  const [showModalDireccion, SetshowModalDireccion] = useState(false);
 
  const handleClose = () => {
    setOpen(false);
  };
  
  return (
    <>
      <StyledSnackbar
        direction="right"
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {mensaje}
        </Alert>
      </StyledSnackbar>
      {showModalSelectDirec ? (
        <ModalSelectDireccion
          SetshowModalSelectDirec={SetshowModalSelectDirec}
          setDomicilio={setDomicilio} 
          domicilio={domicilio}
        />
      ) : (
        ""
      )}
  {showModalDireccion ? (
        <ModalDireccion
          SetshowModalDireccion={SetshowModalDireccion}
          getDireciones={()=>{SetshowModalSelectDirec(true)}}
          id={0}
        />
      ) : (
        ""
      )}
      {IsLoading ? (
        <Col sm={12} className="pt-5">
          <Loading />
        </Col>
      ) : (
        <div  >
          <div
            className="pb-0 px-3 px-md-4 pt-3  mt-3"
            style={{ backgroundColor: "#F7F7F7" }}
          >
            <div className="row">
              <div className="col-12">
                <h6 className="font-OxygenBold">
                  {domicilio.id == 0
                    ? "Dirección de envío"
                    : domicilio.nombre}
                </h6>
              </div>

              <div className="col-12  mt-3 mt-md-1">
                <small>
                  <p className="font-OxygenBold my-1">Dirección</p>
                  <p className="font-OxygenLight my-1">
                    {domicilio.id == 0
                      ? "Selecciona un domicilio"
                      : domicilio.domicilio}
                  </p> 
                </small>
              </div>

              <div className="col-12 text-end">
                <Stack
                  className="w-100 mt-2"
                  spacing={4}
                  direction="row"
                  justifyContent="end"
                >
                   
                    <Button
                      className="btn-accion "
                      variant="text"
                      onClick={() => { 
                        SetshowModalSelectDirec(true);
                      }}
                    >
                      <img
                        src={AccEditar}
                        className="me-2 mb-1"
                        style={{ width: "15px" }}
                      />
                      {domicilio.id == 0 ? "Seleccionar domicilio" : "Editar"}
                    </Button>
                  
                </Stack>
              </div>
            </div>
          </div> 



          <div className="col-12 text-star mt-3 mb-3">
            <Button
              onClick={() => SetshowModalDireccion(true)}
              className="btn shadow-none btn-outline-black  py-2 px-4 "
              variant="contained"
              style={{ max: "400px" }}
            >
              <span className={"px-2"}> 
                Agregar dirección
              </span>
            </Button>
        </div>



          
        </div>
      )}
    </>
  );
};

export default Direccion;
