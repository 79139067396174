import { stringify } from "query-string";
import { getData, KEY, postdData, postUrl } from "../../Context/backend";

// export const getLista = async (query) => {
//   let data = [];
//   let queryParamsObj = {
//     key: KEY,
//   };
//   const url = `productos/ListarProductos?${stringify(queryParamsObj)}`;
//   const res = await getData(url, false);
//   return new Promise((resolve, reject) => {
//     if (!res.error) {
//       data = res.data.respuesta;
//       resolve(data);
//     } else {
//       reject(res);
//     }
//   });
// };

export const getProducto = async (id) => {
  let data = [];
  let queryParamsObj = {
    key: KEY,
    id: id,
  };
  const url = `productos/getproducto?${stringify(queryParamsObj)}`;
  const res = await getData(url, true);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const getListaNovedades = async () => {
  let data = [];
  let queryParamsObj = {
    key: KEY,
  };
  const url = `productos/novedades?${stringify(queryParamsObj)}`;
  const res = await getData(url, true);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const getListaPopulares = async () => {
  let data = [];
  let queryParamsObj = {
    key: KEY,
    ORDER: 1,
    ID_CATEGORIA: 0,
  };
  const url = `productos/getpopulares?${stringify(queryParamsObj)}`;
  const res = await getData(url, true);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const getListaRandom = async () => {
  let data = [];
  let queryParamsObj = {
    key: KEY,
    ORDER: 2,
    ID_CATEGORIA: 0,
  };
  const url = `productos/getrandom?${stringify(queryParamsObj)}`;
  const res = await getData(url, true);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const getListaRelacionado = async (id_categoria) => {
  let data = [];
  let queryParamsObj = {
    key: KEY,
    ORDER: 3,
    id_categoria: id_categoria.id_categoria,
  };
  console.log("Datos", queryParamsObj);
  const url = `productos/getrelacionado?${stringify(queryParamsObj)}`;
  const res = await getData(url, true);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const getListaProductos = async (
  id,
  precioMin = 0,
  precioMax = 0,
  siPrecio = false
) => {
  let data = [];
  let queryParamsObj = {
    key: KEY,
    id_categoria: id,
    query: "",
    precioMin,
    precioMax,
    siPrecio,
  };

  const url = `productos/ListarProductos?${id.toString()}&${stringify(queryParamsObj)}`;
  const res = await getData(url, true);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const getPrecioMaximo = async () => {
  let data = [];
  let params = {
    key: KEY
  }
  const url = `productos/getMaxPrecio?${stringify(params)}`;
  const res = await getData(url, true);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const getListaFavoritos = async (id) => {
  let data = [];
  let queryParamsObj = {
    key: KEY,
    id_categoria: id,
    query: "",
  };
  const url = `productos/ListarFavoritos?${stringify(queryParamsObj)}`;
  const res = await getData(url, true);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};


export const getListaComentarios = async (id_producto, top = 30) => {
  let data = [];
  let queryParamsObj = { id_producto, top };
  const url = `productos/ListarComentarios?${stringify(queryParamsObj)}`;
  const res = await getData(url, true);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};


export const guardarProductoComentario = async (id_producto, comentario, calificacion, id = 0) => {
  let data = [];
  let queryParamsObj = { id_producto, comentario, calificacion, id };
  const url = id == 0 ? `productos/AgregarComentario` : `productos/EditarComentario`;
  const res = await postdData(url, queryParamsObj);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      resolve(res);
    } else {
      reject(res);
    }
  });
};
